import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { isContentEditable } from "@testing-library/user-event/dist/utils";

const Reference = ({ footer, content }) => {
  return (
    <div className="flex justify-center bg-[#fff8ef] flex-col items-center">
      <div className="max-w-[1536px] w-[90%] lg:w-[95%]">
        <h1 className="text-3xl lg:text-5xl font-extrabold mx-auto pt-16 pb-24 lg:leading-[56px] text-center max-w-[900px] font-sans">
          {content.title}
        </h1>

        <section className="overflow-hidden flex relative bg-[rgb(244,246,248)] px-3 md:px-6 md:justify-center gap-10 lg:gap-28 rounded-2xl">
          <div className="flex items-start py-10 gap-1 md:gap-2 text-xl">
            <img
              src="/img/blue-quote.svg"
              alt="quote"
              className="w-10 md:w-14"
            />

            <div className="flex flex-col gap-8 py-3 md:py-6">
              <p className="sm:text-lg lg:text-xl md:min-w-[450px] sm:w-[70%] md:w-full md:max-w-[650px]">
                {content.review1}
              </p>

              <div className="text-base">
                <h6 className="font-bold">Bettina Ladewig</h6>
                <div className="flex items-center">
                  <span className="font-light">Beratungsstellenleiterin</span>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/img/uc_BettinaLadewigFreigestellt.png"
            alt="bettina ladewig aktenplatz customer"
            className="hidden sm:block absolute bottom-0 -right-16 md:static min-w-[200px] max-w-[350px] w-[250px] md:w-full object-contain self-end"
          />
        </section>

        <section className="flex justify-center py-20">
          <div className="flex flex-col gap-16">
            <div className="flex flex-col md:flex-row gap-8">
              <img
                src="/img/mountain-flag.svg"
                alt="mountain"
                className="w-40 h-fit self-center"
              />

              <div className="flex flex-col gap-3">
                <h6 className="text-2xl font-bold">
                  {content.challenges.title}
                </h6>
                <p className="font-bold">{content.challenges.description}</p>

                <ul className="list-disc ml-4">
                  <li>{content.challenges.point1}​</li>
                  <li>{content.challenges.point2}</li>
                  <li>{content.challenges.point3}</li>
                </ul>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-8">
              <img
                src="/img/arrow-target.svg"
                alt="mountain"
                className="w-40 h-fit self-center"
              />

              <div className="flex flex-col gap-3">
                <h6 className="text-2xl font-bold">
                  {content.solutions.title}
                </h6>
                <p className="font-bold">{content.solutions.description}</p>

                <div>
                  <p>{content.solutions.pointsTitle}</p>
                  <ul className="mt-1">
                    <li>
                      <a
                        href="#"
                        className="hover:underline cursor-pointer text-orange font-medium"
                      >
                        {content.solutions.point1}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="hover:underline cursor-pointer text-orange font-medium"
                      >
                        {content.solutions.point2}
                      </a>{" "}
                      {content.solutions.point2Extra}
                    </li>
                    <li>
                      <a
                        href="#"
                        className="hover:underline cursor-pointer text-orange font-medium"
                      >
                        {content.solutions.point3}
                      </a>{" "}
                      {content.solutions.point3Extra}
                    </li>
                    <li>
                      <a
                        href="#"
                        className="hover:underline cursor-pointer text-orange font-medium"
                      >
                        {content.solutions.point4}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-col px-4 lg:flex-row bg-[rgb(244,246,248)] items-center justify-center gap-16 rounded-2xl py-10">
          <div className="text-center flex lg:flex-col items-center gap-3 lg:gap-0">
            <h6 className="text-orange font-extrabold">
              <span className="text-5xl lg:text-6xl">70</span>
              <span className="text-xl lg:text-3xl">%</span>
            </h6>

            <p className="text-xl">{content.reduction}</p>
          </div>

          <div className="text-center flex lg:flex-col items-center gap-3 lg:gap-0">
            <h6 className="text-[#00c180] font-extrabold">
              <span className="text-5xl lg:text-6xl">50</span>
              <span className="text-xl lg:text-3xl">%</span>
            </h6>

            <p className="text-xl">{content.increase}</p>
          </div>
        </section>

        <section className="z-0 relative mt-28 lg:pt-28 rounded-2xl">
          <div className="-z-10 bg-cover bg-no-repeat absolute left-0 right-0 top-0 h-[550px] rounded-t-2xl lg:reference-usecase-1"></div>

          <div className="lg:max-w-[1280px] lg:w-[85%] mx-auto">
            <div className="uppercase text-white text-lg font-semibold">
              {content.usecase1.title}
            </div>

            <div className="flex flex-col gap-8 mt-4">
              <div>
                <h6 className="text-2xl sm:text-3xl font-bold">
                  <span className="text-orange">
                    {content.usecase1.challengeTitle.orange}
                  </span>{" "}
                  {content.usecase1.challengeTitle.black}
                </h6>
                <h6 className="text-3xl sm:text-4xl font-bold">
                  {content.usecase1.challenge}
                </h6>
              </div>

              <p className="lg:max-w-[500px]">
                {content.usecase1.challengeDescription}
              </p>

              <p className="font-bold">{content.usecase1.stepsIn}</p>
            </div>

            <div className="flex flex-col gap-4 mt-14">
              <div>
                <h6 className="text-orange text-2xl sm:text-3xl font-bold">
                  {content.usecase1.solutionTitle}
                </h6>
                <h6 className="text-2xl sm:text-3xl font-bold">
                  {content.usecase1.solution}
                </h6>
              </div>

              <div className="flex flex-col gap-2">
                <p>{content.usecase1.solutionDescription1}</p>

                <ul className="flex flex-col gap-2">
                  <li className="flex items-center gap-2">
                    <span className="bg-[rgb(203,213,225)] w-8 h-8 grid place-items-center text-white rounded-full">
                      1
                    </span>
                    {content.usecase1.point1}
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="bg-[rgb(203,213,225)] w-8 h-8 grid place-items-center text-white rounded-full">
                      2
                    </span>
                    {content.usecase1.point2}
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="bg-[rgb(203,213,225)] w-8 h-8 grid place-items-center text-white rounded-full">
                      3
                    </span>
                    {content.usecase1.point3}
                  </li>
                </ul>

                <p className="mt-6">
                  {content.usecase1.solutionDescription2.normal1}{" "}
                  <span className="font-bold">
                    {content.usecase1.solutionDescription2.bold1}
                  </span>{" "}
                  {content.usecase1.solutionDescription2.normal2}{" "}
                  <span className="font-bold">
                    {content.usecase1.solutionDescription2.bold2}
                  </span>{" "}
                  {content.usecase1.solutionDescription2.normal3}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-16">
          <div className="lg:max-w-[1250px] flex flex-col gap-16 mx-auto">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16">
              <p className="lg:max-w-[500px] font-light w-full order-1 lg:-order-1">
                <span className="font-bold">{content.about1.para.bold}</span>{" "}
                {content.about1.para.normal}
              </p>

              <img
                src="/img/RefLadPic1.png"
                alt="get started checklist"
                style={{ borderRadius: "30px", borderColor: "white", borderWidth: "7px" }}
                className="lg:max-w-[575px] w-full"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16">
              <div className="lg:max-w-[500px] w-full font-light flex flex-col gap-2 order-1">
                <p>
                  {content.about2.para.normal1}{" "}
                  <span className="font-bold">{content.about2.para.bold1}</span>
                  {content.about2.para.normal2}
                </p>

                <p>
                  {content.about2.pointsTitle.normal1}{" "}
                  <span className="font-bold">
                    {content.about2.pointsTitle.bold1}
                  </span>{" "}
                  {content.about2.pointsTitle.normal2}
                </p>

                <ul className="flex flex-col gap-1">
                  <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                    {content.about2.point1}
                  </li>
                  <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                    {content.about2.point2}
                  </li>
                </ul>
              </div>

              <iframe width="100%" height="315" src="https://www.youtube.com/embed/Y3Wv2AoGiv0?si=zrSVInkMPbpo0czy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-16">
              <div className="w-full lg:max-w-[500px] font-light flex flex-col gap-2 order-1 lg:-order-1">
                <p>
                  {content.about3.pointsTitle.normal1}{" "}
                  <span className="font-bold">
                    {content.about3.pointsTitle.bold1}
                  </span>{" "}
                  {content.about3.pointsTitle.normal2}
                </p>

                <ul className="flex flex-col gap-1">
                  <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                    {content.about3.point1}
                  </li>
                  <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                    {content.about3.point2}
                  </li>
                </ul>

                <p>{content.about3.para}</p>
              </div>

              <img
                src="/img/automationRule1.png"
                alt="help center widget"
                className="w-full lg:max-w-[575px]"
              />
            </div>
          </div>
        </section>

        <section className="lg:pt-28 relative z-0 mt-24 lg:mt-32">
          <div className="lg:reference-usecase-2 rounded-t-2xl h-[600px] bg-no-repeat bg-cover -z-10 absolute top-0 left-0 right-0"></div>

          <div className="lg:max-w-[1280px] lg:w-[85%] mx-auto">
            <div className="lg:ml-auto lg:w-1/2">
              <div className="uppercase text-white text-lg font-semibold">
                {content.usecase2.title}
              </div>

              <div className="mt-4">
                <div>
                  <h6 className="text-2xl sm:text-3xl font-bold">
                    <span className="lg:text-orange">
                      {content.usecase2.challengeTitle.white}
                    </span>{" "}
                    {content.usecase2.challengeTitle.black}
                  </h6>
                  <h6 className="text-3xl sm:text-4xl font-bold">
                    {content.usecase2.challenge}
                  </h6>
                </div>
              </div>

              <div className="flex flex-col gap-4 mt-8">
                <div>
                  <h6 className="lg:text-orange text-2xl sm:text-3xl font-bold">
                    {content.usecase2.solutionTitle}
                  </h6>
                  <h6 className="text-2xl sm:text-3xl font-bold">
                    {content.usecase2.solution}
                  </h6>
                </div>

                <p>
                  {content.usecase2.solutionDescription.normal1}{" "}
                  <span className="font-bold">
                    {content.usecase2.solutionDescription.bold1}
                  </span>{" "}
                  {content.usecase2.solutionDescription.normal2}{" "}
                  <span className="font-bold">
                    {content.usecase2.solutionDescription.bold2}
                  </span>{" "}
                  {content.usecase2.solutionDescription.normal3}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="max-w-[1250px] flex flex-col gap-16 mt-12 lg:mt-32 xl:mt-40 mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center gap-10 md:gap-16">
            <div className="flex flex-col gap-2 w-full">
              <span className="font-semibold">
                {content.npsSurvey.pointsTitle}
              </span>

              <ul className="font-light">
                <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                  {content.npsSurvey.point1}
                </li>
                <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                  {content.npsSurvey.point2}
                </li>
                <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                  {content.npsSurvey.point3}
                </li>
                <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                  {content.npsSurvey.point4}
                </li>
                <li className="before:bg-[#000] before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
                  {content.npsSurvey.point5}
                </li>
              </ul>
            </div>

            <p className="font-light md:max-w-xl w-full">
              <span className="font-semibold">
                {content.cancellationSurvey.bold}
              </span>{" "}
              {content.cancellationSurvey.normal}
            </p>
          </div>

          <p className="font-light">
            {content.netPromoterSurvey.normal1}{" "}
            <span className="font-semibold">
              {content.netPromoterSurvey.bold1}
            </span>
            {content.netPromoterSurvey.normal2}{" "}
            <span className="font-semibold">
              {content.netPromoterSurvey.bold2}
            </span>
            {content.netPromoterSurvey.normal3}
          </p>

          <div>
            <img src="/img/CalendarView.png" alt="laptop" className="w-4/5 mx-auto" />
            <p className="font-light mt-8">{content.apiSurvey}</p>
          </div>

          <div className="flex flex-col lg:flex-row gap-8 md:gap-12 lg:gap-20 items-center justify-between">
            <img
              src="/img/MeetingSuggestions.png"
              alt="branching editor"
              className="w-full"
              style={{ borderRadius: "30px", borderColor: "white", borderWidth: "7px", maxWidth: "500px" }}
            />

            <div className="flex w-full items-start lg:py-10 gap-1 md:gap-2 text-xl">
              <img src="/img/blue-quote.svg" alt="quote" className="w-10" />

              <div className="flex flex-col gap-8 py-3 md:py-6">
                <p className="font-light text-base lg:max-w-[450px]">
                  {content.review2}
                </p>

                <div className="flex items-center gap-8">
                  <div className="text-base">
                    <h6 className="font-semibold">Bettina Ladewig</h6>
                    <div className="flex items-center">
                      <span className="font-light">Beratungsstellen Leiterin</span>{" "}
                    </div>
                  </div>

                  <div className="w-20 aspect-square rounded-full">
                    <img
                      src="/img/ladewig_round.png"
                      alt="anders ekstrom"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="bg-[rgb(244,246,248)] flex flex-col gap-8 text-center items-center rounded-2xl py-8 px-4 lg:py-16 mt-28">
          <div className="flex items-center gap-3">
            <span className="text-xl sm:text-2xl font-semibold">
              {content.about.title}
            </span>
            <img
              src="/img/logo_alh.png"
              alt="fitness player"
              className="w-36 sm:w-44"
            />
          </div>

          <div className="font-light flex flex-col gap-4 max-w-3xl">
            <p>
              <span className="underline">
                <a href="#">{content.about.description1.link}</a>
              </span>{" "}
              {content.about.description1.normal}
            </p>
            <p>
              {content.about.description2.normal}{" "}
              <span className="underline">
                <a href="#">{content.about.description2.link}</a>
              </span>
            </p>
          </div>

          <ul className="flex flex-col gap-1 text-start font-light mt-4">
            <li className="before:bg-orange before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
              {content.about.point1}
            </li>
            <li className="before:bg-orange before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
              {content.about.point2}
            </li>
            <li className="before:bg-orange before:mr-2 before:top-50% before:relative before:-translate-y-1/2 before:inline-block before:rounded-full before:w-1.5 before:aspect-square">
              {content.about.point3}
            </li>
          </ul>
        </section> */}

        <section className="max-w-[1200px] mx-auto px-12 mt-24 rounded-2xl py-16 flex flex-col gap-4 justify-center text-center text-white w-full bg-gradient-to-r from-[#1c8f8f] to-[#23b47d]">
          <h6 className="text-2xl font-bold">{content.wantToLearn.title}</h6>
          <p className="font-light">
            <span className="underline">
            <Link to="/contact">
              {content.wantToLearn.description.link}
            </Link>
            </span>{" "}
            {content.wantToLearn.description.normal}
          </p>
        </section>

        <section className="flex flex-col items-center text-center gap-8 mt-24 mb-20">
          <h6 className="text-2xl font-semibold">{content.interested.title}</h6>
          <p className="max-w-3xl">{content.interested.description}</p>
          <div>
            <div className="flex gap-4">
            <a href="https://app.aktenplatz.de/register" target="_blank" rel="noreferrer">
              <button className="w-[150px] sm:w-[172px] h-[50px] rounded-lg bg-orange text-white font-bold">
                {content.interested.button1}
              </button>
            </a>
              <Link to="/termin">
              <button className="w-[150px] sm:w-[172px] h-[50px] rounded-lg bg-transparent border-2 border-orange text-orange font-bold">
                {content.interested.button2}
              </button>
              </Link>
            </div>

            <p className="text-sm font-light mt-2">{content.interested.ps}</p>
          </div>
        </section>
      </div>
      <Footer content={footer} />
    </div>
  );
};

export default Reference;
