const FormInput = ({
  label,
  name,
  handler,
  value,
  compulsory,
  placeholder,
}) => {
  return (
    <div className="flex justify-start items-start flex-col w-full gap-3">
      <label className="text-base text-black font-medium" htmlFor={label}>
        {label} {compulsory && <span className="text-orange">*</span>}
      </label>
      <input
        name={name}
        id={label}
        onChange={handler}
        value={value}
        type="text"
        placeholder={placeholder}
        className="w-full border outline-1 border-solid border-[#727272] h-[50px] pl-4 rounded"
      />
    </div>
  );
};

export default FormInput;
