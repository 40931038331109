import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Teams = ({ content, footer }) => {
  return (
    <div className="grid grid-rows-[1fr__auto] min-h-screen w-full">
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
      </Helmet>
      <div className="flex pb-12 px-5 justify-center bg-[#fff8ef] pt-[40px] md:pt-[60px] items-center flex-col w-full">
        <div className="container flex justify-center flex-col  items-center gap-2 md:gap-[35px]">
          <h2 className="leading-none text-black font-bold text-center text-2xl  sm:text-[28px] md:text-[35px] xl:text-[48px]">
            {content.heading}
          </h2>
          <p className="text-black text-center font-medium text-base sm:text-xl md:text-[25px] xl:text-[30px]">
            {content.sub}
          </p>
          <div className="grid grid-cols-1 auto-rows-fr md:grid-cols-2 xl:grid-cols-3 mt-5 md:mt-10 w-full gap-5">
            {content.boxes.map((elem, idx) => {
              return (
                <ApplicationBox
                  {...elem}
                  url_lang={content.urlLang}
                  key={idx + "application-box"}
                />
              );
            })}
          </div>
          <p className="text-base sm:text-xl lg:text-[20px] text-black text-center mt-3 sm:mt-6 max-w-[1300px]">
            {content.bottomText}
          </p>
          <Link to={"/contact"} className="btn-primary flex justify-center items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
            {content.btnText}
          </Link>
        </div>
      </div>
      <Footer content={footer} />
    </div>
  );
};

export default Teams;

const ApplicationBox = ({ title, url, desc, img, url_lang }) => {
  return (
    <Link className='flex justify-start gap-4 items-start flex-col py-5 group sm:py-7 px-5 sm:px-6 rounded-[15px] bg-[#ffffff] hover:bg-orange transition-all duration-300' to={url} >
      {/* <img
      src={img}
      className="w-[35px] group-hover:invert-0 transition-all duration-300 invert mb-5 sm:w-[50px] object-contain"
      alt=""
    /> */}
      < FontAwesomeIcon icon={img} size='3x' />
      <h4 className="text-black group-hover:text-white transition-all duration-300 font-bold text-xl sm:text-[28px]">
        {title}
      </h4>
      <p className="text-black group-hover:text-white transition-all duration-300 font-normal text-base sm:text-lg">
        {desc}
      </p>
      {
        url && (
          <Link to={url} className="text-black group-hover:text-white transition-all duration-300 flex justify-center items-center gap-2 font-bold">
            {url_lang}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </Link>
        )
      }
    </Link >
  );
};
