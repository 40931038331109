import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import FormTextarea from "../components/FormTextarea";

const Contact = ({ content, footer }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyEmail: "",
    telephone: "",
    country: "",
    size: "",
    message: "",
    privacyPolicy: false,
  });
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  //input handler for input components
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  //submit handler
  const submitHandler = (e) => {
    e.preventDefault();
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.companyEmail ||
      !formData.country ||
      !formData.size ||
      !formData.message
    )
      return toast.error("Please fill the form correctly");
    if (!formData.privacyPolicy) return toast.error("Accept Privacy Policy");
    //sending mail
    setLoading(true);
    emailjs
      .sendForm(
        "service_qdyu768",
        "template_qlwxsac",
        formRef.current,
        "j5AWxEsNPh7ca7u_6"
      )
      .then(
        () => {
          setLoading(false);
          toast.success("Message sent successfully");
          setFormData({
            firstName: "",
            lastName: "",
            companyEmail: "",
            telephone: "",
            country: "",
            size: "",
            message: "",
            privacyPolicy: false,
          });
        },
        () => {
          setLoading(false);
          toast.error("Error occured");
        }
      );
  };

  return (
    <div className="min-h-screen grid grid-rows-[1fr__auto]">
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
      </Helmet>
      <div className="flex  justify-center bg-[#fff8ef] pt-[40px] md:pt-[60px] items-center flex-col w-full">
        <div className="container px-5 mb-12 flex justify-center items-center gap-[20px]">
          <div className="hidden lg:flex justify-start max-w-[450px] items-start flex-col gap-10">
            <img src="/img/contact-small.jpg" alt="contact icon" />
            <p className="text-base">{content.desc}</p>
          </div>
          <form
            ref={formRef}
            className="flex gap-5 bg-white sm:gap-10 justify-start items-start flex-col w-full p-8 rounded-lg shadow-lg"
          >
            <h2 className="font-normal text-2xl text-center md:text-left md:text-3xl mb-4">
              {content.heading}
            </h2>
            <p className="text-orange text-sm sm:-mb-6">{content.note}</p>
            <div className="sm:flex-row flex-col flex justify-start items-start w-full gap-5">
              <FormInput
                value={formData.firstName}
                handler={inputHandler}
                label={content.label1}
                name="firstName"
                compulsory
                placeholder="Jens"
              />
              <FormInput
                value={formData.lastName}
                handler={inputHandler}
                label={content.label2}
                name="lastName"
                compulsory
                placeholder="Weber"
              />
            </div>
            <div className="sm:flex-row flex-col flex justify-start items-start w-full gap-5">
              <FormInput
                value={formData.companyEmail}
                handler={inputHandler}
                label={content.label3}
                name="companyEmail"
                compulsory
                placeholder="name@firma.de"
              />
              <FormInput
                value={formData.telephone}
                handler={inputHandler}
                label={content.label4}
                name="telephone"
                placeholder="+49 1525 12345678"
              />
            </div>
            <div className="sm:flex-row flex-col flex justify-start items-start w-full gap-5">
              <FormSelect
                value={formData.country}
                handler={inputHandler}
                label={content.label5}
                name="country"
                compulsory
                placeholder={content.choose}
                options={[
                  content.choose,
                  "Deutschland",
                  "Österreich",
                  "Schweiz",
                  "Sonstige",
                ]}
              />
              <FormSelect
                value={formData.size}
                handler={inputHandler}
                label={content.label6}
                name="size"
                compulsory
                placeholder={content.choose}
                options={[content.choose, "1-14", "14-25", "50-99"]}
              />
            </div>
            <FormTextarea
              placeholder={content.messagePlaceholder}
              compulsory
              label={content.label7}
              name="message"
              handler={inputHandler}
              value={formData.message}
            />
            <div className="flex  justify-start items-start flex-col gap-3">
              <label
                className="flex justify-start items-center gap-2"
                htmlFor="privacy"
              >
                <input
                  checked={formData.privacyPolicy}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return { ...prev, privacyPolicy: e.target.checked };
                    });
                  }}
                  type="checkbox"
                  name="privacyPolicy"
                  id="privacy"
                  className="accent-orange w-4 h-4"
                />
                <p className="text-sm">
                  {content.policy} <span className="text-orange">*</span>
                </p>
              </label>
              <button
                disabled={loading}
                onClick={submitHandler}
                className="btn-primary p-0 grid place-items-center w-[150px] h-[50px]"
              >
                {loading ? (
                  <img
                    className="w-7 object-contain"
                    src="/img/loader.gif"
                    alt="load icon"
                  />
                ) : (
                  content.btn
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer content={footer} shortPage={false} />
    </div>
  );
};

export default Contact;
