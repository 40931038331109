import React, { useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation } from "react-router";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import FormTextarea from "../components/FormTextarea";
import toast from "react-hot-toast";

const Termin = ({ content, footer }) => {
  const [loading, setLoading] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyEmail: "",
    size: "",
    country: "",
  });
  const formRef = useRef();


  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  var templateParams = {
    message: window.location.href
  };



  useEffect(() => {
    emailjs.send('service_qdyu768', 'template_qlwxsac', templateParams, "j5AWxEsNPh7ca7u_6").then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text);
      },
      (error) => {
        console.log('FAILED...', error);
      },
    );
  });



  //submit handler
  const submitHandler = (e) => {
    e.preventDefault();
    if (!formData.companyEmail)
      return toast.error("Please fill the company email");
    //sending mail
    setLoading(true);
    emailjs
      .sendForm(
        "service_qdyu768",
        "template_qlwxsac",
        formRef.current,
        "j5AWxEsNPh7ca7u_6"
      )
      .then(
        () => {
          setLoading(false);
          toast.success("Message sent successfully");
          setFormData({
            companyEmail: "",
          });
        },
        () => {
          setLoading(false);
          toast.error("Error occured");
        }
      )
      .finally(() => {
        setIsModalActive(false);
      });
  };



  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        ></script>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
      </Helmet>
      <div className="min-h-screen grid gap-y-[90px]   w-full grid-rows-[1fr__auto] body">
        <section className="header-container">
          {/* <!-- START --> */}

          <div className="banner-section-tax pt-[60px] flex flex-col  lg:flex-row container mx-auto">
            <div
              className={`banner-col-tax lg:pt-24 flex flex-1 flex-col items-center text-center pt-10 lg:items-start  lg:text-left col-1 xl:ml-[60px] 2xl:ml-[190px]`}
            >
              <div className="banner-col-1-content-tax px-4 lg:max-w-lg">
                <h1 className="banner-title-tax pt-2 pb-3 text-4xl font-bold max-w-xs mx-auto lg:text-5xl lg:max-w-none lg:pb-6 lg:leading-tight">
                  {content.headline}
                </h1>

              </div>
              <p className="banner-description-tax text-[17px] opacity-80  leading-8">
                {content.desc1}
              </p>
              <br />
              <b>{content.link1}</b>

              <div className="w-[400px] mt-5">
                <div className="client-feedback">
                  <div className="client-image-container">
                    <div className="client-image-outer-wrapper">
                      <div className="client-image-wrapper">
                        <img
                          className="client-image"
                          src="/img/sascha.png"
                          alt="image sascha ladewig"
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="client-dot"
                    src="/img/Dot.png"
                    alt="dot icon"
                  />
                  <div className="client-content">
                    <div className="client-details">
                      <h4 className="client-name">Sascha Ladewig</h4>
                      <span className="client-position">
                        {content.coFounder}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-col col-2-tax flex flex-row flex-1 items-center justify-center pt-10 lg:pt-0  xl:mr-[60px] 2xl:mr-[190px] self-end w-full">
              {/* <!-- <img src="./img/tax-page-image.svg" alt="logo" className="tax-image max-w-xs lg:max-w-[500px]"> --> */}
              {/* <!-- <embed src="https://www.youtube.com/embed/Y3Wv2AoGiv0" wmode="transparent" type="video/mp4" width="100%"
            height="300px" allow="autoplay; encrypted-media; picture-in-picture" allowfullscreen
            title="Keyboard Cat"> --> */}
              {/* <!-- Calendly Inline-Widget Beginn --> */}
              <div
                className={
                  "calendly-inline-widget  w-[90%] max-w-[580px] h-[830px]"
                }
                data-url="https://calendly.com/aktenplatz/aktenplatz-einrichtung?hide_landing_page_details=1&hide_gdpr_banner=1"
              ></div>

              {/* {formSubmitted && (
                <iframe
                  className={
                    "calendly-inline-widget  w-[90%] max-w-[580px] h-[630px]"
                  }
                  title="calendly"
                  src={`https://calendly.com/aktenplatz/30min?name=${formData.firstName}&email=${formData.email}`}
                />
              )} */}

              {/* <form
                ref={formRef}
                onSubmit={submitHandler}
                className={
                  "max-w-[500px] flex bg-white gap-2 justify-start items-start flex-col w-full p-8 rounded-lg shadow-xl"
                }
              >
                <h4 className="font-bold text-xl text-center md:text-3xl mb-4 max-w-[380px] mx-auto">
                  {content.form.title}
                </h4>
                <div className="sm:flex-row flex-col flex justify-start items-start w-full gap-2">
                  <FormInput
                    value={formData.firstName}
                    handler={inputHandler}
                    name="firstName"
                    placeholder="Jens"
                  />
                  <FormInput
                    value={formData.lastName}
                    handler={inputHandler}
                    name="lastName"
                    placeholder="Weber"
                  />
                </div>
                <div className="sm:flex-row flex-col flex justify-start items-start w-full gap-2">
                  <FormInput
                    value={formData.companyEmail}
                    handler={inputHandler}
                    name="companyEmail"
                    placeholder="name@firma.de"
                  />{" "}
                </div>
                <FormSelect
                  value={formData.size}
                  handler={inputHandler}
                  label={content.label4}
                  name="size"
                  placeholder={content.form.label4}
                  options={[content.form.label4, "1-14", "14-25", "50-99"]}
                />
                <div className="sm:flex-row flex-col flex justify-start items-start w-full gap-5">
                  <FormSelect
                    value={formData.country}
                    handler={inputHandler}
                    placeholder={content.form.label5}
                    name="country"
                    options={[
                      content.form.label5,
                      "Deutschland",
                      "Österreich",
                      "Schweiz",
                      "Sonstige",
                    ]}
                  />
                </div>
                <div className="w-full flex justify-end gap-3 mt-5">
                  <button
                    disabled={loading}
                    className="btn-primary flex justify-center gap-1 items-center font-bold p-0 w-[260px] h-[50px] rounded-full text-xs"
                  >
                    {loading ? (
                      <img
                        className="w-7 object-contain"
                        src="/img/loader.gif"
                        alt="load icon"
                      />
                    ) : (
                      <>
                        {content.form.btn}
                        <svg
                          className="rotate-90"
                          fill="#fff"
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Layer_1"
                          viewBox="0 0 330 330"
                        >
                          <path
                            id="XMLID_29_"
                            d="M100.606,100.606L150,51.212V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V51.212l49.394,49.394  C232.322,103.535,236.161,105,240,105c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75  c-5.857-5.858-15.355-5.858-21.213,0l-75,75c-5.858,5.857-5.858,15.355,0,21.213C85.251,106.463,94.749,106.463,100.606,100.606z"
                          />
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </form> */}

              {/* <!-- Calendly Inline-Widget Ende --> */}
            </div>
          </div>

          {/* <!-- BLOG START --> */}

          {/* <div className="mx-auto sm:container  lg:max-w-[880px] px-[20px] mb-20">
            <div className="w-full h-[300px]"></div>
          </div> */}

          {/* <!-- END --> */}
        </section>
        <Footer content={footer} />
      </div>
    </>
  );
};

export default Termin;
