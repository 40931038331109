import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faBellConcierge,
  faBoxArchive,
  faBuilding,
  faBusinessTime,
  faCarBurst,
  faChampagneGlasses,
  faCoins,
  faComment,
  faComments,
  faCompass,
  faComputer,
  faDiagramProject,
  faDolly,
  faEnvelopesBulk,
  faFileInvoiceDollar,
  faGift,
  faHandshake,
  faHospital,
  faHouse,
  faIndustry,
  faLightbulb,
  faListCheck,
  faMapPin,
  faPaperPlane,
  faPersonDigging,
  faPhotoFilm,
  faPiggyBank,
  faPlaneDeparture,
  faSchoolFlag,
  faScrewdriverWrench,
  faSignHanging,
  faStore,
  faUserDoctor,
  faClapperboard,
  faBook,
  faClipboardList,
  faMedal,
  faPodcast,
  faBlog,
  faPenRuler,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import TennisLandingPage from "./pages/tennis_landing";
import Impressum from "./pages/Impressum";
import Kieferorthopaedie from "./pages/Kieferorthopaedie";
import PageNotFound from "./pages/PageNotFound";
import Preise from "./pages/Preise";
import Revisionssicher from "./pages/Revisionssicher";
import Steuerkanzleien from "./pages/Steuerkanzleien";
import Termin from "./pages/Termin";
import TerminCal from "./pages/TerminCal";
import ThankYou from "./pages/ThankYou";
import { languageData } from "./language";
import Contact from "./pages/Contact";
import { Toaster } from "react-hot-toast";
import Applications from "./pages/Applications";
import Teams from "./pages/Teams";
import Branchen from "./pages/Branchen";
import CaseStudies from "./pages/CaseStudies";
import Webinars from "./pages/Webinars";
import CheatSheet from "./pages/CheatSheet";
import Zeiterfassung from "./pages/Zeiterfassung";
import Mail from "./pages/Mail";
import Ideen from "./pages/Ideen";
import Ort from "./pages/Ort";
import Aufgaben from "./pages/Aufgaben";
import Lieferschein from "./pages/Lieferschein";
import Versicherung from "./pages/Versicherung";
import Immobilien from "./pages/Immobilien";
import Geschaeftsprozesse from "./pages/Geschaeftsprozesse";
import Papierlos from "./pages/Papierlos";
import Personalmanagement from "./pages/Personalmanagement";
import Ceo from "./pages/Ceo";
import Events from "./pages/Events";
import Bauen from "./pages/Bauen";
import ODienst from "./pages/Odienst";
import Redirect from "./pages/Redirect";
import Reference from "./pages/Reference";

function App() {
  library.add(
    faPersonDigging,
    faScrewdriverWrench,
    faSignHanging,
    faHouse,
    faCoins,
    faPiggyBank,
    faFileInvoiceDollar,
    faUserDoctor,
    faHospital,
    faSchoolFlag,
    faStore,
    faIndustry,
    faPhotoFilm,
    faPlaneDeparture,
    faBellConcierge,
    faAddressBook,
    faHandshake,
    faGift,
    faLightbulb,
    faChampagneGlasses,
    faCompass,
    faComputer,
    faBuilding,
    faPaperPlane,
    faDiagramProject,
    faBoxArchive,
    faCarBurst,
    faDolly,
    faListCheck,
    faMapPin,
    faComments,
    faEnvelopesBulk,
    faBusinessTime,
    faClapperboard,
    faBook,
    faClipboardList,
    faMedal,
    faPodcast,
    faBlog,
    faPiggyBank,
    faPenRuler,
    faCalendarDays
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [language, setLanguage] = useState(
    searchParams.get("lang") === null ? "DE" : searchParams.get("lang")
  );
  const [content, setContent] = useState(languageData.german);
  const location = useLocation();
  //adjust the content based on the param and also set the local storage item for later use
  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    if (currentParams.lang === "EN") {
      setContent(languageData.english);
      document.documentElement.setAttribute("lang", "en");
    }
    if (currentParams.lang === "DE") {
      setContent(languageData.german);
      document.documentElement.setAttribute("lang", "de");
    }
  }, [searchParams]);
  //retain the query param even after you change pages
  useEffect(() => {
    // setSearchParams({ lang: language });
    setSearchParams(
      (searchParams) => {
        searchParams.set("lang", language);
        return searchParams;
      },
      { replace: true }
    );
  }, [location.pathname, location.search]);

  let tennisLanding = false;
  if (window.location.href.indexOf("tennis") > -1) {
    tennisLanding = true;
  }
  if (window.location.href.indexOf("sport") > -1) {
    tennisLanding = true;
  }
  return (
    <>
      <Toaster />
      <Header
        language={language}
        setLanguage={setLanguage}
        content={content.header}
        tennisLanding={tennisLanding}
      />
      <Routes>
        {!tennisLanding && (
          <Route
            path="/"
            element={<Home content={content.home} footer={content.footer} />}
          />
        )}
        {tennisLanding && (
          <Route
            path="/"
            element={
              <TennisLandingPage
                content={content.home}
                footer={content.footer}
              />
            }
          />
        )}
        <Route
          path="/preise"
          element={<TerminCal content={content.appointment} footer={content.footer} />}
        />
        <Route
          path="/preise_sell"
          element={<Preise content={content.price} footer={content.footer} />}
        />
        <Route
          path="/steuerkanzleien"
          element={
            <Steuerkanzleien
              content={content.steuerkanzleien}
              footer={content.footer}
            />
          }
        />
        <Route
          path="/kieferorthopaedie"
          element={
            <Kieferorthopaedie
              content={content.doctor}
              footer={content.footer}
            />
          }
        />
        <Route
          path="/revisionssicher"
          element={
            <Revisionssicher
              content={content.revSafe}
              footer={content.footer}
            />
          }
        />
        <Route
          path="/termin"
          element={
            <Termin content={content.appointment} footer={content.footer} />
          }
        />
        <Route
          path="/termincal"
          element={
            <TerminCal content={content.appointment} footer={content.footer} />
          }
        />
        <Route
          path="/thankyou"
          element={
            <ThankYou content={content.thankYou} footer={content.footer} />
          }
        />
        <Route
          path="/impressum"
          element={
            <Impressum content={content.price} footer={content.footer} />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact content={content.contact} footer={content.footer} />
          }
        />
        <Route
          path="/application"
          element={
            <Applications
              content={content.application}
              footer={content.footer}
            />
          }
        />
        <Route
          path="/teams"
          element={<Teams content={content.teams} footer={content.footer} />}
        />
        <Route
          path="/branchen"
          element={
            <Branchen content={content.branchen} footer={content.footer} />
          }
        />
        <Route
          path="/cases"
          element={
            <CaseStudies content={content.cases} footer={content.footer} />
          }
        />
        <Route
          path="/webinars"
          element={
            <Webinars content={content.webinars} footer={content.footer} />
          }
        />
        <Route
          path="/cheat"
          element={
            <CheatSheet content={content.cheat} footer={content.footer} />
          }
        />

        <Route
          path="/papierlos"
          element={
            <Papierlos content={content.paperless} footer={content.footer} />
          }
        />
        <Route
          path="/geschaeftsprozesse"
          element={
            <Geschaeftsprozesse
              content={content.revSafe}
              footer={content.footer}
            />
          }
        />
        <Route
          path="/immobilien"
          element={
            <Immobilien content={content.revSafe} footer={content.footer} />
          }
        />
        <Route
          path="/versicherung"
          element={
            <Versicherung content={content.revSafe} footer={content.footer} />
          }
        />
        <Route
          path="/lieferschein"
          element={
            <Lieferschein content={content.delivery} footer={content.footer} />
          }
        />
        <Route
          path="/aufgaben"
          element={
            <Aufgaben content={content.revSafe} footer={content.footer} />
          }
        />
        <Route
          path="/ort"
          element={<Ort content={content.revSafe} footer={content.footer} />}
        />
        <Route
          path="/ideen"
          element={<Ideen content={content.revSafe} footer={content.footer} />}
        />
        <Route
          path="/reference_alh"
          element={
            <Reference footer={content.footer} content={content.reference} />
          }
        />
        <Route
          path="/mail"
          element={<Mail content={content.mail} footer={content.footer} />}
        />
        <Route
          path="/zeiterfassung"
          element={
            <Zeiterfassung content={content.revSafe} footer={content.footer} />
          }
        />
        <Route
          path="/persmanagement"
          element={
            <Personalmanagement
              content={content.persm}
              footer={content.footer}
            />
          }
        />
        <Route
          path="/ceo"
          element={<Ceo content={content.ceo} footer={content.footer} />}
        />
        <Route
          path="/events"
          element={<Events content={content.events} footer={content.footer} />}
        />
        <Route
          path="/bauen"
          element={<Bauen content={content.build} footer={content.footer} />}
        />
        <Route
          path="/odienst"
          element={
            <ODienst content={content.odienst} footer={content.footer} />
          }
        />

        <Route
          path="/privacy-policy"
          element={<Redirect url="https://datenschutz.aktenplatz.de" />}
        />

        <Route
          path="/terms-of-use"
          element={<Redirect url="https://agb.aktenplatz.de" />}
        />

        <Route
          path="*"
          element={
            <PageNotFound content={content.price} footer={content.footer} />
          }
        />
      </Routes>
    </>
  );
}

export default App;
