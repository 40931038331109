import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import useOutsideClick from "../hooks/useOutsideClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// random comment ---
const Header = ({ setLanguage, content, tennisLanding }) => {
  const handleClickOutside = () => {
    setmenuMobile("hidden");
  };

  const [menuMobile, setmenuMobile] = React.useState("hidden");
  const [openLanguage, setOpenLanguage] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDrop, setOpenDrop] = React.useState(false);
  const [openDropResources, setOpenDropResources] = React.useState(false);
  const mobileMenu = useOutsideClick(handleClickOutside);

  const onOpenMenu = () => {
    document.body.classList.add("overflow-hidden");
    document.body.classList.remove("overflow-auto");
    setmenuMobile("flex flex-col");
  };

  const onCloseMenu = () => {
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
    setmenuMobile("hidden");
  };

  let addScrollBar = () => {
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
  };

  return (
    <>
      {!menuMobile.includes("hidden") && (
        <div
          onClick={onCloseMenu}
          className="fixed top-0 xl:hidden left-0 w-full h-full bg-black opacity-60 z-40"
        ></div>
      )}
      {openDrop && (
        <div
          onClick={() => setOpenDrop(false)}
          className="hidden fixed top-0 xl:block left-0 w-full h-full bg-black opacity-60 z-20"
        ></div>
      )}
      {openDropResources && (
        <div
          onClick={() => setOpenDropResources(false)}
          className="hidden fixed top-0 xl:block left-0 w-full h-full bg-black opacity-60 z-20"
        ></div>
      )}
      {/* //mobile menu --------------------------------- */}
      <div
        ref={mobileMenu}
        className={`menu-mobile   overflow-y-auto ${menuMobile}`}
      >
        <div className="block  w-full h-full relative">
          <div className="flex flex-none" style={{ zIndex: "2" }}>
            <Link
              to="/"
              onClick={addScrollBar}
              className="flex flex-row items-center"
            >
              <img src="/img/logo.png" alt="logo" className="logo" />
              {tennisLanding && (
                <span className="logo-title">mysport.tennis</span>
              )}
              {!tennisLanding && <span className="logo-title">Aktenplatz</span>}
            </Link>
          </div>
          <div className="menu-close-icon" onClick={onCloseMenu}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.6L14.4 0L8 6.4L1.6 0L0 1.6L6.4 8L0 14.4L1.6 16L8 9.6L14.4 16L16 14.4L9.6 8L16 1.6Z"
                fill="black"
              />
            </svg>
          </div>

          <ul className="main-mobile-menu " id="main-mobile-menu">
            <li onClick={onCloseMenu}>
              <Link
                to={"/"}
                onClick={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    const elem = document.querySelector("#funktionen");
                    if (!elem) return;
                    elem.scrollIntoView();
                  }, 200);
                }}
              >
                <button onClick={addScrollBar}>{content.link1}</button>
              </Link>
            </li>
            <li>
              <div
                className="overflow-hidden"

              // onClick={(e) => {
              //   setTimeout(() => {
              //     e.preventDefault();
              //     const elem = document.querySelector("#funktionen");
              //     if (!elem) return;
              //     elem.scrollIntoView();
              //   }, 200);
              // }}
              >
                {/* {""} */}
                <button
                  onClick={() => {
                    setOpenDrop((prev) => !prev);
                    setOpenDropResources(false);
                  }}
                  className="flex justify-start items-center gap-2"
                >
                  {content.link3}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-5 h-5 transition-all duration-300 ${openDrop ? "rotate-180" : "rotate-0"
                      }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </button>

                <div
                  className={`flex transition-all duration-1000 flex-col  justify-between w-full  gap-6 items-start ${openDrop ? "max-h-[3000px] my-4" : "max-h-0"
                    }`}
                >
                  <DropDownCol
                    addScrollBar={addScrollBar}
                    setDrop={setmenuMobile}
                    mobile
                    {...content.col1}
                  />
                  <DropDownCol
                    addScrollBar={addScrollBar}
                    setDrop={setmenuMobile}
                    mobile
                    {...content.col2}
                  />
                  <DropDownCol
                    addScrollBar={addScrollBar}
                    setDrop={setmenuMobile}
                    mobile
                    {...content.col3}
                  />
                </div>
                <div
                  className={`flex transition-all duration-1000 flex-col  justify-between w-full  gap-6 items-start ${openDropResources ? "max-h-[3000px] my-4" : "max-h-0"
                    }`}
                >
                  <DropDownCol
                    addScrollBar={addScrollBar}
                    setDrop={setmenuMobile}
                    mobile
                    {...content.col1Resources}
                  />
                  <DropDownCol
                    addScrollBar={addScrollBar}
                    setDrop={setmenuMobile}
                    mobile
                    {...content.col2Resources}
                  />
                </div>
              </div>
            </li>
            <li onClick={onCloseMenu}>
              <Link onClick={addScrollBar} to="/preise">
                {content.link2}
              </Link>
            </li>
            <li onClick={onCloseMenu}>
              <Link
                onClick={() => {
                  addScrollBar();
                  window.open("https://blog.aktenplatz.de", "_blank");
                }}
              >
                {content.link9}
              </Link>
            </li>
            <li onClick={onCloseMenu}>
              <Link
                onClick={() => {
                  addScrollBar();
                  window.open(
                    "https://help.aktenplatz.de/" + searchParams.get("lang"),
                    "_blank"
                  );
                }}
              >
                {content.link10}
              </Link>
            </li>
            <li onClick={onCloseMenu}>
              <Link onClick={addScrollBar} to="/reference_alh">
                {content.link11}
              </Link>
            </li>
            <li onClick={onCloseMenu}>
              <Link onClick={addScrollBar} to="/termin">
                {content.link7}
              </Link>
            </li>
            <li onClick={onCloseMenu}>
              <Link onClick={addScrollBar} to="/contact">
                {content.link8}
              </Link>
            </li>
            <li onClick={onCloseMenu}>
              <Link onClick={addScrollBar} to="/termin" className="btn-primary p-0 w-[172px] h-[50px] grid place-items-center">
                {content.btnText}
              </Link>
            </li>
            <a onClick={onCloseMenu}
              href="https://app.aktenplatz.de/register"
              target="_blank" rel="noreferrer"
            >
              <button className="w-[150px] sm:w-[172px] h-[50px] rounded-lg bg-transparent border-2 border-orange text-orange font-bold mr-1">
                Login
              </button>
            </a>
          </ul>
        </div>
      </div > {" "}
      {/* //mobile menu ends--------------------------------- */}
      <div className="header-wrapper relative">
        <header className="header">
          <div
            className="flex gap-4 xl:min-w-auto min-w-[300px] xl:flex-none flex-1 justify-start items-center"
            style={{ zIndex: "2" }}
          >
            <Link
              onClick={() => {
                setOpenDrop(false);
                setOpenDropResources(false);
                addScrollBar();
              }}
              to="/"
              className="flex sm:w-[170px] lg:min-w-[240px] xl:min-w-0 flex-row items-center"
            >
              {tennisLanding && (
                <>
                  <img
                    src="/img/mysport_logo.png"
                    alt="logo"
                    className="logo"
                  />
                  <span className="logo-title">mysport.tennis</span>
                </>
              )}
              {!tennisLanding && (
                <>
                  <img src="/img/logo.png" alt="logo" className="logo" />
                  <span className="logo-title">Aktenplatz</span>
                </>
              )}
            </Link>
            <div
              onClick={() => {
                setOpenLanguage((prev) => !prev);
              }}
              className="gap-1 xl:hidden cursor-pointer relative h-full flex items-center justify-start"
            >
              <img
                className="w-6"
                src="/img/language.svg"
                alt="language icon"
              />
              <img
                alt="down menu"
                src="/img/chevron-down.svg"
                className={`w-4 transition-all ${openLanguage ? "rotate-180" : ""
                  }`}
              />
              {openLanguage && (
                <div className="shadow-md dropdown rounded py-2 flex z-10 flex-col items-center bg-white w-[150px] absolute left-1/2 -translate-x-1/2 top-[130%]">
                  <p
                    onClick={() => {
                      setLanguage("DE");
                      setSearchParams(
                        (searchParams) => {
                          searchParams.set("lang", "DE");
                          return searchParams;
                        },
                        { replace: true }
                      );
                      // setSearchParams({ lang: "DE" });
                    }}
                    className={`py-[5px] ${searchParams.get("lang") === "DE"
                      ? "bg-[#f4f5f6]"
                      : "bg-white"
                      } text-sm cursor-pointer hover:bg-[#f4f5f6] hover:text-[#192435] px-5 text-left  w-full`}
                  >
                    German
                  </p>
                  <p
                    onClick={() => {
                      setLanguage("EN");
                      setSearchParams(
                        (searchParams) => {
                          searchParams.set("lang", "EN");
                          return searchParams;
                        },
                        { replace: true }
                      );
                      // setSearchParams({ lang: "EN" });
                    }}
                    className={`py-[5px] ${searchParams.get("lang") === "EN"
                      ? "bg-[#f4f5f6]"
                      : "bg-white"
                      } text-sm cursor-pointer hover:bg-[#f4f5f6] hover:text-[#192435] px-5 text-left  w-full`}
                  >
                    English
                  </p>
                </div>
              )}
              {/* <p>German</p> */}
            </div>
          </div>
          <div className="flex flex-row  justify-end items-center sm:flex lg:flex xl:hidden">
            <div
              onClick={onOpenMenu}
              className={`"menu-icon static ${tennisLanding ? "hidden" : ""}`}
            >
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="-0.000976562" width="16" height="2" fill="#04060F" />
                <rect
                  x="-0.000976562"
                  y="8"
                  width="24"
                  height="2"
                  fill="#04060F"
                />
                <rect x="7.99902" y="16" width="16" height="2" fill="#04060F" />
              </svg>
            </div>
          </div>
          <div className="hidden flex-row flex-1 xl:flex-none justify-start items-center sm:hidden md:hidden xl:flex">
            <ul className={`main-menu static ${tennisLanding ? "hidden" : ""}`}>
              {/* <li>
                <div>
                  <button
                    onClick={() => {
                      setOpenDrop((prev) => {
                        return !prev;
                      });
                      addScrollBar();
                    }}
                    className="flex pointer-events-all justify-center items-center gap-1"
                  >
                    {content.link1}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`w-5 h-5 transition-all duration-300 ${openDrop ? "rotate-180" : "rotate-0"
                        }`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                  <div
                    className={`flex flex-col shadow-lg px-5  justify-center items-center  w-full absolute transition-all duration-500 ${openDrop
                      ? "opacity-100 z-[90] pointer-events-all translate-y-0 visible"
                      : "opacity-0 -z-10 pointer-events-none translate-y-9 invisible"
                      } top-full left-0 bg-white pt-6 `}
                  >
                    <div className="flex justify-between w-full p-6 items-stretch container">
                      <DropDownCol
                        setDrop={setOpenDrop}
                        mobile={false}
                        {...content.col1}
                      />
                      <DropDownCol
                        setDrop={setOpenDrop}
                        mobile={false}
                        {...content.col2}
                      />
                      <DropDownCol
                        setDrop={setOpenDrop}
                        mobile={false}
                        {...content.col3}
                      />
                      <div className="flex justify-start items-start flex-col gap-8">
                        <h2 className="text-base 2xl:text-lg font-bold text-black">
                          {content.col4Title}
                        </h2>
                        <img
                          src="/img/award1.webp"
                          className="max-w-[160px] object-contain"
                          alt=""
                        />
                        <img
                          className="max-w-[160px] object-contain"
                          src="/img/award2.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex justify-center bg-[#f9f9f9] items-center w-full">
                      {" "}
                      <div className="flex h-[47px] px-6 container w-full justify-start items-center gap-2">
                        <p className="text-black text-xs">
                          Kostenlos testen | Demo vereinbaren
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              <li>
                <Link
                  to={"/"}
                  onClick={(e) => {
                    setOpenDrop(false);
                    setOpenDropResources(false);
                    setTimeout(() => {
                      e.preventDefault();
                      const elem = document.querySelector("#funktionen");
                      if (!elem) return;
                      elem.scrollIntoView();
                    }, 200);
                  }}
                >
                  <button onClick={addScrollBar}>{content.link1}</button>
                </Link>
              </li>
              {/* <li
                className={`z-50 relative  ${searchParams.get("lang") === "EN" ? "w-[130px]" : "w-[113px]"
                  }`}
              >
                <details className="menu-details">
                  <summary>{content.link3}</summary>
                  <ul>
                    <li
                      onClick={() => {
                        const menu = document.querySelector(".menu-details");
                        menu.removeAttribute("open");
                      }}
                    >
                      <Link
                        onClick={() => {
                          setOpenDrop(false);
setOpenDropResources(false);
                          addScrollBar();
                        }}
                        to="/steuerkanzleien"
                      >
                        {content.link4}
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        const menu = document.querySelector(".menu-details");
                        menu.removeAttribute("open");
                      }}
                    >
                      <Link
                        onClick={() => {
                          setOpenDrop(false);
setOpenDropResources(false);
                          addScrollBar();
                        }}
                        to="/kieferorthopaedie"
                      >
                        {content.link5}
                      </Link>
                    </li>
                  </ul>
                </details>
              </li> */}
              <li>
                <div>
                  <button
                    onClick={() => {
                      setOpenDrop((prev) => {
                        return !prev;
                      });
                      setOpenDropResources(false);
                      addScrollBar();
                    }}
                    className="flex pointer-events-all justify-center items-center gap-1"
                  >
                    {content.link3}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`w-5 h-5 transition-all duration-300 ${openDrop ? "rotate-180" : "rotate-0"
                        }`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                  <div
                    className={`flex flex-col shadow-lg px-5  justify-center items-center  w-full absolute transition-all duration-500 ${openDrop
                      ? "opacity-100 z-[90] pointer-events-all translate-y-0 visible"
                      : "opacity-0 -z-10 pointer-events-none translate-y-9 invisible"
                      } top-full left-0 bg-white pt-6 `}
                  >
                    <div className="flex justify-between w-full p-6 items-stretch container">
                      <DropDownCol
                        setDrop={setOpenDrop}
                        mobile={false}
                        {...content.col1}
                      />
                      <DropDownCol
                        setDrop={setOpenDrop}
                        mobile={false}
                        {...content.col2}
                      />
                      <DropDownCol
                        setDrop={setOpenDrop}
                        mobile={false}
                        {...content.col3}
                      />
                      <div className="flex justify-start items-start flex-col gap-8">
                        {/* <h2 className="text-base 2xl:text-lg font-bold text-black">
                          {content.col4Title}
                        </h2>
                        <img
                          src="/img/award1.webp"
                          className="max-w-[160px] object-contain"
                          alt=""
                        />
                        <img
                          className="max-w-[160px] object-contain"
                          src="/img/award2.png"
                          alt=""
                        /> */}
                      </div>
                    </div>
                    <div className="flex justify-center bg-[#f9f9f9] items-center w-full">
                      {" "}
                      <div className="flex h-[47px] px-6 container w-full justify-start items-center gap-2">
                        <p className="text-black text-xs">
                          <Link to="/termin" rel="noreferrer">
                            {content.test}
                          </Link>
                          &emsp;-&emsp;
                          <Link
                            onClick={() => {
                              setOpenDrop(false);
                              setOpenDropResources(false);
                              addScrollBar();
                            }}
                            to="/termin"
                          >
                            {content.demo}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setOpenDrop(false);
                    setOpenDropResources(false);
                    addScrollBar();
                  }}
                  to="/preise"
                >
                  {content.link2}
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={() => {
                    setOpenDrop(false);
                    setOpenDropResources(false);
                    addScrollBar();
                    window.open("https://blog.aktenplatz.de", "_blank");
                  }}
                >
                  {content.link9}
                </Link>
              </li> */}
              {/* <li>
                <Link
                  onClick={() => {
                    setOpenDrop(false);
                    setOpenDropResources(false);
                    addScrollBar();
                    window.open(
                      "https://help.aktenplatz.de/" + searchParams.get("lang"),
                      "_blank"
                    );
                  }}
                >
                  {content.link10}
                </Link>
              </li> */}

              <li>
                <div>
                  <button
                    onClick={() => {
                      setOpenDropResources((prev) => {
                        return !prev;
                      });
                      setOpenDrop(false);
                      addScrollBar();
                    }}
                    className="flex pointer-events-all justify-center items-center gap-1"
                  >
                    {content.link11}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`w-5 h-5 transition-all duration-300 ${openDropResources ? "rotate-180" : "rotate-0"
                        }`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                  <div
                    className={`flex flex-col shadow-lg px-5  justify-center items-center  w-full absolute transition-all duration-500 ${openDropResources
                      ? "opacity-100 z-[90] pointer-events-all translate-y-0 visible"
                      : "opacity-0 -z-10 pointer-events-none translate-y-9 invisible"
                      } top-full left-0 bg-white pt-6 `}
                  >
                    <div className="flex justify-between w-full p-6 items-stretch container">
                      <DropDownCol
                        setDrop={setOpenDropResources}
                        mobile={false}
                        {...content.col1Resources}
                      />
                      <DropDownCol
                        setDrop={setOpenDropResources}
                        mobile={false}
                        {...content.col2Resources}
                      />
                      <div className="flex justify-start items-start flex-col gap-8">
                        {/* <h2 className="text-base 2xl:text-lg font-bold text-black">
                          {content.col4Title}
                        </h2>
                        <img
                          src="/img/award1.webp"
                          className="max-w-[160px] object-contain"
                          alt=""
                        />
                        <img
                          className="max-w-[160px] object-contain"
                          src="/img/award2.png"
                          alt=""
                        /> */}
                      </div>
                    </div>
                    <div className="flex justify-center bg-[#f9f9f9] items-center w-full">
                      {" "}
                      <div className="flex h-[47px] px-6 container w-full justify-start items-center gap-2">
                        <p className="text-black text-xs">
                          <Link to="/termin" rel="noreferrer">
                            {content.test}
                          </Link>
                          &emsp;-&emsp;
                          <Link
                            onClick={() => {
                              setOpenDropResources(false);
                              addScrollBar();
                            }}
                            to="/termin"
                          >
                            {content.demo}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="hidden flex-row flex-1 justify-end items-center sm:hidden md:hidden xl:flex">
            {/* //language selector deskop ------------ */}
            <div
              onMouseEnter={() => {
                setOpenLanguage(true);
                setOpenDrop(false);
                setOpenDropResources(false);
              }}
              onMouseLeave={() => {
                setOpenLanguage(false);
                setOpenDrop(false);
                setOpenDropResources(false);
              }}
              className="flex mr-4 gap-1 cursor-pointer relative h-full"
            >
              <img
                className="w-6"
                src="/img/language.svg"
                alt="language icon"
              />
              <img
                alt="down menu"
                src="/img/chevron-down.svg"
                className={`w-5 transition-all ${openLanguage ? "rotate-180" : ""
                  }`}
              />
              {openLanguage && (
                <div className="shadow-md dropdown rounded py-2 flex z-10 flex-col items-center bg-white w-[150px] absolute left-1/2 -translate-x-1/2 top-[90%]">
                  <p
                    onClick={() => {
                      setLanguage("DE");
                      setSearchParams(
                        (searchParams) => {
                          searchParams.set("lang", "DE");
                          return searchParams;
                        },
                        { replace: true }
                      );
                      // setSearchParams({ lang: "DE" });
                    }}
                    className={`py-[5px] ${searchParams.get("lang") === "DE"
                      ? "bg-[#f4f5f6]"
                      : "bg-white"
                      } text-sm cursor-pointer hover:bg-[#f4f5f6] hover:text-[#192435] px-5 text-left  w-full`}
                  >
                    German
                  </p>
                  <p
                    onClick={() => {
                      setLanguage("EN");
                      setSearchParams(
                        (searchParams) => {
                          searchParams.set("lang", "EN");
                          return searchParams;
                        },
                        { replace: true }
                      );
                      // setSearchParams({ lang: "EN" });
                    }}
                    className={`py-[5px] ${searchParams.get("lang") === "EN"
                      ? "bg-[#f4f5f6]"
                      : "bg-white"
                      } text-sm cursor-pointer hover:bg-[#f4f5f6] hover:text-[#192435] px-5 text-left  w-full`}
                  >
                    English
                  </p>
                </div>
              )}
              {/* <p>German</p> */}
            </div>
            {/* <ul className="main-menu">
              <li>
                <Link
                  onClick={() => {
                    setOpenDrop(false);
setOpenDropResources(false);
                    addScrollBar();
                  }}
                  to="/termin"
                >
                  {content.link7}
                </Link>
              </li>
            </ul> */}
            <Link
              onClick={() => {
                setOpenDrop(false);
                setOpenDropResources(false);
                addScrollBar();
              }}
              to="/contact"
              className="mr-4"
            >
              {content.link8}
            </Link>
            <a href="https://app.aktenplatz.de" target="_blank" rel="noreferrer">
              <button className="w-[150px] sm:w-[172px] h-[50px] rounded-lg bg-transparent border-2 border-orange text-orange font-bold mr-1">
                Login
              </button>
            </a>
            {!tennisLanding && (
              <>
                {" "}
                <Link
                  onClick={() => {
                    setOpenDrop(false);
                    setOpenDropResources(false);
                    addScrollBar();
                  }}
                  to="/termin"
                  rel="noreferrer"
                  className="btn-primary p-0 w-[172px] h-[50px] grid place-items-center"
                >
                  {content.btnText}
                </Link>
              </>
            )}
            {tennisLanding && (
              <>
                {" "}
                <a
                  onClick={() => {
                    setOpenDrop(false);
                    setOpenDropResources(false);
                    addScrollBar();
                  }}
                  href="https://app.mysport.tennis/register"
                  target="_blank"
                  rel="noreferrer"
                  className="btn-primary-tennis p-0 w-[172px] h-[50px] grid place-items-center"
                >
                  {content.btnText}
                </a>
              </>
            )}
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;

const DropDownCol = ({
  heading,
  options,
  mobile,
  addScrollBar,
  view,
  viewUrl,
  setDrop,
}) => {
  return (
    <div className="flex justify-start items-start flex-col gap-5 xl:grid grid-rows-[auto__1fr__auto]  xl:gap-8">
      <h2 className="text-base 2xl:text-lg font-bold text-black">{heading}</h2>
      <div className="flex justify-start items-start w-full flex-col gap-8">
        {options?.map((elem, idx) => {
          if (elem.url.includes("http")) {
            return (
              <Link
                onClick={() => {
                  if (mobile) {
                    setDrop("hidden");
                    addScrollBar();
                  }
                  window.open(elem.url, "_blank");
                }}
                key={idx + elem.title}
                className="flex justify-start max-w-[350px] xl:p-3 rounded-[5px] hover:bg-orange transition-all duration-300 group items-start gap-5 h-max"
              >
                {/* <img
                src={elem.img}
                className="w-[30px] 2xl:w-[35px] object-contain group-hover:invert-0 transition-all duration-300 invert"
                alt=""
              /> */}
                <FontAwesomeIcon
                  icon={elem.img}
                  size="2x"
                  className="text-black group-hover:text-white transition-all duration-300"
                />
                <div className="flex justify-start items-start flex-col ">
                  <h4 className="text-base 2xl:text-lg text-black group-hover:text-white font-semibold transition-all duration-300">
                    {elem.title}
                  </h4>
                  <p className="text-[#495765] text-sm group-hover:text-white transition-all duration-300">
                    {elem.desc}
                  </p>
                </div>
              </Link>
            );
          } else {
            return (
              <Link
                onClick={() => {
                  if (mobile) {
                    setDrop("hidden");
                    addScrollBar();
                  } else {
                    setDrop(false);
                  }
                }}
                to={elem.url}
                key={idx + elem.title}
                className="flex justify-start max-w-[350px] xl:p-3 rounded-[5px] hover:bg-orange transition-all duration-300 group items-start gap-5 h-max"
              >
                {/* <img
                src={elem.img}
                className="w-[30px] 2xl:w-[35px] object-contain group-hover:invert-0 transition-all duration-300 invert"
                alt=""
              /> */}
                <FontAwesomeIcon
                  icon={elem.img}
                  size="2x"
                  className="text-black group-hover:text-white transition-all duration-300"
                />
                <div className="flex justify-start items-start flex-col ">
                  <h4 className="text-base 2xl:text-lg text-black group-hover:text-white font-semibold transition-all duration-300">
                    {elem.title}
                  </h4>
                  <p className="text-[#495765] text-sm group-hover:text-white transition-all duration-300">
                    {elem.desc}
                  </p>
                </div>
              </Link>
            );
          }
        })}
      </div>
      {view && (
        <>
          <Link
            onClick={() => {
              if (mobile) {
                setDrop("hidden");
                addScrollBar();
              } else {
                setDrop(false);
              }
            }}
            to={viewUrl}
            className="flex  font-semibold  justify-start items-center gap-3 text-black hover:text-orange transition-all duration-300"
          >
            <p className="text-sm xl:text-base">{view} </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 xl:w-6 h-5 xl:h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </Link>
        </>
      )}
    </div>
  );
};
