export const languageData = {
  german: {
    reference: {
      title:
        "Mehr Zeit für Beratung, weniger für Verwaltung: Aktenplatz und der moderne Lohnsteuerhilfeverein🗄️🥳",
      review1:
        "Die Implementierung von Aktenplatz war eine sehr gute Entscheidung. Die Software hat unsere Produktivität gesteigert und gleichzeitig die Qualität unserer Arbeit gegenüber den Mitgliedern verbessert.",
      challenges: {
        title: "Herausforderungen",
        description: "Die Zukunft ist digital, die Probleme real",
        point1: "Rechtskonformität​",
        point2: "Kosten",
        point3: "Schulung, Unterstützung, Akzeptanz",
      },
      solutions: {
        title: "Lösungen",
        description:
          "Optimierte Software als Antwort auf digitale Herausforderungen",
        pointsTitle: "Genutze Aktenplatz Funktionen:",
        point1: "Digitales Ablagesystem",
        point2: "Archivierung",
        point2Extra: "GoBD Konform",
        point3: "Outlook Kalender Integration",
        point3Extra: "M365 Schnittstelle",
        point4: "Automatische Terminfindung",
      },
      reduction: "weniger Aufwand bei der Aktenverfolgung",
      increase: "mehr Mitglieder, durch schnellere Verwaltung",
      usecase1: {
        title: "Use Case #1",
        challengeTitle: {
          orange: "Die Herausforderung",
          black: "im Alltag:",
        },
        challenge: "Keine Akte darf unter den Tisch fallen",
        challengeDescription:
          "Es ist von entscheidender Bedeutung, den Fortschritt jeder Akte im Blick zu behalten, um eine effiziente und zuverlässige Arbeitsweise sicherzustellen. Jede übersehene Akte kann zu Fehlern, Verzögerungen und Unzufriedenheit bei den Mitgliedern führen. Die lückenlose Verfolgung gewährleistet, dass keine Aufgabe vergessen wird und unterstützt die Qualität der Dienstleistungen.",
        stepsIn: "Hier kommt Aktenplatz ins Spiel",
        solutionTitle: "Die Lösung:",
        solution:
          "Nie wieder verpasste Fristen durch das Aktenplatz-Ablagesystem. Es behält alles im Blick, setzt Wiedervorlagen und führt Folgeaktionen automatisiert nach Ihren Regeln aus.",
        solutionDescription1:
          "Aktenplatz behält die Akten in einem 3 Schritte-System im Auge:",
        point1: "Beobachten und Hervorheben von Wiedervorlagen",
        point2: "Automatische Mitglieder-Kommunikation über Fortschritte",
        point3: "Erstellen von Aufgaben und Zordnung von Verantwortlichkeiten",
        solutionDescription2: {
          normal1:
            "Um Beratungsstellen die Einführung besonders einfach zu machen, haben wir verschiedene",
          bold1: "Vorlagen",
          normal2:
            "in Zusammenarbeit mit der Beratungsstelle von Frau Ladewig erarbeitet. Zusätzlich haben wir einen ",
          bold2: "Beratungsstellen Support ",
          normal3: "mit spezieller Schulung aufgebaut.",
        },
      },
      about1: {
        para: {
          bold: "Projektvorlagen",
          normal:
            "helfen dabei blitzschnell mit Aktenplatz zu starten. Unsere Vorlagen bieten eine initiale Struktur, die nicht nur den Einstieg erleichtert, sondern auch den Weg zu Ihren Arbeitszielen effizient gestaltet. Sie sind speziell entwickelt, um Ihnen einen schnellen Start zu ermöglichen und auch erste Automatisierungserfolge greifbar zu machen. Entdecken Sie den einfachen Weg um in Aktenplatz einzusteigen!",
        },
      },
      about2: {
        para: {
          normal1: "Um Nutzer zu unterstützen, integriert Aktenplatz",
          bold1: "Automatisierungsfunktionen",
          normal2:
            " für die Verfolgung von Fristen und Wiedervorlagen. Dies ermöglicht den Nutzern eine sorgenfreie Handhabung von Akten und Fristen und dient als ständiges Feature innerhalb der Anwendung, das nach Bedarf jederzeit angepasst werden kann.",
        },
        pointsTitle: {
          normal1: "Aktenplatz nutzt die",
          bold1: "Automatisierung",
          normal2: ", um:",
        },
        point1:
          "Effizienz zu steigern, indem automatisch anstehende Fristen und Wiedervorlagen identifiziert und Benachrichtigungen versendet werden.",
        point2:
          "Risikominderung zu gewährleisten, indem es sicherstellt, dass kein wichtiges Datum oder keine Aufgabe übersehen wird, durch die automatische Erinnerungsfunktion.",
      },
      about3: {
        pointsTitle: {
          normal1: "Aktenplatz nutzt eine",
          bold1: "automatisierte Mitglieder-Kommunikation",
          normal2: "und liefert so",
        },
        point1:
          "Proaktive Informationen, indem Mitglieder automatisch über Änderungen, Fortschritte und fehlende Unterlagen informiert werden, ohne dass sie nachfragen müssen.",
        point2:
          "Reduzierung von Mitglieder-Anfragen, indem die automatisierte Kommunikation dazu beiträgt, die Menge an eingehenden Telefonanrufen und E-Mails erheblich zu verringern.",
        para: "Die Implementierung der automatisierten Mitglieder-Kommunikation durch Aktenplatz führt so nicht nur zu einer signifikanten Reduzierung von Anfragen via Telefon und E-Mail, sondern auch zu einer merklichen Steigerung der Mitglieder-Zufriedenheit durch stetige, proaktive Updates und Informationen",
      },
      usecase2: {
        title: "Use Case #2",
        challengeTitle: {
          white: "Terminfindung",
          black: "als Herausforderung:",
        },
        challenge:
          "Im Kontakt mit den Mitgliedern machen dicht gedrängte Termine und volle Kalender, Planungen schwierig.",
        solutionTitle: "Die Lösung:",
        solution: "Automatische Terminfindung",
        solutionDescription: {
          normal1: "Aktenplatz ermöglicht mittels",
          bold1: "Google und Outlook-Integration",
          normal2:
            "und anpassbaren Regeln eine effiziente und benutzerdefinierte",
          bold2: "Terminfindung und -planung",
          normal3: ", selbst in dicht gefüllten Kalendern.",
        },
      },
      npsSurvey: {
        pointsTitle: "Terminregeln umfassen:",
        point1: "Besonderheiten der Mitglieder",
        point2: "Vermietung, Kapitalerträge oder Beamtenstatus",
        point3: "Zusatzzeiten",
        point4: "Urlaubs- und Fortbildungstage",
        point5: "Telefon- und Auswärtstermine",
      },
      cancellationSurvey: {
        bold: "Terminbestätigungen und Absagen",
        normal:
          "optimieren und vereinfachen die Mitglieder-Kommunikation, indem sie Klarheit und Zuverlässigkeit gewährleisten.",
      },
      netPromoterSurvey: {
        normal1: "Der",
        bold1: "Aktenplatz Kalender",
        normal2:
          " revolutioniert die Arbeitsweise durch die intelligente Verknüpfung zwischen Akten, Terminen und Kontakten, wodurch eine zentralisierte, strukturierte Übersicht aller relevanten Informationen ermöglicht wird. Durch die Eliminierung des Bedarfs, zwischen verschiedenen Anwendungen oder Fenstern zu wechseln, ermöglicht Aktenplatz eine nahtlose, effiziente Navigation und Verwaltung aller berufsrelevanten Daten an einem Ort. Dies fördert nicht nur eine gesteigerte Arbeitsproduktivität, sondern auch eine verbesserte Mitglieder-Erfahrung, da alle notwendigen Informationen und Termine stets leicht zugänglich und übersichtlich organisiert sind.",
        normal3: "",
      },
      apiSurvey:
        "Durch den Einsatz unterschiedlicher Kategorien, Farben und Mitarbeiterzuweisungen im Aktenplatz Kalender können effiziente Dienstpläne erstellt sowie Termine und Verfügbarkeiten klar und strukturiert verwaltet werden.",
      review2:
        "Seit wir Aktenplatz für die automatische Terminfindung nutzen, sind die Telefonate mit Mitgliedern erheblich kürzer und mein Büroalltag ist spürbar effizienter geworden.",
      about: {
        title: "Über den",
        description1: {
          link: "Der Aktuell Lohnsteuerhilfeverein e.V.",
          normal:
            "ist einer der größten Lohnsteuerhilfevereine in Deutschland.",
        },
        description2: {
          normal:
            "Er berät Arbeitnehmer:innen, Studenten:innen, Rentner:innen und Pensionäre:innen bei ausschließlich nichtselbständigen Einkünften im Rahmen einer Mitgliedschaft begrenzt nach § 4 Nr. 11 StBerG. Jede Beratungsstelle kümmert sich mit großer Sorgfalt und Kompetenz um die Steuerangelegenheiten ihrer Mitglieder. Daher haben Sie mit dem ALH einen verlässlichen und fachkundigen Ansprechpartner, wenn es um Ihre Steuern geht. Mehr auf:",
          link: "www.aktuell-verein.de",
        },
        point1: "800 Beratungsstellen",
        point2: "300.000 Mitglieder",
        point3: "Deutschlandweit",
      },
      wantToLearn: {
        title:
          "Wollen Sie wissen wie Aktenplatz Ihren Büroalltag optimieren kann?",
        description: {
          link: "Sprechen Sie mit einem unserer",
          normal: "Experten für Büroorganisation",
        },
      },
      interested: {
        title: "Interessiert?",
        description:
          "Möchten Sie erfahren, wie Sie mit Aktenplatz am Besten starten? Vereinbaren Sie einen Termin mit unseren Exprten!",
        button1: "Probieren Sie es selbst",
        button2: "Demo buchen",
        ps: "Keine Kreditkarte erforderlich, kostenlose 14-tägige Testversion.",
      },
    },
    application: {
      title: "Die ideale Lösung im Prozess- & Dokumentenmanagement",
      description:
        "Anwendungsfälle zu den Themen Dokumente verwalten, organisieren, archivieren, teilen, suchen, genehmigen...",
      heading: "Die ideale Lösung im Prozess- & Dokumentenmanagement",
      sub: "Dokumente verwalten, organisieren, archivieren, teilen, suchen, genehmigen... auch Ihr Anwendungsfall ist mit Sicherheit dabei",
      bottomText:
        "Erleben Sie maximale Effizienz in Ihrem Workflow: Entdecken Sie, wie Aktenplatz auch für Ihren Anwendungsfall eingesetzt werden kann. Von ausgewählten Fällen inspiriert, bieten wir Ihnen individuelle Lösungen für Ihre Herausforderungen.",
      btnText: "Jetzt Vertrieb kontaktieren",
      urlLang: "Mehr erfahren",
      boxes: [
        {
          title: "Papierloses Büro",
          img: "paper-plane",
          desc: "Papierlos: Die Zukunft des Dokumentenmanagements",
          url: "/papierlos",
        },
        {
          title: "Digitalisierung von Geschäftsprozessen",
          img: "diagram-project",
          desc: "Verbesserung von Geschäftspraktiken durch digitale Transformation",
          // url: "/geschaeftsprozesse",
        },
        {
          title: "Revisionssichere Archivierung",
          img: "box-archive",
          desc: "Archivierung - automatisch, rechtssicher, GoBD konform",
          url: "/revisionssicher",
        },
        {
          title: "Immobilienverwaltung",
          img: "building",
          desc: "Mehr Effizienz, bessere Überblick und zufriedene Mieter",
          // url: "/immobilien",
        },
        {
          title: "Verwaltung von Versicherungsfällen",
          img: "car-burst",
          desc: "Aktenplatz bringt Effizienz und Transparenz in die Verwaltung von Versicherungsfällen",
          // url: "/versicherung",
        },
        {
          title: "Digitale Lieferscheine",
          img: "dolly",
          desc: "Fehlende Lieferscheine gehören der Vergangenheit an",
          url: "/lieferschein",
        },
        {
          title: "Aufgabenmanagement",
          img: "list-check",
          desc: "Erhöhen Sie die Produktivität: Entdecken Sie die Vorteile der digitalen Aufgabenverwaltung",
          // url: "/aufgaben",
        },
        {
          title: "Ortsunabhängigkeit",
          img: "map-pin",
          desc: "Flexible und effiziente Arbeit mit Dokumenten auch von unterwegs direkt aus unserer App",
          // url: "/ort",
        },
        {
          title: "Ideenmanagement",
          img: "comments",
          desc: "Entfesseln Sie die kreative Energie Ihres Teams und formen Sie Ihre Ideen zu Projekten, die den Geschäftserfolg optimieren.",
          // url: "/ideen",
        },
        {
          title: "Pinnwand Mail",
          img: "envelopes-bulk",
          desc: "Verwandeln Sie Mail-Kommunikation in ein effizientes Kanban-Board",
          url: "/mail",
        },
        {
          title: "Zeiterfassung",
          img: "business-time",
          desc: "Zeiterfassung ist der Schlüssel zur effektiven Dokumentation",
          // url: "/zeiterfassung",
        },
      ],
    },
    teams: {
      title: "Moderne Teams brauchen moderne Software",
      description:
        "Teams brauchen die beste Software um Ihre Dokumente, Aufgaben und Termine zu verwalten",
      heading: "Ihr Team braucht Aktenplatz!",
      sub: "Egal was sie digitalisieren: aktenplatz passt sich jedem Bedarf an.",
      bottomText:
        "…dies sind nur einige ausgewählte Teams, die die Vorteile von aktenplatz nutzen. Gehört Ihr Team noch nicht dazu? Kontaktieren Sie uns und wir zeigen Ihnen, wie aktenplatz auch für Ihr Team von Nutzen sein kann.",
      btnText: "Jetzt Vertrieb kontaktieren",
      urlLang: "Mehr erfahren",
      boxes: [
        {
          title: "Kundenbetreuung",
          img: "bell-concierge",
          desc: "Verbessern Sie die Kundenzufriedenheit durch effiziente Dokumentation in der Kundenbetreuung",
          // url: "#",
        },
        {
          title: "Personalmanagement",
          img: "address-book",
          desc: "Revolutionieren Sie Ihr Personalmanagement mit der digitalen Personalakte",
          url: "/persmanagement",
        },
        {
          title: "Vertrieb",
          img: "handshake",
          desc: "Maximieren Sie Ihren Vertrieb durch effiziente Dokumentation - Mehr Zeit für Kunden, bessere Entscheidungen, mehr Erfolg",
          // url: "/vertrieb",
        },
        {
          title: "Produktmanagement",
          img: "gift",
          desc: "Optimieren Sie Ihr Produktmanagement durch eine strukturierte Dokumentation - ein wichtiger Schritt zum Erfolg",
          // url: "/produkt",
        },
        {
          title: "Marketing",
          img: "lightbulb",
          desc: "Optimieren Sie Ihr Marketing mit einer effizienten Dokumentation",
          // url: "",
        },
        {
          title: "Eventorganisation",
          img: "champagne-glasses",
          desc: "Effiziente Eventplanung durch Digitalisierung",
          url: "/events",
        },
        {
          title: "Geschäftsführung",
          img: "compass",
          desc: "Unterstützung für Führungsaufgaben völlig neu gedacht",
          url: "/ceo",
        },
        {
          title: "IT-Abteilung",
          img: "computer",
          desc: "Meistern Sie mit Aktenplatz die IT-Dokumentation spielend",
          // url: "#",
        },
        {
          title: "Unternehmensweit",
          img: "building",
          desc: "Intelligente Such- und Klassifizierungstechnologien sorgen für bessere Zugänglichkeit und Übersicht von Wissen im Unternehmen",
          // url: "#",
        },

        // {
        //   title: "Produkt Roadmap",
        //   img: "/img/app-ico7.svg",
        //   desc: "Plane deine Produkt Roadmap grafisch und mit Leichtigkeit, so dass deine Kunden begeistert sind!",
        //   url: "",
        // },
      ],
    },
    branchen: {
      title: "Eine einfache Digitalisierung für jede Branche",
      description:
        "Ihre Branche hat spezielle Anforderungen im Bereich der Digitalisierung? Wir haben die perfekte Lösung für Sie. Wetten?",
      heading: "Die einfache Lösung für jede Branche!",
      sub: "Wir unterstützen jede Branche bei den unterschiedlichen Herausforderungen der Digitalisierung.",
      bottomText:
        "Entdecken Sie die Vorteile von aktenplatz in Ihrer Branche - diese Branchen arbeiten bereits erfolgreich mit uns. Sollte Ihre Branche nicht dabei sein, kein Problem. Kontaktieren Sie uns und lassen Sie uns gemeinsam herausfinden, wie aktenplatz auch in Ihrer Branche zum Einsatz kommen kann.",
      btnText: "Jetzt Vertrieb kontaktieren",
      urlLang: "Mehr erfahren",
      boxes: [
        {
          title: "Bauwesen & Architektur",
          img: "person-digging",
          desc: "Baudokumente sind gut organisiert und einfach auffindbar, was die Übersicht und Planung erleichtert",
          url: "/bauen",
        },
        {
          title: "Handwerk",
          img: "screwdriver-wrench",
          desc: "Nutzung einer digitalen Akte für die Verwaltung von Projekten, Aufgaben und Terminen",
          // url: "/handwerk",
        },
        {
          title: "Immobilien & Hausverwaltung",
          img: "house",
          desc: "Verbesserte Entscheidungsfindung durch schnelle Zugriffsmöglichkeiten auf Dokumente in der Immobilien- und Hausverwaltung",
          // url: "",
        },
        {
          title: "Steuerberater & Lohnsteuerhilfe",
          img: "file-invoice-dollar",
          desc: "Die papierlose Akte zu Ende gedacht. Arbeiten wie früher mit allen Vorteilen",
          url: "/steuerkanzleien",
        },
        {
          title: "Finanz & Kreditwirtschaft",
          img: "piggy-bank",
          desc: "Verbessern Sie Ihre Finanzprozesse durch eine effiziente digitale Aktenverwaltung",
          // url: "",
        },
        {
          title: "Ärzte",
          img: "user-doctor",
          desc: "Eine effiziente Aktenorganisation spart Zeit bei der Suche und Verwaltung von Patientendokumenten",
          url: "/Kieferorthopaedie",
        },
        {
          title: "Gesundheitswesen",
          img: "hospital",
          desc: "Digitales Dokumentenmanagement verbessert die Patientenversorgung im Gesundheitswesen",
          // url: "",
        },
        {
          title: "Verwaltung und öffentlicher Dienst",
          img: "school-flag",
          desc: "Herausforderungen aus kommunalem und öffentlichem Dienst direkt mitgedacht",
          url: "/odienst",
        },
        {
          title: "Handel",
          img: "store",
          desc: "Schnelle und effiziente Ablage: Ein digitales Backoffice im Handel vereinfacht die Verwaltung von Mietobjekten und Rechnungen",
          // url: "",
        },
        {
          title: "Industrie",
          img: "industry",
          desc: "Steigerung der Transparenz und Nachvollziehbarkeit in der Fertigung durch digitale Dokumentation",
          // url: "",
        },
        {
          title: "Medien",
          img: "photo-film",
          desc: "Digitale Akten- und Projektverwaltung sorgt für schnellere Workflows in TV, Nachrichten und Print",
          // url: "#",
        },
        {
          title: "Tourismus",
          img: "plane-departure",
          desc: "Vom Reisebüro bis zum Hotel: Digitale Aktenverwaltung revolutioniert Prozesse im Tourismus",
          // url: "",
        },
      ],
    },
    cases: {
      title: "Erfolgsgeschichten",
      description:
        "Lassen Sie sich von den Onboarding-Geschichten unserer Kunden und ihren Ergebnissen inspirieren.",
      heading: "Erfolgsgeschichten",
      sub: "Lassen Sie sich von den Onboarding-Geschichten unserer Kunden und ihren Ergebnissen inspirieren.",
      bottomText:
        "Entdecken Sie die Vorteile von aktenplatz in Ihrer Branche - diese Branchen arbeiten bereits erfolgreich mit uns. Sollte Ihre Branche nicht dabei sein, kein Problem. Kontaktieren Sie uns und lassen Sie uns gemeinsam herausfinden, wie aktenplatz auch in Ihrer Branche zum Einsatz kommen kann.",
      btnText: "Jetzt Vertrieb kontaktieren",
      urlLang: "Mehr erfahren",
      boxes: [
        {
          title: "ALH Lohnsteuerhilfe Ladewig",
          img: "piggy-bank",
          desc: "Nutzung einer digitalen Akte für die Verwaltung von Stammdaten, Aufgaben und Terminen",
          url: "/reference_alh",
        },
        {
          title: "Docubyte",
          img: "pen-ruler",
          desc: "Baudokumente sind gut organisiert und einfach auffindbar, was die Übersicht und Planung erleichtert",
          // url: "/handwerk",
        },
      ],
    },
    webinars: {
      title: "Webinare",
      description:
        "Nehmen Sie an unseren bevorstehenden Live-Webinaren teil oder sehen Sie sich diese auf Abruf an, um von erstklassigen Experten mehr über die Zukunft des Onboarding-Prozesses zu erfahren.",
      heading: "Webinare",
      sub: "Nehmen Sie an unseren bevorstehenden Live-Webinaren teil oder sehen Sie sich diese auf Abruf an, um von erstklassigen Experten mehr über die Zukunft des Onboarding-Prozesses zu erfahren.",
      bottomText:
        "Entdecken Sie die Vorteile von aktenplatz in Ihrer Branche - diese Branchen arbeiten bereits erfolgreich mit uns. Sollte Ihre Branche nicht dabei sein, kein Problem. Kontaktieren Sie uns und lassen Sie uns gemeinsam herausfinden, wie aktenplatz auch in Ihrer Branche zum Einsatz kommen kann.",
      btnText: "Jetzt Vertrieb kontaktieren",
      urlLang: "Mehr erfahren",
      boxes: [
        {
          title: "Das Geheimnis der Automatisierung",
          img: "",
          desc: "Baudokumente sind gut organisiert und einfach auffindbar, was die Übersicht und Planung erleichtert",
          url: "",
          youtube:
            "https://www.youtube.com/embed/c1xjjnAgHME?si=sujpA2K2MX2Apg0e&amp;controls=1",
        },
        // {
        //   title: "Digitalisierung für Einsteiger",
        //   img: "",
        //   desc: "Nutzung einer digitalen Akte für die Verwaltung von Projekten, Aufgaben und Terminen",
        //   // url: "/handwerk",
        //   youtube: "https://www.youtube.com/embed/c1xjjnAgHME?si=sujpA2K2MX2Apg0e&amp;controls=1"
        // },
      ],
    },
    cheat: {
      title: "Starten Sie jetzt mit Aktenplatz",
      description:
        "Sie sind sich nicht sicher, welche Elemente Sie für Ihren Onboarding-Ablauf auswählen sollten? Unser Spickzettel hilft Ihnen sofort weiter.",
      heading: "Starten Sie jetzt mit Aktenplatz",
      sub: "Sie sind sich nicht sicher, welche Elemente Sie für Ihren Onboarding-Ablauf auswählen sollten? Unser Spickzettel hilft Ihnen sofort weiter.",
      bottomText:
        "Sie sind sich nicht sicher, welche Elemente Sie für Ihren Onboarding-Ablauf auswählen sollten? Unser Spickzettel hilft Ihnen sofort weiter.",
      btnText: "Erhalten Sie Ihr Cheat Sheet",
      urlLang: "Mehr erfahren",
      boxes: [
        {
          title: "Das Geheimnis der Automatisierung",
          img: "person-digging",
          desc: "Baudokumente sind gut organisiert und einfach auffindbar, was die Übersicht und Planung erleichtert",
          url: "/reference_alh",
        },
        {
          title: "Digitalisierung für Einsteiger",
          img: "screwdriver-wrench",
          desc: "Nutzung einer digitalen Akte für die Verwaltung von Projekten, Aufgaben und Terminen",
          // url: "/handwerk",
        },
      ],
      formTitle: "Wohin sollen wir Ihren Spickzettel schicken?",
      formButton: "Bestätigen",
    },
    contact: {
      title: "Schnell und unkompliziert: Kontaktieren Sie uns per Formular",
      description:
        "Sprechen Sie mit unserem Vertriebsteam. Wir helfen Ihnen gerne die perfekte Unterstützung zu finden",
      desc: "Benötigen Sie weitere Informationen oder möchten Sie ein personalisiertes Angebot haben? Senden Sie uns eine Nachricht und wir melden uns so schnell wie möglich bei Ihnen. Gerne beantworten wir Ihnen auch Produktfragen persönlich.",
      heading: "Sprechen Sie mit unserem Vertriebsteam",
      note: "Mit einem Sternchen (*) gekennzeichnete Felder sind Pflichtfelder.",
      label1: "Vorname",
      label2: "Nachname",
      label3: "Firmen-E-Mail",
      label4: "Telefonnummer",
      label5: "Land",
      label6: "Größe",
      label7: "Was möchten Sie mit uns besprechen?",
      messagePlaceholder:
        "Erzählen Sie uns von Ihrem Team und geben Sie uns einige Details zu einem Projekt oder Prozess, den Sie in Aktenplatz erfassen möchten.",
      policy:
        "Indem Sie das Kontrollkästchen aktivieren und auf „Senden“ klicken, stimmen Sie der Datenschutzrichtlinie zu",
      btn: "Schicken",
      choose: "bitte wählen...",
    },
    appointment: {
      title: "Jetzt kostenlosen Demo-Termin vereinbaren",
      description:
        "Unsere Experten haben ein offenes Ohr für Ihre individuellen Anforderungen",
      headline: "Jetzt Demo-Termin verabreden",
      headline_alt: "Individuelles Angebot für maßgeschneiderte Digitalisierung",
      desc1:
        "Haben Sie Probleme die richtigen Unterlagen stets vollständig vor sich zu haben? Führt ein Mitarbeiter Ausfall zu einem regelmäßigen Stillstand? Bleiben die gewünschten Vorteile der Digitalisierung bisher aus?",
      desc1_alt: "Bei aktenplatz.de verstehen wir, dass jedes Unternehmen einzigartige Anforderungen hat, wenn es um die Digitalisierung geht. Deshalb bieten wir keine pauschalen Preise an, sondern maßgeschneiderte Lösungen, die genau auf Ihre Bedürfnisse zugeschnitten sind.",
      link1: "Lassen Sie uns darüber sprechen.",
      form: {
        title: "Buchen Sie Ihre 1-zu-1-Demo, ganz unverbindlich",
        label1: "Vorname",
        label2: "Familienname, Nachname",
        label3: "Familienname, Nachname",
        label4: "Firmengröße",
        placeholder4: "Firmengröße",
        label5: "Land",
        placeholder5: "Land",
        btn: "Wählen Sie Datum und Uhrzeit",
      },
    },
    thankYou: {
      title: "Vielen Dank!",
      description:
        "Unsere Experten haben ein offenes Ohr für Ihre individuellen Anforderungen",
      headline: "Vielen Dank für Ihren Kauf - Jetzt Einrichtung buchen!",
      coFounder: "Mitgründer",
      desc1:
        "Um Ihnen einen reibungslosen Start zu ermöglichen, bieten wir Ihnen einen Einrichtungstermin mit unserem Expertenteam an. Dieser Termin ist bereits im Preis enthalten. Buchen Sie jetzt Ihren Termin und starten Sie optimal in die Nutzung unserer Software.",
      link1: "Lassen Sie uns jetzt Ihren aktenplatz einrichten!",
    },
    header: {
      link1: "Funktionen",
      link2: "Preise",
      link3: "Lösungen",
      link4: "Steuerberater",
      link5: "Kieferorthopädie",
      link6: "Revisionssicheres Archiv",
      link7: "Demo buchen",
      link8: "Vertrieb kontaktieren",
      link9: "Blog",
      link10: "Anleitungen",
      link11: "Ressourcen",
      btnText: "Demo buchen",
      mobLink1: "Vorteile",
      mobLink2: "Vertrauen",
      demo: "Demo vereinbaren",
      test: "Demo buchen",
      col1: {
        heading: "Anwendungsfälle",
        viewUrl: "/application",
        view: "Alle Anwendungsfälle anzeigen",
        options: [
          {
            img: "box-archive",
            title: "Revisionssichere Archivierung",
            desc: "Archivierung - automatisch, rechtssicher, GoBD konform",
            url: "/revisionssicher",
          },
          {
            img: "paper-plane",
            title: "Papierloses Büro",
            desc: "Papierlos: Die Zukunft des Dokumentenmanagements",
            url: "/papierlos",
          },
          {
            img: "dolly",
            title: "Digitale Lieferscheine",
            desc: "Fehlende Lieferscheine gehören der Vergangenheit an",
            url: "/lieferschein",
          },
          {
            img: "envelopes-bulk",
            title: "Pinnwand Mail",
            desc: "Verwandeln Sie Mail-Kommunikation in ein effizientes Kanban-Board",
            url: "/mail",
          },
        ],
      },
      col2: {
        heading: "Teams",
        viewUrl: "/teams",
        view: "Alle Teams anzeigen",
        options: [
          // {
          //   img: "bell-concierge",
          //   title: "Kundenbetreuung",
          //   desc: "Verwalten Sie Ihren Verkaufsprozess vom ersten Lead bis zum Abschluss",
          //   url: "#",
          // },
          {
            img: "address-book",
            title: "Personalmanagement",
            desc: "Revolutionieren Sie Ihr Personalmanagement mit der digitalen Personalakte",
            url: "/persmanagement",
          },
          {
            img: "compass",
            title: "Geschäftsführung",
            desc: "Unterstützung für Führungsaufgaben völlig neu gedacht",
            url: "/ceo",
          },
          {
            img: "champagne-glasses",
            title: "Eventorganisation",
            desc: "Effiziente Eventplanung durch Digitalisierung",
            url: "/events",
          },
        ],
      },
      col3: {
        heading: "Branchen",
        view: "Alle Branchen anzeigen",
        viewUrl: "/branchen",
        options: [
          {
            img: "person-digging",
            title: "Bauwesen & Architektur",
            desc: "Baudokumente sind gut organisiert und einfach auffindbar, was die Übersicht und Planung erleichtert",
            url: "/bauen",
          },
          {
            img: "user-doctor",
            title: "Ärzte",
            desc: "Eine effiziente Aktenorganisation spart Zeit bei der Suche und Verwaltung von Patientendokumenten",
            url: "/Kieferorthopaedie",
          },
          {
            img: "file-invoice-dollar",
            title: "Steuerberater & Lohnsteuerhilfe",
            desc: "Die papierlose Akte zu Ende gedacht. Arbeiten wie früher mit allen Vorteilen",
            url: "/steuerkanzleien",
          },
          {
            img: "school-flag",
            title: "Verwaltung & öffentlicher Dienst",
            desc: "Herausforderungen aus kommunalem und öffentlichem Dienst direkt mitgedacht",
            url: "/odienst",
          },
        ],
      },
      col4Title: "Auszeichnungen",

      col1Resources: {
        heading: "Wissenswertes",
        viewUrl: "/application",
        // view: "Alle Anwendungsfälle anzeigen",
        options: [
          {
            img: "medal",
            title: "Erfolgsgeschichten",
            desc: "Lassen Sie sich von den erfolgreichen Onboarding-Geschichten unserer Kunden inspirieren",
            url: "/cases",
          },
          {
            img: "podcast",
            title: "Webinare",
            desc: "Nehmen Sie an unseren Schulungs-Webinaren teil und erfahren Sie, wie Sie unsere App bei Benutzern begeistern können",
            url: "/webinars",
          },
          {
            img: "blog",
            title: "Blog",
            desc: "Erfahren Sie mehr über Best Practices, Trends & Hacks",
            url: "https://blog.aktenplatz.de",
          },
        ],
      },
      col2Resources: {
        heading: "Dokumentation",
        viewUrl: "/teams",
        // view: "Alle Teams anzeigen",
        options: [
          {
            img: "book",
            title: "Anleitungen",
            desc: "Dokumentation aller Funktionen im aktenplatz",
            url: "https://help.aktenplatz.de/de",
          },
          {
            img: "clapperboard",
            title: "Video tutorials",
            desc: "Lassen Sie sich von unseren Tutorials inspirieren",
            url: "https://www.youtube.com/playlist?list=PLUy_v4DyRSxPmjWKwe_yKw8BhDu5r1o6P",
          },
          {
            img: "clipboard-list",
            title: "Spickzettel",
            desc: "Laden Sie eine Schritt-für-Schritt-Anleitung zur Digitalisierung Ihrer Prozesse herunter",
            url: "/cheat",
          },
        ],
      },
    },
    home: {
      general: {
        title: "Die Software die Arbeit digital organisiert & strukturiert",
        titleTennis:
          "Die Software die das Tennistraining digital organisiert & strukturiert",
        description:
          "Kein ständiges wechseln zwischen Ordnern, Akten, E-Mails und anderen Tools, um Ihre Arbeit auf Kurs zu halten.",
        descriptionTennis:
          "Moderne Hilfsmittel für Tennis-Coaches zur Dokumentation, Verwaltung und Erreichung von Erfolgen!",
        knowMore: "mehr erfahren",
        secureHeadline: "Vertrauensvoll und sicher",
        secureHeadlineSub: "Unsere Versicherungen und Partner",
        startNow: "Jetzt starten",
        fileControlHeadline: "Alle Akten im Griff mit Aktenplatz",
        fileControlHeadlineTennis: "Coaching von der Planung bis zur Analyse",
        fileControlDesc: "Testen Sie Aktenplatz 14 Tage kostenlos.",
        fileControlDescTennis: "Testen Sie mysport.tennis 14 Tage kostenlos.",
        fileControlDesc2: "Keine Kreditkarte erforderlich.",
        btnText: "Demo buchen",
        persQuote:
          "Mein Ziel ist es Software zu bauen die Menschen bei Ihrer täglichen Arbeit unterstützt und nicht frustriert.",
        coFounder: "Mitgründer",
        allFunctions: "Alle Funktionen",
      },
      section1: {
        title: "Organisiert & strukturiert das Arbeiten",
        titleTennis: "Organisiert & strukturiert das Coaching",
        desc: "Kein ständiges wechseln zwischen Ordnern, Akten, E-Mails und anderen Tools, um Ihre Arbeit auf Kurs zu halten. Verfolgen und verwalten Sie alles aus einem zentralen Ort - Ihrem Aktenplatz",
        descTennis:
          "Moderne Hilfsmittel für Tennis-Coaches zur Dokumentation, Verwaltung und Erreichung von Erfolgen!",
        btnText: "Jetzt beginnen",
        subDesc: "Ohne Kreditkarte sofort starten.",
        review1:
          "“Mit Aktenplatz wissen meine Backoffice-Mitarbeiter und ich, um welche Akten (Patienten) wir uns dringend kümmern müssen.“",
        review1Tennis:
          "“Mit einer digitalen Aufgabenverwaltung können wir das Tennistraining optimieren, indem wir gezielte Übungen und Ziele festlegen.“",
        review2:
          "“Mit Aktenplatz managen wir unsere Bauprojekte und legen Unterlagen und Kontakte zentral ab.“",
        review2Tennis:
          "“Durch die Bereitstellung von Trainingsvideos können wir unseren Spielern visuelle Anleitungen und Feedback bieten.“",
        review3:
          "“Mit Aktenplatz verpassen wir keine wichtige Fristen unserer Mitglieder mehr. Wir behalten Überblick über Wiedervorlagen und vereinbarte Termine“",
        review3Tennis:
          "“Dank der Fortschrittsdokumentation können wir den Trainingsverlauf unserer Spieler genau verfolgen, um ihre Leistung kontinuierlich zu steigern“",
      },
      section2: {
        partOneTitle: "Vorgänge digital ablegen:",
        partOneTitleTennis: "Trainingsplanung und -verwaltung",
        partOneTitleSub: "Sparen Sie sich Zeit und Nerven",
        partOneText:
          "Vorgänge und Ablagen sind die Grundbausteine zur Organisation von Arbeit im Aktenplatz. Jeder Vorgang lässt sich mit Checklisten, Fälligkeitsdaten, Anhängen und vielem mehr versehen. Von hier aus können Sie Aufgaben zuweisen, Termine und Kontakte festlegen, Dokumente unterschreiben und noch einiges mehr. Jeder Vorgang wird einer Ablage zugeordnet. Mit Ablagen organisieren Sie Ihre Arbeit einfach besser – Sie können Vorgänge in den Ablagen verschieben, jede Bewegung nachverfolgen und nach Namen, Prioritäten oder Fortschritt sortieren.",
        partOneTextTennis:
          "Mit unserer Software können Tennistrainer und Vereine mühelos detaillierte Trainingspläne erstellen und verwalten. Das intuitive Benutzerinterface ermöglicht das Hinzufügen von Übungen, Spielformen und Schwerpunkten für jedes Training. Trainingspläne können für einzelne Spieler oder für die gesamte Trainingsgruppe angepasst werden. Die Möglichkeit, vorherige Trainingspläne einzusehen und zu analysieren, unterstützt die langfristige Entwicklung der Spieler und sorgt für eine gezielte Leistungssteigerung.",
        partTwoTitle: "Vorgänge enthalten alles was Sie brauchen",
        partTwoTitleTennis: "Spielerfortschritt und Leistungsanalyse",
        partTwoText:
          "Erstellen Sie mit einem Klick einen Aktenplatz-Vorgang und finden Sie heraus, was Sie alles damit anstellen können. Von der digitalen Akte bis zur komplexen Projektverwaltung. Ein Vorgang ist die ideale Unterteilung um Ihre Arbeit in kleinere Schritte zu unterteilen. Ihr virtueller Vorgang ist der zentrale Zugang zu all Ihren Informationen. Egal ob Verknüpfungen oder Dateien. Ziehen Sie einfach jede Information auf ihren digitalen Vorgang und schon ist sie abgespeichert. So müssen Sie nie wieder unübersichtliche Postfächer, Tabellen oder Papierakten durchsuchen.",
        partTwoTextTennis:
          "Durch die umfassende Protokollierung von Spielerleistungen und -fortschritten bietet mysport.tennis wertvolle Einblicke in die individuelle Entwicklung jedes Spielers. Die Software verfolgt präzise statistische Daten, wie Schlaggenauigkeit, Spielfrequenz und körperliche Fitness. Trainer können auf diese Weise Stärken und Schwächen der Spieler identifizieren und darauf basierend personalisierte Trainingspläne erstellen. Durch eine kontinuierliche Leistungsanalyse können Spieler motiviert und gezielt gefördert werden.",
        partThreeTitle: "Verknüpfen Sie was zusammen gehört",
        partThreeTitleTennis: "Problemdokumentation und Lösungsmanagement",
        partThreeText:
          "Ein Arbeitsplatz ist selten rein digital. Ob Sie im Büro auf Ihre Papierakten nicht verzichten wollen oder im Handwerk einen Wartungsvertrag mit einer Anlage verknüpfen wollen. Kein Problem! Mit einem Klick erstellen Sie den Aktenplatz-Sticker in der Form eines QR-Codes. Nun können Sie mit Ihrer Handykamera direkt den digitalen Vorgang aufrufen.",
        partThreeTextTennis:
          "Der Bereich zur Problemdokumentation ermöglicht es Trainern und Spielern, Herausforderungen und Schwierigkeiten während des Trainingsprozesses zu erfassen. Von Verletzungen bis hin zu taktischen Schwierigkeiten können alle Aspekte festgehalten werden. Die Software bietet eine übersichtliche Darstellung, um wiederkehrende Probleme zu erkennen und effektive Lösungen zu erarbeiten. Die systematische Verwaltung von Lösungsansätzen führt zu einer kontinuierlichen Verbesserung der Spielergebnisse.",
        partFourTitle: "Arbeiten Sie am besten zusammen",
        partFourTitleTennis: "Teamkommunikation und Spielerbindung",
        partFourText:
          "Erfassen Sie die Arbeit Ihres Teams an einem gemeinsamen Ort. Laden Sie Ihre Kollegen zu Ihren Projekten ein, weisen Sie sich die digitalen Vorgänge untereinander zu und hinterlegen Sie wichtige Hinweise untereinander direkt in den digitalen Vorgängen. Von überall und wann immer Sie wollen können Sie für Ihre Kollegen wichtige Informationen und Materialen direkt zugänglich machen.",
        partFourTextTennis:
          "Unsere Software fördert eine effiziente Teamkommunikation und stärkt die Bindung zwischen Trainern, Spielern und Vereinen. Die Plattform ermöglicht den direkten Austausch von Informationen, Trainingsplänen und Feedback. Spieler können ihre Erfolge und Fortschritte teilen und sich gegenseitig motivieren. Auch Eltern oder Erziehungsberechtigte können in den Kommunikationsprozess integriert werden, um einen umfassenden Einblick in die Trainingsentwicklung ihrer Kinder zu erhalten. Diese transparente und offene Kommunikation trägt zur positiven Teamdynamik und langfristigen Bindung der Spieler zum Verein bei.",
      },
      functions: {
        advantages: "Ihre Vorteile",
        headline: "Funktionsübersicht",
        privacy: "Datenschutz und Sicherheit",
        privacyDesc:
          "Genießen Sie volle Transparenz und die Gewissheit, dass Sie jederzeit nachvollziehen können, wo etwas gespeichert wird. Auf Wunsch auch auf Ihrem lokalen Server.",
        vWork: "Virtueller Schreibtisch",
        vWorkDesc:
          "Lassen Sie weiter Ihre Akten von Schreibtisch zu Schreibtisch wandern. Nur jetzt wissen Sie auch in der digitalen Welt, bei wem sich welche Akte gerade befindet.",
        vWorkDescTennis:
          "Haben Sie Ihre Trainingsakten stets zur Hand. Egal ob auf Trainingsfahrt, auf dem Tennis-Court oder daheim.",
        reminder: "Erinnerungen",
        reminderDesc:
          "Unser System informiert Sie über Akten zur Wiedervorlage. Verpassen Sie keine Fristen mehr und nehmen Sie die Initiative wieder in die Hand.",
        reminderDescTennis:
          "Unser System informiert Sie über Trainingsdaten zur Wiedervorlage. Verpassen Sie keine Fristen mehr und nehmen Sie die Initiative wieder in die Hand.",
        integration: "Integration",
        integrationDesc:
          "Wir binden Ihre bestehenden digitalen System direkt ein. Egal ob Steuerberatungssoftware, Ablagesysteme bis hin zu Praxisverwaltungssystemen.",
        integrationDescTennis:
          "Wir binden digitale Systeme direkt ein. Egal ob Gesundheitsdaten, EKG Werte oder Fitnessarmbänder.",
        team: "Teamarbeit",
        teamDesc:
          "Schreiben Sie Kommentare, legen Sie Dokumente zentral ab. So, dass Sie, Ihre Mitarbeiter und Ihre Kunden alle Informationen zu der Akte auf einen Blick bereit haben.",
        teamDescTennis:
          "Schreiben Sie Kommentare, legen Sie Dokumente zentral ab. So, dass Sie, Trainer und Athleten alle Informationen zum Training auf einen Blick bereit haben.",
        smartphone: "Smartphone/Tablet",
        smartphoneDesc:
          "Damit Ihnen wirklich nichts durch die Finger rutscht, bieten wir unsere Aktenverwaltung auch auf Ihrem Mobiltelefon und Tablet an.",
        smartphoneDescTennis:
          "Damit Ihnen wirklich nichts durch die Finger rutscht, bieten wir alle Trainingsdaten auch auf Ihrem Mobiltelefon und Tablet an.",
      },
      section3: {
        modules: "Produktmodule",
        headline: "Eine Plattform zur Koordinierung Ihrer gesamten Arbeit",
        desc: "Binden Sie die Apps, mit denen Ihr Team bereits arbeitet, in Aktenplatz ein. Mit mittlerweile mehr als 50 Integrationen können Sie in Aktenplatz alles verknüpfen, was Ihr Team zur Kommunikation, Zusammenarbeit und Koordinierung benötigt.",
        descTennis:
          "Binden Sie die Apps, mit denen Ihr Team bereits arbeitet, in mysport.tennis ein. Mit mittlerweile mehr als 50 Integrationen können Sie in mysport.tennis alles verknüpfen, was Ihr Team zur Kommunikation, Zusammenarbeit und Koordinierung benötigt.",
        mail: "Email Verwaltung",
        mailUrl: "/mail",
        mailp1:
          "Unsere innovative Email Funktion mit integriertem Kanban bietet eine neue Art der E-Mail-Verwaltung. Kanban ist eine visuelle Methode zur Organisation von Aufgaben, die ursprünglich in der Produktionssteuerung entwickelt wurde. In der Email Funktion können Sie E-Mails in verschiedene Spalten wie 'zu tun', 'in Arbeit' und 'erledigt' einordnen und so eine übersichtliche To-Do-Liste erstellen.",
        mailp2:
          "Diese visuelle Organisation hilft Ihnen, den Überblick zu behalten und Ihre E-Mail-Aufgaben effektiv zu verwalten. So können Sie beispielsweise alle E-Mails, die noch bearbeitet werden müssen, in die Spalte 'zu tun' verschieben und diese dann nach und nach bearbeiten, während Sie erledigte E-Mails in die Spalte 'erledigt' verschieben.",
        mailp3:
          "Nutzen Sie jetzt die Vorteile unserer Email Funktion mit integriertem Kanban für eine optimierte E-Mail-Organisation.",
        archive: "Archivierung",
        archiveUrl: "/revisionssicher",
        archivep1:
          "Unsere digitale Aktenarchivierung bietet eine sichere und effiziente Lösung für die Verwaltung Ihrer wichtigen Geschäfts- und Dokumentendaten, die den GoBD (Grundsätze zur ordnungsmäßigen Führung und Aufbewahrung von Büchern, Aufzeichnungen und Unterlagen in elektronischer Form sowie zum Datenzugriff) Richtlinien entspricht.",
        archivep2:
          "Zugriff von überall und jederzeit, Cloud-Speicherung und Schutz vor Datenverlust sind nur einige der Vorteile einer digitalen Aktenarchivierung. Dokumentensuche und -verwaltung werden einfacher durch die Verwendung von Schlagworten und Metadaten. Mit einer digitalen Aktenarchivierungslösung können Sie auf Ihre Dokumente von überall und jederzeit zugreifen und sicherstellen, dass Ihre wichtigen Daten jederzeit sicher und zugänglich sind.",
        archivep3:
          "Eine digitale Aktenarchivierung, die den GoBD Richtlinien entspricht, bietet auch eine hohe Datensicherheit und garantiert die rechtliche Zulässigkeit der elektronischen Aufbewahrung Ihrer Geschäfts- und Dokumentendaten. Wählen Sie jetzt eine digitale Aktenarchivierungslösung, um Ihre Dokumenten-Management-Prozesse zu optimieren und sicherzustellen, dass Sie den gesetzlichen Anforderungen entsprechen.",
        team: "Teamarbeit",
        teamUrl: "/teams",
        teamp1:
          "Aktenplatz ermöglicht es, dass Teams effektiver und produktiver zusammenarbeiten. Teamarbeit ist in vielen Bereichen des täglichen Arbeitsalltags unerlässlich, um Aufträge erfolgreich abzuschließen und gemeinsame Ziele zu erreichen.",
        teamp2:
          "Teamarbeit in moderner Software ermöglicht es, Informationen und Daten in Echtzeit zu teilen, zusammenzuarbeiten und Prozesse zu optimieren. Mit der Verwendung unserer Anwendung können Teams in Echtzeit miteinander kommunizieren und ihre Arbeit koordinieren.",
        teamp3:
          "Investieren Sie in moderne Softwarelösungen, die die Zusammenarbeit Ihres Teams fördern und verbessern, um Arbeitsschritte schneller und effizienter abzuschließen und gemeinsame Ziele zu erreichen. Eine gute Teamarbeit ist entscheidend für den Erfolg in jedem Unternehmen und kann durch die Verwendung von modernen Softwarelösungen verbessert werden.",
        automation: "Automatisierung",
        automationUrl: "/application",
        automationp1:
          "Unsere Software kann die Büroarbeit deutlich automatisieren und verbessern. Mit einer Vielzahl von Tools und Funktionen, wie z.B. automatisierte Aufgabenlisten, Benachrichtigungen und Arbeitsabläufen, können Sie Ihre Prozesse optimieren und Zeit und Ressourcen sparen.",
        automationp2:
          "Eine automatisierte Büroarbeit ermöglicht es Ihnen, Ihre Zeit effektiver zu nutzen und sich auf wichtigere Aufgaben zu konzentrieren. Aktenplatz hilft auch bei der Verwaltung von Aufgaben und Deadlines, was zu einer besseren Organisation und einer höheren Effizienz führt.",
        automationp3:
          "Investieren Sie in Aktenplatz, um Ihre Büroarbeit zu automatisieren und Ihre Prozesse zu optimieren. Sparen Sie Zeit und Ressourcen und verbessern Sie Ihre Organisation und Effizienz.",
        contacts: "Kontakte",
        contactsUrl: "/application",
        contactsp1:
          "Eine moderne Kontaktverwaltung zeichnet sich durch eine intuitive Benutzeroberfläche, zentralisierten Zugriff auf alle Kontakte und eine Vielzahl von Funktionen aus. Dazu gehören beispielsweise die Verwaltung von Kontaktdetails, die Verfolgung von Kommunikationen und Interaktionen sowie die Möglichkeit, Kontakte in benutzerdefinierten Gruppen und Listen zu organisieren.",
        contactsp2:
          "Moderne Kontaktverwaltungslösungen bieten auch die Möglichkeit, Kontakte aus verschiedenen Quellen, wie z.B. E-Mail-Adressbüchern, Social-Media-Konten und CRM-Systemen, zu importieren und zu synchronisieren. So haben Sie alle Kontakte an einem zentralen Ort und können auf sie jederzeit und überall zugreifen.",
        contactsp3:
          "Investieren Sie in eine moderne Kontaktverwaltungslösung wie unsere, um Ihre Kontakte zu organisieren, zu verwalten und zu verfolgen. Verbesseren Sie Ihre Kommunikation, führen Sie effektivere Kundenbeziehungen und sparen Sie Zeit und Ressourcen.",
        folder: "Dateiablage",
        folderUrl: "/papierlos",
        folderp1:
          "Eine moderne Dateiablage wie die von Aktenplatz ist ein wichtiger Bestandteil jeder modernen Büroumgebung. Diese Lösungen bieten eine einfache und sichere Möglichkeit, Dokumente und Daten in der Cloud zu speichern, zu teilen und zu organisieren.",
        folderp2:
          "Sie können Ihre Dokumente in benutzerdefinierten Ordnern und Kategorien organisieren und haben jederzeit von überall auf Ihre Dateien Zugriff. Darüber hinaus können Sie problemlos Zugriffsberechtigungen für Ihre Dateien verwalten und Ihre Teammitglieder einbeziehen, um gemeinsam an Dokumenten zu arbeiten.",
        folderp3:
          "Investieren Sie in die moderne Dateiablage von Aktenplatz, um Ihre Dokumente zu verwalten, zu organisieren und zu teilen. Nutzen Sie die Vorteile einer papierlosen Umgebung und verbessern Sie Ihre Effizienz, Zusammenarbeit und Datensicherheit.",
      },
    },
    footer: {
      contact: "Kontakt",
      datenschutz: "Datenschutzerklärung",
      agb: "AGB",
      impressum: "Impressum",
      status: "Status",
    },
    price: {
      title: "Software Kosten: Vergleich der Preise und Tarife",
      description_meta:
        "Hier finden Sie die aktuelle Preisliste von Aktenplatz und den besten Plan für Ihre Bedürfnisse",
      headline: "Der beste Plan für Ihre Bedürfnisse",
      pricing: "Preisgestaltung",
      desc: "Hier finden Sie die aktuelle Preisliste von Aktenplatz. Alle Preise verstehen sich zuzüglich 19% Mehrwertsteuer.",
      monthly: "Monatliche Abrechnung",
      anually: "Jährliche Abrechnung (bis zu 33% sparen)",
      package1Title: "Einzelplatz",
      package2Title: "Team",
      package3Title: "Professionell",
      perMonth: "pro Monat -",
      packageDescYear: "jährliche Abrechnung",
      packageDescMonth: "monatliche Abrechnung",
      package1Desc:
        "Unser Einstiegspaket für alle Freelancer. Bekommen Sie alle Funktionen die Sie zur Sortierung Ihrer Akten brauchen.",
      package2Desc:
        "Arbeiten Sie im Team? Dieses Paket bietet Ihnen alle Funktionen um gemeinsam Ihre Akten im Blick zu behalten und mit Ihren Kollegen zu bearbeiten.",
      package3Desc:
        "Brauchen Sie besondere Schnittstellen zu vorhandener Software? Dann ist dieses Paket genau das Richtige für Sie.",
      btnTest: "Demo buchen",
      btnBuy: "Jetzt kaufen",
      shortProfile: "Kurzprofil",
      description: "Beschreibung",
      inclusiveStorage: "Speicherplatz inklusive",
      moreSpace: "Je weitere 100GB Speicher",
      moreUser: "Je Nutzer",
      characteristics: "Merkmale",
      issueView: "Aktenschrank Sicht",
      calView: "Kalender Sicht",
      userRoles: "Benutzer / Rollenkonzept",
      customFields: "Benutzerdefinierte Felder",
      externalAccess: "Externer Zugriff",
      push: "Push Benachrichtigungen",
      issuePlace: "Dokumentenablage",
      explorer: "Zugriff über Explorer (ähnl. Netzlaufwerk)",
      reminder: "Erinnerungen",
      smartphone: "Smartphone- und Tablet Oberfläche",
      scan: "Dokumenten-Scan aus App",
      tasks: "Aufgabenmanagement (TODO)",
      label: "Papierakten Label",
      qr: "QR-Code Scanner",
      interfaces: "Schnittstellen",
      outlook: "Outlook Kalender und Kontakte",
      datev: "Datev",
      lexware: "Lexware",
      praxident: "Praxident",
      steuersoft: "Steuersoft",
      revsafe: "Revisionssicheres Archiv",
      installation: "Installation",
      cloud: "Cloud",
      local: "Lokal",
      singleSign: "Single Sign-On",
      templates: "Tempaltes",
      security: "Sicherheit",
      dsgvo: "DGSVO Konform",
      rz: "Deutsches Rechenzentrum",
    },
    steuerkanzleien: {
      title:
        "Digitales Steuerbüro: Effiziente Steuerberatung durch moderne Technologie",
      description:
        "Aktenverwaltung für Steuerbüros und -Berater neu gedacht. Günstig und effizient, ganz anders als DATEV und dennoch kompatibel",
      headline: "Ihr Weg zur digitalen Kanzlei",
      intro:
        "Haben Sie Probleme die richtigen Unterlagen stets vollständig vor sich zu haben? Führt ein Mitarbeiter Ausfall zu einem regelmäßigen Stillstand? Bleiben die gewünschten Vorteile der Digitalisierung bisher aus?",
      head1: "Das Problem herkömmlicher Aktenverwaltung",
      desc1:
        "Die herkömmliche Verwaltung von Steuerakten war schon immer eine Herausforderung. Wenn es schnell gehen muss, fehlen wichtige Dokumente in Ihrem Büro oder in Ihrem Ablagesystem. Zudem kann es schwierig sein, die aktuellste Version der Akten wiederzufinden, wenn sie im Laufe ihrer Lebensspanne mehrmals überarbeitet wurde.",
      desc2:
        "Es ist mühsame Arbeit erforderlich, nur damit Sie alle Informationen zur Hand haben, wenn Sie sie brauchen. Das ist zum einen sehr zeitaufwendig und zum anderen verschwendet die Papierform enorm viel Platz.",
      desc3:
        "Die Digitalisierung ist in der Theorie ein Heilmittel, aber in der Praxis scheitert sie einfach. Man kann alte Akten nicht einscannen und die Software, die einem die Arbeit erleichtern soll, passt einfach nicht zu den eigenen Arbeitsgewohnheiten! Sie ist umständlich und starr und verursacht somit mehr Chaos, anstatt Sie, wie gewünscht, zu unterstützen.",
      desc4:
        "Wir haben uns an die Arbeit gemacht und präsentieren Ihnen nun Stolz unser durchdachtes Dokumentmanagementsystem Aktenplatz.",
      head2: "Aktenplatz führt Sie zur Steuerberatung 4.0",
      desc5:
        "Der Begriff 'Steuerberatung 4.0' ist an eine Initiative der Bundesregierung 'Industrie 4.0' angelehnt und beschreibt die umfassende Digitalisierung von Geschäftsprozessen durch die fortschreitende Vernetzung aller Bereiche in Produktion und Dienstleistung mit moderner Informations- und Kommunikationstechnik",
      desc6:
        "Was wäre, wenn es eine Möglichkeit gäbe, Ihre Akten effizient und übersichtlich zu verwalten? Eine Möglichkeit, mit der Sie jedes Dokument in Sekundenschnelle finden können, unabhängig davon, wer es abgelegt hat oder wann es zuletzt aktualisiert wurde? Hier kommt unsere Aktenmanagement-Software Aktenplatz ins Spiel.",
      desc7:
        "Wir haben eine umfassende Lösung speziell für Steuerberater entwickelt. Eine Lösung, die individuell auf Ihre Bedürfnisse eingestellt und so angepasst wird, dass es mit Ihren gewohnten Arbeitsschritten harmoniert. Sie umgehen somit stundenlanges, manuelles Einstellen des Programms und können sofort loslegen!",
      desc8:
        "Im Gegensatz zu anderen Tools, bieten wir Ihnen keine unnötige Reizüberflutung an Funktionen, sondern genau die, die Sie benötigen.",
      desc9:
        "Um dies zu ermöglichen, stützen wir uns auf einen individuellen Ansatz für die Aktenverwaltung. Einer unserer Experten wird auf Sie zukommen und die verschiedenen Arbeitsabläufe in Ihrem Büro genauestens unter die Lupe nehmen. Anschließend stellt er sicher, dass alles von Anfang bis Ende korrekt eingerichtet ist.",
      desc10:
        "Auf diese Weise können wir gewährleisten, dass Sie den effektiven Service erhalten, den Sie erwarten. Unser Ziel ist es, Ihnen das Leben leichter zu machen. Das beginnt damit, dass unsere Software perfekt zu Ihrem Unternehmen passt.",
      point1: "Papierkosten erfolgreich senken",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head3: "Ihre Ergebnisse aus einer Zusammenarbeit mit uns",
      desc11:
        "Aktenplatz ist kein revolutionäres neues Programm, aber das Erste, dass alle Ihre wichtigen Akten, Kontakte, Notizen und Termine in einem einzigen Arbeitsablauf zusammenfasst. Kein Herumhantieren mit mehreren Programmen oder Aktenordnern mehr! Mit Aktenplatz finden Sie schnell und einfach, die benötigte Akte und den jeweiligen Status dieser.",
      desc12:
        "Auch können Sie nachverfolgen, wer Zugriff auf die einzelnen Dateien hat und welche Änderungen vorgenommen wurden. Außerdem erleichtert Aktenplatz den Austausch mit Kollegen, da es die interne Kommunikation bezüglich bestimmter Akten in einem Programm zusammenfasst und somit erheblich vereinfacht.",
      desc13:
        "Selbst Papierakten, die Sie nur für das Archiv aufbewahren müssen, spüren Sie dank eines speziellen Aufklebers in Ihrer Übersicht auf und rufen diese bei Nachfragen schnell ab. Aktenplatz ist die perfekte Lösung für vielbeschäftigte Steuerberater, die den Überblick über ihre Arbeit behalten müssen.",
      desc14:
        "Profitieren Sie nicht nur von unserer Software, sondern auch von unserem hervorragenden Kundensupport. Wann immer Sie eine Frage haben oder Hilfe benötigen, kommt das Aktenplatz-Suportteam schnell und zuverlässig auf Sie zu und beantwortet Ihr Anliegen umfassend. Wenn Sie sich nicht sicher sind, ob Aktenplatz das Richtige für Sie ist, probieren Sie es einfach aus. Wir sind uns sicher, dass Sie sowohl von der Software als auch vom Service beeindruckt sein werden. Für nähere Informationen rufen Sie uns gerne jederzeit an!",
    },
    revSafe: {
      title: "Revisionssichere Archivierung - automatisch und rechtssicher",
      description:
        "Die Sicherung Ihrer Geschäftsdaten auf Festplatten oder einfachen Cloud- Speichern reicht nicht aus, wir haben die GoBD Konforme Lösung",
      headline: "Archivierung - automatisch und rechtssicher",
      desc1:
        "Die Rechtslage für Unternehmen in Deutschland ist eindeutig: Die Sicherung Ihrer Geschäftsdaten auf Festplatten oder einfachen Cloud- Speichern reicht nicht aus, um die gesetzlichen Vorgaben zu erfüllen.Daten müssen in einem unveränderlichen Speicher abgelegt werden.",
      head1: "Revisionssicher und manipulationssicher",
      desc2:
        "Mit der Archivierung von Aktenplatz und dem unveränderlichen Speicher von Microsoft Azure erfüllen Sie die gesetzlich verpflichtenden Kriterien der Revisionssicherheit: Alle Dateien werden im Originalzustand an einem zentralen Ort in deutschen Rechenzentren archiviert und sind unveränderbar.",
      desc3:
        "Praktisch: Haben Sie versehentlich Dateien gelöscht, sind diese dennoch weiterhin im Archiv enthalten und können jederzeit wiederhergestellt werden. Das Löschen einmal archivierter Dokumente innerhalb des Archivs ist technisch unmöglich. So können auch Viren und externe Angreifer Ihren Daten nichts anhaben. Aktualisieren Sie Ihre Dokumente so werden diese stets in einer neuen Version täglich abgelegt. So können Sie auch jederzeit auf ältere Versionen zugreifen.",
      head2: "Rechtssicher und GoBD-konform",
      desc4:
        "GoBD bedeutet Grundsätze zur ordnungsmäßigen Führung und Aufbewahrung von Büchern, Aufzeichnungen und Unterlagen in elektronischer Form. Es handelt sich um ein BMF-Schreiben. Die GoBD regelt, welche grundsätzlichen Prinzipien Unternehmer für ihre Bücher und sonstigen Aufzeichnungen beachten müssen, damit diese für steuerliche Beweiszwecke von den Finanzbehörden anerkannt werden.",
      desc5:
        "Gemäß der GoBD sind bei der Führung von Büchern in elektronischer oder in Papierform die folgenden Anforderungen zu beachten: Grundsatz der Nachvollziehbarkeit und Nachprüfbarkeit, Grundsätze der Wahrheit, Klarheit und fortlaufenden Aufzeichnung, Vollständigkeit, Einzelaufzeichnungspflicht, Richtigkeit, zeitgerechte Buchungen und Aufzeichnungen, Ordnung und Unveränderbarkeit.",
      head3: "Schutz vor Cyberangriffen",
      desc6:
        "Bei einem Cyberangriff zählt jede Sekunde und Ausfallzeiten sind nicht nur schmerzhaft, sondern vor allem teuer. Unternehmen dürfen deshalb keine Minute verlieren, sondern müssen besser heute als morgen für einen zuverlässigen Datenschutz sorgen. Mit Aktenplatz bieten wir Ihnen genau diesen Schutz, völlig automatisch ohne das Sie sich darum kümmern müssen.",
      desc7:
        "Spielen wir ein potentielles Datenverlust Szenario durch: Auf Ihrem Endgerät befindet sich ein Virus. Dieser verschlüsselt all Ihre Daten. Nicht nur lokal sondern auch auf Ihren Cloud-Speichern. Eventuell bemerken Sie diese Verschlüsselung nicht direkt und schließlich können Sie Ihre Daten nicht wiederherstellen. Die Archivierung mit Aktenplatz und Microsoft Azure hilft Ihnen nun aus. Sämtliche Daten in Ihrem Archiv sind unveränderbar und können somit auch durch Viren nicht verschlüsselt oder unbrauchbar gemacht werden.",
      head4: "Warum ein Produkt von Microsoft?",
      desc8:
        "Unveränderlicher Speicher für Azure-Blobs ist ein Produkt von Microsoft zu dem wir eine spezielle Schnittstelle im Aktenplatz bieten. Sie ist so konzipiert, dass sie von Finanzinstituten und in anderen Branchen für die Erfüllung von Anforderungen in Bezug auf SEC 17a-4(f), CFTC 1.31©-(d), FINRA usw. genutzt werden kann. Ein technisches Whitepaper mit Details dazu, wie dieses Feature bei diesen behördlichen Vorgaben hilft, können Sie im",
      desc9: "herunterladen. Das",
      desc10:
        "enthält ausführliche Informationen zu den Compliancezertifizierungen.",
      desc11:
        "Benutzer erzielen hiermit maximalen Schutz von Daten, da mit dem unveränderlichen Speicher für den Azure-Blob-Dienst sichergestellt ist, dass Daten nicht von Benutzern geändert oder gelöscht werden können, auch wenn diese über Berechtigungen für die Kontoverwaltung verfügen.",
      desc12:
        "Mit dem unveränderlichen Speicher für Azure Storage-Blobs können Benutzer sensible Informationen, die für Beweissicherungsverfahren, strafrechtliche Untersuchungen usw. wichtig sind, für den gewünschten Zeitraum in einem vor Manipulationen geschützten Zustand speichern.",
      desc13:
        "Eine Aufbewahrung für juristische Zwecke erfordert spezielle gesetzliche Anforderungen welche Microsoft mit seinem Produkt Azure-Blobs vollständig erfüllt. Sie bieten eine vorübergehende Unveränderlichkeitsrichtlinie, die für juristische Untersuchungszwecke oder allgemeine Schutzrichtlinien angewendet werden kann. Eine Aufbewahrung für juristische Zwecke speichert Daten in einem WORM-Format (Write-Once, Read-Many), bis sie explizit gelöscht werden. Wenn ein Zeitraum für die Aufbewahrung für juristische Zwecke festgelegt wird, können Dateien erstellt und gelesen, aber nicht geändert oder gelöscht werden. Verwenden Sie eine Aufbewahrung für juristische Zwecke, wenn der Zeitraum, in dem die Daten in einem WORM-Zustand aufbewahrt werden müssen, unbekannt ist.",
      desc14:
        "Mit dem Einsatz von dem von Microsoft entwickelten und betriebenen Produkt bieten wir Ihnen eine langfrisitge und Sichere Aufbewahrung Ihrer Archivdaten, welche alle nötigen Zertifizierungen erfüllt.",
      p1: "Sichere Dokumentaufbewahrung",
      p2: "Einhaltung gesetzlicher Bestimmungen",
      p3: "Sicherung in deutschen Rechenzentren",
      p4: "Datenschutz und Sicherheit gegeben",
      p5: "Automatische Archivierung",
      p6: "GoBD konform",
      head5: "Ihre Ergebnisse aus einer Zusammenarbeit mit uns",
      desc15:
        "„Aktenplatz” ist eine Softwarelösung, die alle Ihre Akten, Notizen und Termine in einen strukturierten Arbeitsablauf zusammenfasst. Mit „Aktenplatz” finden Sie schnell und einfach, die benötigte Akte und den jeweiligen Status dieser. Notizen, Bilder, Emails und weitere Dokumente lassen sich von jedem Arbeitsplatz aus der Akte hinzufügen. Sie können jederzeit nachvollziehen, wer wann welche Akte bearbeitet hat. Gelbe Post-It Sticker gehören endlich der Vergangenheit an",
      desc16:
        "Durch unsere Archivschnittstelle zu Microsoft Azure bieten wir Ihnen einen revisionssicheren Archivspeicher an, in dem all Ihre Dokumente vollständig, automatisch und unveränderbar abgelegt werden.",
    },
    paperless: {
      title: "Papierloses Büro - Effizient durch moderne Akten-Verwaltung",
      description:
        "Ein papierloses Büro: Effizienzsteigerung durch moderne Dokumenten-Verwaltung",
      headline:
        "Ein papierloses Büro: Effizienzsteigerung durch moderne Dokumenten-Verwaltung",
      intro:
        "Eine effiziente Dokumentenverwaltung ist in jedem Büro unerlässlich. Eine moderne und digitale Lösung bietet nicht nur Zeitersparnis, sondern auch eine bessere Übersicht und Zugänglichkeit von wichtigen Unterlagen. Aktenplatz ist eine solche Lösung, die ein modernes, papierloses Büro unterstützt und die Verwaltung von Dokumenten vereinfacht. Lesen Sie weiter, um mehr darüber zu erfahren, wie aktenplatz Ihr Büro optimieren kann.",
      head1: "Einführung in das papierlose Büro",
      desc1:
        "Ein modernes papierloses Büro – Eine Vision, die lange Zeit nur in Science-Fiction-Filmen zu sehen war, ist heute Realität geworden. Die Digitalisierung hat dafür gesorgt, dass Unternehmen und Organisationen ihre Geschäftsprozesse vereinfachen und optimieren können. Eine moderne Bürokultur kann jedoch nur erreicht werden, wenn das Management die technischen Möglichkeiten nutzt, um Papierverwaltung und manuelle Prozesse zu eliminieren. Ein modernes papierloses Büro ist nicht nur ökologisch und kosteneffizient, es ermöglicht auch eine schnellere und einfachere Kommunikation und Zusammenarbeit.",
      desc2:
        "Ein wesentlicher Bestandteil eines modernen papierlosen Büros ist die digitale Dokumentenverwaltung. Die Verwaltung von Papierdokumenten ist zeitaufwändig, unübersichtlich und anfällig für Fehler. Eine digitale Aktenverwaltung bietet dagegen eine sichere und zentralisierte Lösung für die Verwaltung aller Dokumente. Unternehmen können so ihre Geschäftsprozesse beschleunigen und verbessern.",
      head2: "Vorteile einer digitalen Dokumentenverwaltung",
      desc3:
        "Die Software aktenplatz ist eine hervorragende Lösung für die digitale Dokumentenverwaltung. Die Benutzeroberfläche ist einfach zu bedienen und bietet eine übersichtliche Struktur für die Verwaltung von Dokumenten. Die Software unterstützt die Organisation bei der Verwaltung ihrer Dokumente und ermöglicht es, diese einfach und schnell zu finden. Eine intuitive Suche und Filterfunktionen erleichtern die Suche nach bestimmten Dokumenten erheblich.",
      desc4:
        "Ein weiterer Vorteil von aktenplatz ist, dass es eine integrierte Workflow-Lösung bietet. Mit dieser Funktion können Unternehmen ihre Geschäftsprozesse automatisieren und verbessern. Ein Beispiel dafür ist das Genehmigungsverfahren für Dokumente. Statt manuell per E-Mail hin- und herzusenden, kann ein Genehmigungsprozess innerhalb der Software gestartet werden. Die Verantwortlichen erhalten dann eine Benachrichtigung, wenn es an der Zeit ist, das Dokument zu genehmigen.",
      desc5:
        "Das moderne Büro, das auf Papier verzichtet, ist in der Lage, eine Vielzahl von Prozessen zu automatisieren und zu beschleunigen. Es ermöglicht eine einfache und schnelle Ablage und Verwaltung von Dokumenten, was zu einer besseren Organisation und Effizienz führt. Mit einer digitalen Dokumentenverwaltung wie aktenplatz kann das moderne Büro seine Dokumente jederzeit und von überall aus sicher abrufen.",
      desc6:
        "Mit der Software aktenplatz wird das Suchen und Finden von Dokumenten zum Kinderspiel. Die einfache Bedienung und die Möglichkeit, Dokumente per Drag & Drop hochzuladen, vereinfachen den Arbeitsalltag erheblich. Zudem kann man mit aktenplatz durch eine Volltextsuche schnell die gewünschten Dokumente finden und diese auch direkt bearbeiten.",
      desc7:
        "Ein weiterer Vorteil von aktenplatz ist die Möglichkeit, Dokumente mit mehreren Benutzern gleichzeitig zu bearbeiten. Dies erleichtert die Zusammenarbeit im Team und führt zu einer besseren Kommunikation. Außerdem kann man über den integrierten Genehmigungsprozess jederzeit nachvollziehen, wer welche Änderungen an den Dokumenten vorgenommen hat.",
      point1: "Vermeidung von Papierbergen und Platzersparnis",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head3:
        "Sichere Datenverwaltung: Wie wir im papierlosen Büro den Schutz sensibler Informationen garantieren",
      desc8:
        "Auch die Sicherheit der Daten ist bei aktenplatz gewährleistet. Die Daten werden auf hochsicheren Servern in Deutschland gespeichert und sind durch mehrere Sicherheitsschichten geschützt. So kann man sicher sein, dass die Daten jederzeit geschützt sind und nicht in falsche Hände geraten.",
      head4: "Fazit: Warum sich ein Umstieg auf ein papierloses Büro lohnt",
      desc9:
        "Zusammenfassend lässt sich sagen, dass aktenplatz eine wertvolle Unterstützung für das moderne papierlose Büro ist. Es vereinfacht die Dokumentenverwaltung, beschleunigt Prozesse und schützt die Daten. Wenn man ein modernes und effizientes Büro führen möchte, sollte man auf jeden Fall über die Implementierung einer digitalen Dokumentenverwaltung wie aktenplatz nachdenken.",
    },
    delivery: {
      title: "Software unterstützt die Verwaltung von Lieferscheinen",
      description:
        "Papierlose Effizienz: So unterstützt Software die Verwaltung von Lieferscheinen",
      headline:
        "Papierlose Effizienz: So unterstützt Software die Verwaltung von Lieferscheinen",
      intro:
        "Eine papierlose Verwaltung von Lieferscheinen kann für Unternehmen viele Vorteile mit sich bringen. Durch den Einsatz von modernen Technologien wie aktenplatz, kann ein effizienter und schneller Prozess garantiert werden. Ein papierloser Workflow spart nicht nur Zeit und Kosten, sondern führt auch zu einer höheren Übersicht und Transparenz in der Dokumentenverwaltung.",
      head1:
        "Optimierung des Lieferschein-Managements durch aktenplatz: Effizienzsteigerung und Prozessverbesserung",
      desc1:
        "Ein moderner Lieferprozess mit papierlosen Lieferscheinen ist ein Beispiel für den Einsatz digitaler Technologien in Unternehmen. Es bietet viele Vorteile im Vergleich zu herkömmlichen Papierprozessen. Durch den Einsatz einer Dokumentenverwaltungssoftware wie aktenplatz kann dieser Prozess noch effizienter gestaltet werden.",
      desc2:
        "Ein papierloser Lieferprozess beginnt bereits bei der Erstellung der Lieferscheine. Diese können direkt in der Software erstellt werden, ohne dass Papier oder Stifte benötigt werden. Die Informationen werden direkt in die digitale Form übertragen und sind somit direkt verfügbar.",
      desc3:
        "Während des Transports können die Lieferscheine jederzeit eingesehen werden. Dies bedeutet, dass es keine Verzögerungen oder Fehler mehr bei der Überprüfung von Papierdokumenten gibt. Stattdessen können die Daten direkt auf einem Computer oder einem mobilen Gerät abgerufen werden.",
      desc4:
        "Die Überprüfung der Lieferscheine am Empfangsort wird ebenfalls vereinfacht. Die Informationen können direkt auf dem Computer oder mobilen Gerät überprüft werden, ohne dass Papierdokumente gescannt oder fotografiert werden müssen.",
      desc5:
        "Nach der Überprüfung werden die Lieferscheine automatisch in der Dokumentenverwaltungssoftware archiviert. Dies bedeutet, dass sie jederzeit schnell und einfach gefunden werden können, falls sie später noch einmal benötigt werden.",
      head2:
        "Verbesserte Effizienz und Organisation: 6 Vorteile von aktenplatz bei der Verwaltung digitaler Lieferscheine",
      desc6:
        "Aktenplatz ist eine moderne Software, die die Dokumentenverwaltung im papierlosen Büro unterstützt. Hier sind 6 Punkte, die die Vorteile von aktenplatz bei der Verwaltung von Lieferscheinen zeigen:",
      upoint1:
        "Zeitersparnis: Lieferscheine können mit aktenplatz in wenigen Sekunden digital erfasst werden, was eine Zeitersparnis gegenüber der manuellen Ablage von Papierdokumenten bedeutet.",
      upoint2:
        "Übersichtliche Archivierung: Die Lieferscheine werden in aktenplatz übersichtlich und einfach zugänglich archiviert, was eine schnelle Suche nach benötigten Dokumenten ermöglicht.",
      upoint3:
        "Automatisierte Verarbeitung: aktenplatz unterstützt die automatisierte Verarbeitung von Lieferscheinen, was zu einer schnelleren und fehlerfreien Abwicklung führt.",
      upoint4:
        "Papierreduktion: Durch die digitale Verarbeitung von Lieferscheinen kann das Papieraufkommen im Büro reduziert werden, was nicht nur die Umwelt schont, sondern auch den Platzbedarf im Büro verringert.",
      upoint5:
        "Echtzeitüberwachung: Mit aktenplatz kann die Bearbeitung von Lieferscheinen in Echtzeit überwacht werden, was eine schnelle Reaktion auf mögliche Probleme ermöglicht.",
      upoint6:
        "Kosteneinsparungen: Die Verwendung von aktenplatz zur Verwaltung von Lieferscheinen kann zu erheblichen Kosteneinsparungen führen, da Papier, Drucker und Aktenregale nicht mehr benötigt werden und die Abläufe im Büro effizienter gestaltet werden können.",
      point1: "Vermeidung von Papierbergen und Platzersparnis",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head3: "Ihre Ergebnisse aus einer Zusammenarbeit mit uns",
      desc7:
        "Zusammenfassend lässt sich sagen, dass ein papierloser Lieferprozess mit der Unterstützung von aktenplatz viele Vorteile bietet. Es spart Zeit und reduziert Fehler bei der Überprüfung von Dokumenten. Darüber hinaus ist es einfacher, die Lieferscheine zu archivieren und später wiederzufinden. Unternehmen, die auf einen papierlosen Lieferprozess umstellen, werden somit ihre Effizienz steigern und gleichzeitig die Umwelt schonen.",
    },
    mail: {
      title: "Effiziente Verwaltung von E-Mails mithilfe von Kanban",
      description:
        "Eine einfache und übersichtliche Möglichkeit, E-Mails zu verwalten und den Arbeitsablauf zu optimieren",
      headline:
        "Pinnwand-Mail: Effiziente Verwaltung von E-Mails mithilfe von Kanban",
      intro:
        "Mit der Einführung von Pinwand-Mail wird sich die Art und Weise, wie Sie E-Mails verwalten und organisieren, grundlegend verändern. Diese neue Technologie bietet eine einfache und übersichtliche Möglichkeit, E-Mails zu verwalten und den Arbeitsablauf zu optimieren. Lesen Sie weiter, um zu erfahren, wie Pinwand-Mail Ihnen helfen kann, produktiver und effizienter zu arbeiten.",
      head1: "Was ist Pinnwand-Mail?",
      desc1:
        "E-Mail ist ein unverzichtbares Werkzeug im modernen Geschäftsleben, aber die Verwaltung von E-Mails kann schnell zu einer Herausforderung werden. Insbesondere wenn es um den Überblick über den Bearbeitungsstand und die Priorität von Aufgaben geht, kann es schwierig sein, eine systematische Herangehensweise zu entwickeln. Hier kommt Pinnwand-Mail ins Spiel.",
      desc2:
        "Pinnwand-Mail ist eine Art von E-Mail-Management-System, das auf dem Konzept von Kanban basiert. Kanban ist eine Methode zur Visualisierung von Prozessen, die ursprünglich in der Automobilindustrie entwickelt wurde. Pinnwand-Mail nutzt diese Methode, um E-Mails in einer übersichtlichen Weise zu verwalten. Statt E-Mails in einem Posteingang zu sammeln, werden sie in verschiedene Kategorien eingeteilt, wie z.B. 'Zu erledigen', 'In Bearbeitung' und 'Erledigt'. Dies ermöglicht es dem Benutzer, den Fortschritt jeder Aufgabe zu verfolgen und Prioritäten zu setzen.",
      head2: "Diese Vorteile tragen zur Steigerung der Produktivität bei",
      desc3:
        "Eines der wichtigsten Vorteile von Pinnwand-Mail ist die Steigerung der Produktivität. Da alle E-Mails sichtbar und gut organisiert sind, ist es einfacher, den Überblick zu behalten und sich auf die wichtigsten Aufgaben zu konzentrieren. Außerdem kann die Zeit, die für das Suchen und Organisieren von E-Mails aufgewendet wird, minimiert werden. Da jede Aufgabe in einer bestimmten Kategorie eingeteilt ist, kann der Benutzer seine Arbeit effizienter planen und sicherstellen, dass keine Aufgaben vergessen werden.",
      desc4:
        "Ein weiterer Vorteil von Pinnwand-Mail ist die Möglichkeit, einfach mit anderen zusammenzuarbeiten. Da jede Aufgabe in einer bestimmten Kategorie eingeteilt ist, kann jeder Mitarbeiter sehen, welche Aufgaben von anderen bearbeitet werden und wo er eingreifen kann, um zu helfen. Dies verbessert die Kommunikation und Zusammenarbeit im Team erheblich. Die Überwachung des Fortschritts einer Aufgabe wird einfacher und es kann sichergestellt werden, dass jeder seinen Teil zur effizienten Abwicklung beiträgt.",
      desc5:
        "Pinnwand-Mail steht außerdem für Transparenz im Arbeitsprozess. Da alle Aufgaben sichtbar und gut organisiert sind, ist es einfach, den Überblick zu behalten und Verantwortungen zu klären. Außerdem kann es vorkommen, dass Aufgaben zwischen verschiedenen Abteilungen hin- und hergereicht werden. Mit Pinnwand-Mail wird sichergestellt, dass alle Beteiligten wissen, wo eine Aufgabe steht und wer dafür verantwortlich ist.",
      desc6:
        "Nicht zu vergessen ist die Einfachheit, mit der es an die Bedürfnisse eines Unternehmens angepasst werden kann. Da es sich um ein flexibles System handelt, kann es leicht an die Anforderungen eines jeden Teams angepasst werden. Außerdem kann es mit anderen Tools und Anwendungen integriert werden, um eine reibungslose Zusammenarbeit zu ermöglichen.",
      point1: "Vermeidung von Papierbergen und Platzersparnis",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head3:
        "Zusammenfassend: Verbesserte Zusammenarbeit und Effizienz mit Pinwand-Mail",
      desc7:
        "Zusammenfassend kann gesagt werden, dass Pinnwand-Mail eine effiziente Lösung für die Verwaltung von E-Mails ist. Durch die Verwendung von Kanban werden E-Mails gut organisiert und der Überblick wird erleichtert. Außerdem bietet es Vorteile wie Steigerung der Produktivität, verbesserte Zusammenarbeit, Transparenz im Arbeitsprozess und einfache Anpassbarkeit. Unternehmen, die nach einer effektiven Lösung für die Verwaltung ihrer E-Mails suchen, sollten Pinnwand-Mail in Betracht ziehen.",
    },
    persm: {
      title: "Einfache digitale Personalakte: Effizientes HR-Management ",
      description:
        "Personalverwaltung leicht gemacht. Die Software für kleine und mittelständische Unternehmen. Einfach, flexibel und sicher.",
      headline: "Von der Papierflut zur digitalen Personalakte",
      intro:
        "Im modernen Personalmanagement spielt die Digitalisierung eine immer größere Rolle. Dabei ist es nicht nur wichtig, Dokumente in digitaler Form zu speichern, sondern auch Prozesse zu automatisieren und zu optimieren. Ein Dokumentenmanagementsystem wie aktenplatz bietet hierbei zahlreiche Vorteile, um den Personalmanagement-Prozess effizienter und sicherer zu gestalten. In diesem Artikel werfen wir einen genaueren Blick auf den Prozess des Personalmanagements und zeigen, wie aktenplatz dabei helfen kann.",
      head1: "Warum digitale Personalakten für Unternehmen sinnvoll sind",
      desc1:
        "Der erste Schritt im Prozess des Personalmanagements ist die Einstellung neuer Mitarbeiter. Mit aktenplatz können Personalabteilungen die Dokumente im Zusammenhang mit der Einstellung von Mitarbeitern elektronisch verwalten. Von Bewerbungsunterlagen über Lebensläufe bis hin zu Arbeitsverträgen können alle relevanten Dokumente in einer zentralen digitalen Datenbank gespeichert und einfach durchsucht werden. Dies beschleunigt den Einstellungsprozess und stellt sicher, dass wichtige Dokumente nicht verloren gehen oder unbeabsichtigt gelöscht werden.",
      desc2:
        "Sobald ein Mitarbeiter eingestellt wurde, müssen Schulungsunterlagen und andere Dokumente verwaltet werden. Hier kommt aktenplatz ins Spiel. Schulungsunterlagen können digital gespeichert und organisiert werden, um sicherzustellen, dass jeder Mitarbeiter die notwendigen Schulungen absolviert und die erforderlichen Zertifizierungen erhält. Darüber hinaus können Urlaubsanträge, Arbeitszeittabellen und andere personalbezogene Dokumente in der digitalen Datenbank gespeichert werden, um den Zugriff auf Informationen zu vereinfachen und zu beschleunigen.",
      desc3:
        "Ein weiterer Vorteil von aktenplatz im Personalmanagement ist die Möglichkeit, sensible Dokumente sicher aufzubewahren. Vertrauliche Informationen wie Sozialversicherungsnummern, Bankdaten und Leistungsbeurteilungen können durch sichere Passwörter und Zugriffsbeschränkungen geschützt werden. Dies gewährleistet die Einhaltung von Datenschutzbestimmungen und reduziert das Risiko von Datenverlusten oder -diebstahl.",
      desc4:
        "Darüber hinaus kann aktenplatz auch bei der Verwaltung von Mitarbeiterakten und der Aufbewahrung von Informationen nach dem Ausscheiden von Mitarbeitern helfen. Mit aktenplatz können Unternehmen die Daten von ehemaligen Mitarbeitern archivieren, um sicherzustellen, dass sie bei Bedarf leicht zugänglich sind. Dies ist besonders wichtig, wenn es um arbeitsrechtliche Angelegenheiten geht oder wenn ehemalige Mitarbeiter erneut eingestellt werden sollen.",
      head2:
        "Bessere Zusammenarbeit durch einfachen und schnellen Zugriff auf Informationen",
      desc5:
        "Neben der Verbesserung der Prozesse des Personalmanagements kann die Verwendung von aktenplatz auch dazu beitragen, die Effizienz des gesamten Unternehmens zu steigern. Durch die Automatisierung von Prozessen und die Verringerung des manuellen Aufwands können die Mitarbeiter mehr Zeit für wichtige Aufgaben aufwenden und die Produktivität insgesamt steigern. Darüber hinaus können digitale Arbeitsabläufe dazu beitragen, Fehler und Verzögerungen zu minimieren, was zu einem insgesamt reibungsloseren Betrieb führt.",
      desc6:
        "Aktenplatz unterstützt Sie auch bei der Zusammenarbeit im gesamten Unternehmen. Das System ermöglicht die gemeinsame Nutzung von Dokumenten und Dateien zwischen verschiedenen Abteilungen und Mitarbeitern. Dies erleichtert die Zusammenarbeit bei der Einstellung neuer Mitarbeiter, der Verwaltung von Urlaubsanträgen und anderen Personalangelegenheiten. Mitarbeiter können auf alle notwendigen Dokumente und Informationen zugreifen, um ihre Aufgaben effektiv zu erledigen, ohne auf manuelle Prozesse zurückgreifen zu müssen.",
      point1: "Vorhandene Prozesse werden übernommen",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head3:
        "Sicherheit und Datenschutz: Die Bedeutung von aktenplatz für die Aufbewahrung von sensiblen Daten",
      desc7:
        "Die erhöhte Datensicherheit von aktenplatz ist ein weiterer wichtiger Vorteil im Personalmanagement. Dokumente und Dateien, die im System gespeichert sind, sind sicher vor Verlust oder Beschädigung durch Feuer, Diebstahl oder andere unvorhergesehene Ereignisse. Die Sicherheitsfunktionen von aktenplatz gewährleisten, dass der Zugriff auf die gespeicherten Daten kontrolliert und nur von autorisierten Personen durchgeführt werden kann. Durch die Verwendung von aktenplatz im Personalmanagement können Unternehmen sicher sein, dass vertrauliche Daten und Informationen geschützt sind und nicht in die falschen Hände geraten können.",
      head4: "Ihre Ergebnisse aus einer Zusammenarbeit mit uns",
      desc8:
        "Insgesamt bietet der Einsatz von aktenplatz im Personalmanagement eine Reihe von Vorteilen. Durch die Automatisierung von Prozessen, die verbesserte Datensicherheit und die Möglichkeit, gemeinsam an Dokumenten und Dateien zu arbeiten, können Unternehmen ihre Arbeitsabläufe im Personalmanagement optimieren und effektiver gestalten. Das System ist auch einfach zu implementieren und zu bedienen, was dazu beiträgt, dass das Personalmanagement schneller und effektiver arbeiten kann. Als Anbieter von Dokumentenmanagement-Lösungen bietet aktenplatz Unternehmen eine flexible, skalierbare und einfach zu verwaltende Plattform, mit der sie ihre Unterlagen digitalisieren und verwalten können. Mit dem Fokus auf Benutzerfreundlichkeit und Sicherheit ist aktenplatz eine ideale Lösung für Unternehmen, die ihre Arbeitsabläufe im Personalmanagement optimieren und effektiver gestalten möchten.",
    },
    ceo: {
      title: "Unternehmensübergreifendes DMS: Effiziente Zusammenarbeit",
      description:
        "Viele Vorteile für die Geschäftsführung, unternehmensübergreifende Aspekte intern, mit Partnern und Kunden",
      headline:
        "Effizient und sicher: Unternehmensübergreifendes Dokumentenmanagement mit aktenplatz",
      intro:
        "In der heutigen digitalen Welt ist die Verwaltung von Unternehmensdokumenten für die Geschäftsführung von entscheidender Bedeutung. Ein gutes Dokumentenmanagementsystem kann dabei helfen, Zeit und Kosten zu sparen, die Produktivität zu steigern und sicherzustellen, dass wichtige Informationen immer verfügbar und geschützt sind. In diesem Artikel werden wir uns mit den Vorteilen eines Dokumentenmanagementsystems mit aktenplatz für die Geschäftsführung befassen und insbesondere auf unternehmensübergreifende Aspekte eingehen.",
      head1: "Unternehmensübergreifende Zusammenarbeit",
      desc1:
        "Eine der großen Stärken von aktenplatz ist die unternehmensübergreifende Zusammenarbeit. Ein effektiver Austausch von Dokumenten und Informationen ist für jedes Unternehmen wichtig, um Projekte und Geschäftsprozesse erfolgreich zu gestalten. Mit aktenplatz können Dokumente und Informationen einfach und sicher mit Partnern und Lieferanten geteilt werden. Dadurch wird eine optimale Zusammenarbeit und Abstimmung ermöglicht. Es können beispielsweise Verträge mit Kunden und Lieferanten digitalisiert und sicher gespeichert werden, so dass alle notwendigen Informationen jederzeit zur Verfügung stehen.      ",
      point1: "Vermeidung von Papierbergen und Platzersparnis",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head2: "Automatisierung von Geschäftsprozessen",
      desc2:
        "Ein weiterer großer Vorteil von aktenplatz ist die Möglichkeit zur Automatisierung von Geschäftsprozessen. Viele Geschäftsprozesse sind zeitintensiv und erfordern eine hohe manuelle Arbeit, wie beispielsweise die Bearbeitung von Rechnungen. Mit aktenplatz kann dieser Prozess automatisiert werden. Rechnungen können direkt im System erfasst werden und werden automatisch an den entsprechenden Ansprechpartner weitergeleitet. Dies spart nicht nur Zeit und Ressourcen, sondern minimiert auch das Risiko von Fehlern.",
      head3: "Einfache Integration von Drittsystemen",
      desc3:
        "Die meisten Unternehmen nutzen bereits eine Vielzahl von verschiedenen Softwaresystemen, um ihre Geschäftsprozesse zu verwalten. Eine der Stärken von aktenplatz ist die einfache Integration von Drittsystemen. Dadurch können bereits vorhandene Systeme nahtlos in den Dokumentenmanagementprozess integriert werden. Dokumente und Informationen können somit direkt aus anderen Systemen wie dem ERP-System importiert und verarbeitet werden.",
      head4: "Sichere Archivierung von Dokumenten",
      desc4:
        "Ein weiterer großer Vorteil von aktenplatz ist die sichere Archivierung von Dokumenten. Jedes Unternehmen muss eine Vielzahl von Dokumenten archivieren, von Verträgen über Rechnungen bis hin zu Personalakten. Mit aktenplatz können alle Dokumente sicher und verschlüsselt abgelegt werden. Durch die übersichtliche Strukturierung der Dokumente kann jederzeit auf alle notwendigen Informationen zugegriffen werden. Die Sicherheit der Daten hat dabei höchste Priorität, sodass Unternehmen sicher sein können, dass ihre Daten in guten Händen sind.",
      head5: "Effizient: Die Geschäftsführung profitiert",
      desc5:
        "Die Einführung eines Dokumentenmanagementsystems mit aktenplatz bietet der Geschäftsführung viele Vorteile, insbesondere bei unternehmensübergreifenden Aspekten wie der Zusammenarbeit mit Partnern und Kunden. Durch die effiziente Verwaltung und den schnellen Zugriff auf Dokumente können Prozesse beschleunigt und Kosten reduziert werden. Die digitale Personalakte erleichtert zudem das Personalmanagement und kann dazu beitragen, dass das Unternehmen als modern und innovativ wahrgenommen wird.",
    },
    events: {
      title:
        "Veranstaltungen effizient planen & organisieren: Tipps und Tricks",
      description:
        "Mit einem Dokumentenmanagement-System wie aktenplatz können alle relevanten Dokumente an einem zentralen Ort gespeichert werden",
      headline:
        "Effiziente Eventplanung dank Dokumentenmanagement: So optimieren Sie Ihre Prozesse",
      intro:
        "Die Organisation von Events kann eine aufwändige und komplexe Angelegenheit sein, die eine Menge Zeit und Ressourcen erfordert. Ein Dokumentenmanagment-System wie Aktenplatz kann dabei helfen, den gesamten Prozess zu erleichtern und zu optimieren. In diesem Artikel werden die Vorteile eines solchen Systems für die Organisation von Events und Veranstaltungen genauer betrachtet.",
      head1: "Zentrale Speicherung aller Dokumente",
      desc1:
        "Mit einem Dokumentenmanagement-System wie aktenplatz können alle relevanten Dokumente an einem zentralen Ort gespeichert werden. Dies ermöglicht es, schnell und einfach auf alle wichtigen Dokumente zuzugreifen und sie zu bearbeiten. Dies ist besonders wichtig, wenn mehrere Personen an der Organisation der Veranstaltung beteiligt sind und jeder Zugriff auf die gleichen Dokumente haben muss.",
      desc1_1:
        "Die Verbesserung der Teamarbeit ist ein zentrales Ziel bei dem Einsatz von Aktenplatz. Alle Teammitglieder und wenn gewünscht externe Partner haben Zugriff auf dieselben Informationen und können in Echtzeit zusammenarbeiten. Dies erleichtert die Zusammenarbeit, indem jeder über den aktuellen Stand des Projekts informiert ist und die Fortschritte der anderen im Blick hat. Außerdem ermöglicht das System die Zuweisung von Aufgaben und die Verfolgung des Fortschritts, was dazu beiträgt, dass jeder im Team verantwortlich ist und Aufgaben effizienter erledigt werden können. Dies kann dazu beitragen, dass das Event reibungsloser verläuft und mögliche Fehler oder Verzögerungen vermieden werden.",
      point1: "Vermeidung von Papierbergen und Platzersparnis",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head2: "Automatisierung von Aufgaben",
      desc2:
        "Ein weiterer Vorteil von aktenplatz ist die Möglichkeit, Routineaufgaben zu automatisieren. Zum Beispiel können Erinnerungen für wichtige Termine wie Anmeldeschlüsse oder Liefertermine automatisch gesendet werden. Dies reduziert den Aufwand für die manuelle Verwaltung von Terminen und sorgt dafür, dass wichtige Fristen nicht verpasst werden.",
      head3: "Erhöhung der Datensicherheit",
      desc3:
        "Ein Dokumentenmanagement-System wie aktenplatz ermöglicht es, sensible Daten wie persönliche Informationen und Finanzdaten sicher zu speichern. Durch die Verwendung von verschlüsselten Verbindungen und der Speicherung von Daten in hochsicheren Rechenzentren wird ein hohes Maß an Datensicherheit gewährleistet. Dies ist besonders wichtig, da viele Events personenbezogene Daten enthalten.",
      head4:
        "Warum ein Dokumentenmanagementsystem ein Muss für erfolgreiche Events ist",
      desc4:
        "Insgesamt bietet ein Dokumentenmanagementsystem wie aktenplatz eine effiziente und strukturierte Möglichkeit, um die Planung und Organisation von Events zu vereinfachen. Durch die zentrale Verwaltung und den einfachen Zugriff auf alle relevanten Informationen und Dokumente können Missverständnisse und Fehler vermieden werden, was zu einem reibungslosen und erfolgreichen Event beiträgt. Ein solches System ist somit ein Muss für jedes Event, das professionell und erfolgreich durchgeführt werden soll.",
    },
    build: {
      title: "Effiziente Bauplanung & Architektur: Optimierte Arbeitsprozesse",
      description:
        "In der Bauplanung und Architektur sind sorgfältige Dokumentation und Organisation unerlässlich, wir haben einen Vorschlag für Sie",
      headline:
        "Wie ein Dokumentenmanagementsystem die Bauplanung und Architektur optimiert",
      intro:
        "In der Bauplanung und Architektur sind sorgfältige Dokumentation und Organisation unerlässlich, um erfolgreich Projekte zu realisieren. Dokumentenmanagementsysteme können hierbei eine wichtige Rolle spielen, da sie eine effiziente Verwaltung von Informationen und eine nahtlose Zusammenarbeit zwischen Projektbeteiligten ermöglichen. Insbesondere ein Dokumentenmanagementsystem mit Aktenplatz bietet zahlreiche Vorteile, die Architekten, Ingenieure und Baufirmen dabei unterstützen können, ihre Arbeitsprozesse zu optimieren und Zeit und Ressourcen zu sparen.",
      head1:
        "Vorteile der digitalen Dokumentation in der Bauplanung und Architektur",
      desc1:
        "Eine der größten Vorteile eines Dokumentenmanagementsystems mit Aktenplatz in der Bauplanung ist die Möglichkeit, Dokumente und Pläne zentral zu speichern und zu verwalten. Durch die zentrale Speicherung können Projektbeteiligte auf alle relevanten Informationen zugreifen, unabhängig von ihrem Standort oder ihrer Funktion im Projektteam. Das spart Zeit und verhindert, dass wichtige Informationen verloren gehen oder mehrfach erstellt werden müssen. Auch Änderungen und Aktualisierungen können schnell und einfach von allen Projektbeteiligten eingesehen werden, ohne dass dafür lange Email-Ketten oder persönliche Treffen erforderlich sind.",
      desc1_1:
        "Ein weiterer Vorteil von Dokumentenmanagementsystemen mit Aktenplatz in der Bauplanung ist die Möglichkeit, die Zusammenarbeit im Team zu erleichtern. Durch die zentrale Speicherung und Verwaltung von Dokumenten und Plänen haben alle Projektbeteiligten Zugriff auf dieselben Informationen. Dadurch wird sichergestellt, dass alle Beteiligten auf dem gleichen Stand sind und gemeinsam an einem Projekt arbeiten können. Auch die Koordination von Terminen und Aufgaben kann durch das Dokumentenmanagementsystem erleichtert werden. Aufgaben können zugeordnet und überwacht werden, um sicherzustellen, dass alle Projektbeteiligten ihre Verantwortlichkeiten erfüllen.",
      point1: "Vermeidung von Papierbergen und Platzersparnis",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head2: "Kosteneinsparungen durch Reduzierung von Fehlern und Papierkram",
      desc2:
        "Zeit und Kosten sind mit die Relevantesten Kriterien auf der Baustelle, insbesondere in Zeiten von erhöhten Rohstoffpreisen und dem Fachkräftemangel. Durch die effiziente Verwaltung von Dokumenten und Plänen können Projektbeteiligte schnell und einfach auf benötigte Informationen zugreifen, ohne lange suchen zu müssen. Dadurch können Verzögerungen und Kosten durch ineffiziente Prozesse vermieden werden. Auch die Möglichkeit, Änderungen schnell und einfach zu implementieren, spart Zeit und verhindert, dass Fehler gemacht werden. Darüber hinaus können Dokumentenmanagementsysteme auch helfen, die Kommunikation zwischen Projektbeteiligten zu verbessern und Missverständnisse und Fehler zu vermeiden.",
      head3:
        "Papierlos und sicher: Warum Sie jetzt auf ein digitales System umsteigen sollten",
      desc3:
        "Die Baubranche ist ständig im Wandel und es gibt immer neue Technologien und Verfahren, die eingesetzt werden können. Durch die Nutzung von Dokumentenmanagementsystemen können Architekten, Ingenieure und Baufirmen sicherstellen, dass sie auf dem neuesten Stand der Technologie bleiben und die besten Werkzeuge und Verfahren zur Verfügung haben, um ihre Projekte zu realisieren. Dies kann auch dazu beitragen, Wettbewerbsvorteile zu gewinnen, indem Sie schneller und effizienter arbeiten als die Konkurrenz. Dies kann insbesondere bei großen Bauprojekten von Vorteil sein, bei denen eine schnelle und präzise Umsetzung entscheidend für den Erfolg des Projekts ist.",
      desc4:
        "Die Verwendung eines DMS kann auch dazu beitragen, Fehler und Verzögerungen zu reduzieren, indem es den Informationsfluss verbessert und sicherstellt, dass alle Beteiligten auf dem gleichen Stand sind. Auf diese Weise können Baufirmen die Kommunikation und Zusammenarbeit innerhalb des Teams verbessern und sicherstellen, dass das Projekt effizient und reibungslos verläuft.",
      head5:
        "Effizienter bauen mit aktenplatz: So bleibt die Baubranche am Puls der Zeit",
      desc5:
        "Zusammenfassend lässt sich sagen, dass der Einsatz eines Dokumentenmanagementsystems mit Aktenplatz für die Bauplanung und Architektur viele Vorteile bietet. Es kann dazu beitragen, die Effizienz und Produktivität zu steigern, die Zusammenarbeit innerhalb des Teams zu verbessern, Fehler und Verzögerungen zu reduzieren und einen Wettbewerbsvorteil zu schaffen. Angesichts all dieser Vorteile sollten Baufirmen in Betracht ziehen, ein DMS zu implementieren, um ihr Unternehmen auf die nächste Stufe zu bringen und erfolgreich zu sein.",
    },
    odienst: {
      title:
        "Digitaler öffentlicher Dienst: Papierlos für effizientere Prozesse",
      description:
        "Schneller, sicherer und effektiver: Warum Behörden auf aktenplatz setzen sollten",
      headline:
        "Wie aktenplatz Behörden und den öffentlichen Dienst unterstützen kann",
      intro:
        "In der öffentlichen Verwaltung müssen tagtäglich große Mengen von Dokumenten bearbeitet, archiviert und verwaltet werden. Das kann eine immense Herausforderung darstellen, die Zeit und Ressourcen in Anspruch nimmt. Doch ein Dokumentenmanagementsystem (DMS) mit Aktenplatz kann Behörden und dem öffentlichen Dienst dabei helfen, diese Herausforderungen zu bewältigen. In diesem Artikel werden wir uns mit den Vorteilen eines DMS für den öffentlichen Sektor auseinandersetzen.",
      head1: "Effizienzsteigerung durch zentrale Datenspeicherung",
      desc1:
        "Behörden und der öffentliche Dienst verwalten eine Vielzahl von Dokumenten und Informationen. Ein Dokumentenmanagementsystem mit Aktenplatz ermöglicht es, diese Dokumente zentral und übersichtlich zu speichern. Die Dokumente sind somit schnell auffindbar und jederzeit verfügbar. Die manuelle Suche nach Dokumenten und Informationen entfällt. Dies spart Zeit und erhöht die Effizienz bei der Arbeit.",
      desc1_1:
        "Durch den Einsatz eines DMS mit Aktenplatz kann der Aufwand für das Drucken und Archivieren von Dokumenten deutlich reduziert werden. Ein papierloses Büro spart nicht nur Platz, sondern auch Kosten. Durch die Reduzierung des Papierverbrauchs können Behörden und der öffentliche Dienst ihre Umweltbilanz verbessern.      ",
      point1: "Vermeidung von Papierbergen und Platzersparnis",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head2: "Sicherheit und Datenschutz",
      desc2:
        "In Behörden und dem öffentlichen Dienst werden oft vertrauliche Informationen und personenbezogene Daten verarbeitet. Ein DMS mit Aktenplatz bietet eine sichere und verschlüsselte Speicherung dieser Daten. Nur autorisierte Personen haben Zugriff auf die Dokumente und Informationen. Ein DMS trägt somit zur Einhaltung von Datenschutzbestimmungen bei.",
      head3: "Transparente Prozesse und schnelle Bearbeitungszeiten",
      desc3:
        "Ein DMS mit Aktenplatz bietet Behörden und dem öffentlichen Dienst die Möglichkeit, Dokumente und Informationen transparent zu verwalten. Jeder Schritt innerhalb eines Prozesses ist nachvollziehbar dokumentiert. Dadurch werden die Bearbeitungszeiten verkürzt und eine schnellere Erledigung von Anfragen und Aufgaben ist möglich.",
      head4: "Einfache Zusammenarbeit im Team",
      desc4:
        "In Behörden und dem öffentlichen Dienst arbeiten oft viele Personen an einem Projekt oder einer Aufgabe. Ein DMS mit Aktenplatz ermöglicht eine einfache Zusammenarbeit im Team. Dokumente können gleichzeitig bearbeitet werden und die Versionierung wird automatisch vorgenommen. Jeder Beteiligte hat stets Zugriff auf die aktuellste Version. Dies spart Zeit und verhindert Fehler.",
      head5:
        "Schneller, sicherer und effektiver: Warum Behörden auf aktenplatz setzen sollten",
      desc5:
        "Ein Dokumentenmanagementsystem mit Aktenplatz ist für Behörden und den öffentlichen Dienst ein unverzichtbares Werkzeug, um effizienter zu arbeiten. Die zentrale Datenspeicherung, die hohe Sicherheit und Datenschutz, transparente Prozesse und schnelle Bearbeitungszeiten sowie eine einfache Zusammenarbeit im Team führen zu einer effizienteren Arbeitsweise. Durch die Reduzierung des Papierverbrauchs können zudem Kosteneinsparungen erzielt und die Umweltbilanz verbessert werden.",
    },
    doctor: {
      title: "Ihr Weg zur digitalen Patienten-Akte",
      description:
        "Aktenplatz” ist die Beste Softwarelösung, die alle Ihre Patienten-Akten, Notizen und Termine in einen strukturierten Arbeitsablauf zusammenfasst",
      headline: "Ihr Weg zur digitalen Patienten-Akte",
      intro:
        "Haben Sie Probleme die richtigen Unterlagen stets vollständig vor sich zu haben? Führt ein Mitarbeiter Ausfall zu einem regelmäßigen Stillstand? Bleiben die gewünschten Vorteile der Digitalisierung bisher aus?",
      head1:
        "Die Software-Lösung, mit der Sie den Überblick über Ihre Patienten-Akten behalten",
      desc1:
        "Sie haben eine Arztpraxis und möchten Ihre analogen und digitalen Patienten-Akten mit einer einzigen Software übersichtlich verwalten? Mit „Aktenplatz“ haben Sie die Möglichkeit, eine durchgängige Aktenverwaltung parallel auf allen Computern in Ihrer Praxis zu führen. Sie können Ihre Akten virtuell ablegen und auf diese zurückgreifen, wann immer Sie möchten. Sie behalten den Überblick, welche Akte gerade von welcher Mitarbeiterin bearbeitet wird, Informationen gehen nicht mehr verloren und zusätzliche Dateien und Verknüpfungen können hinzugefügt werden. Außerdem können Sie schnell und unkompliziert Aufgaben an das Backoffice weiterleiten, während Sie in der Behandlung sind.",
      head2: "Mit Aktenplatz behalten Sie den Überblick über alle Aktivitäten",
      desc2:
        "Mit „Aktenplatz” als Softwarelösung für Ihre Akten, haben Sie die Möglichkeit, Ihre digitalen und analogen Akten effizient und übersichtlich zu verwalten. Mit unserer Software organisieren Sie ähnlich dem Ablagesystem Ihres Schreibtisches und finden Akten in Sekundenschnelle. Hier ein kleines Beispiel, wie Sie „Aktenplatz” direkt am Arbeitsplatz integrieren können:",
      upoint1:
        "Während der Behandlung ergibt sich eine Aufgabe für das Back-Office (z.B. eine Planverlängerung anzufordern)",
      upoint2:
        "Sie vergeben direkt in Ihrer Praxisverwaltungs-Software das Kürzel PL.",
      upoint3:
        "Das Back-Office gibt eine Notiz an den Arzt, für diesen Patienten eine Planverlängerung zu schreiben.",
      upoint4:
        "Die Akte landet damit direkt in der digitalen Ablage des Arztes.",
      upoint5:
        "Der Arzt schreibt nun die Verlängerung und gibt die Akte digital an das Backoffice zum Versand an die Krankenkasse weiter.",
      upoint6:
        "Das Backoffice wiederum löscht nach Aufgabenerledigung das Kürzel und die Akte verschwindet von dem Stapel des Back-Office.",
      desc3:
        "Dieser übliche Vorgang aus dem Praxisalltag lässt sich selbstverständlich auf andere Routinen in Ihrer Praxis übertragen. Ein paar weitere Beispiele: Im Falle offener Patienten-Rechnungen kann jede Praxis-Mitarbeiterin nachverfolgen, wer bereits telefoniert hat und wann ein nächster Anruf ansteht. Sie können den Ablauf des Schreibens eines Arztbriefes steuern oder sich bei bei verpassten Terminen an neue Terminvereinbarungen erinnern lassen.",
      desc4:
        "Im Gegensatz zu anderen Tools, bieten wir eine aufgeräumte Software, die intuitiv zu bedienen ist. „Aktenplatz” wird voll in Ihr Praxisverwaltungssystem integriert sein. Bei der Einrichtung der Integration hilft Ihnen unser Support-Team.",
      point1: "Integration in Ihre Praxis",
      point2: "Teamarbeit schnell und zuverlässig vernetzen",
      point3: "Kommunikation spielend nachverfolgen",
      point4: "Datenschutz und Sicherheit gegeben",
      point5: "Abläufe automatisieren und wachsen",
      point6: "Mobile Apps und Integrationen",
      head3: "Ihre Ergebnisse aus einer Zusammenarbeit mit uns",
      desc5:
        "„Aktenplatz” ist die erste Softwarelösung, die alle Ihre Patienten-Akten, Notizen und Termine in einen strukturierten Arbeitsablauf zusammenfasst. Mit „Aktenplatz” finden Sie schnell und einfach, die benötigte Akte und den jeweiligen Status dieser. Notizen, Bilder, Emails und weitere Dokumente lassen sich von jedem Arbeitsplatz aus der Akte hinzufügen. Sie können jederzeit nachvollziehen, wer wann welche Akte bearbeitet hat. Gelbe Post-It Sticker gehören endlich der Vergangenheit an",
      desc6:
        "Selbst Papierakten, die Sie nur für das Archiv aufbewahren müssen, spüren Sie dank eines aufgeklebten QR-Codes in Ihrer Datenbank auf und rufen diese bei Nachfragen schnell ab. „Aktenplatz” ist die perfekte Lösung für vielbeschäftigte Ärzte, die den Überblick über ihre Arbeit behalten müssen.",
    },
  },
  english: {
    reference: {
      title: "How FitnessPlayer doubled it's user base and reduced churn 💪",
      review1:
        "Thanks to Product Fruits, we're able to help fitness trainers quickly understand the value our app provides. After launching the first onboarding flows, we almost immediately reduced the churn rate of our trial users by 70% (yes!) and doubled conversions into paying customers.",
      challenges: {
        title: "Challenges",
        description: "High user churn rate and low conversions",
        point1: "The product’s value was overlooked by new users​",
        point2: "Users struggled with orientation within the application",
        point3:
          "The money invested in ads didn't generate lead generation and conversions",
      },
      solutions: {
        title: "Solutions",
        description:
          "Automated onboarding process using Product Fruits' customizable and no-code tool.",
        pointsTitle: "Product Fruits features used:",
        point1: "Checklists",
        point2: "Tours",
        point2Extra: "(feature, welcome)",
        point3: "Help center",
        point3Extra: "(Life Ring Button)",
        point4: "Surveys",
      },
      reduction: "reduction of churn from free trial users",
      increase: "increase in conversion of paying customers",
      usecase1: {
        title: "Use Case #1",
        challengeTitle: {
          orange: "The Challenge",
          black: "for new users:",
        },
        challenge: "How to start using the app",
        challengeDescription:
          "Once logged into the system, new users faced the challenge of understanding how to use the application and create their first music playlist. As a result, the immediate value of the app was delayed and missed. Which resulted in high user churn, particularly during the 14-day trial period.",
        stepsIn: "That's where FitnessPlayer steps in",
        solutionTitle: "The Solution:",
        solution:
          "A three-step automated onboarding process using a combination of a checklist, feature tours, and the help center widget.",
        solutionDescription1:
          "FitnessPlayer identified three key steps (functions) that their users need to undertake to start using the app:",
        point1: "Create a playlist",
        point2: "Search for songs",
        point3: "Finalize the playlist by filling the songs",
        solutionDescription2: {
          normal1: "To guide users through these steps, the app built three",
          bold1: "feature tours",
          normal2: "integrated and launched in a specific order thanks to a",
          bold2: "checklist widget",
          normal3: "(Product Fruits features).",
        },
      },
      about1: {
        para: {
          bold: 'The "Get started" checklist',
          normal: `keeps users motivated and guides them through the correct order of information. It also tracks users' progress, displays their next step actions, and is exclusively triggered for new users. Once all three steps are completed, the checklist is marked as "completed" and dismissed by the user.`,
        },
      },
      about2: {
        para: {
          normal1: "Um Nutzer zu unterstützen, integriert Aktenplatz",
          bold1: "Automatisierungsfunktionen",
          normal2:
            "ür die Verfolgung von Fristen und Wiedervorlagen. Dies ermöglicht den Nutzern eine sorgenfreie Handhabung von Terminen und dient als ständiges Feature innerhalb der Anwendung, das nach Bedarf des Nutzers zugänglich ist.",
        },
        pointsTitle: {
          normal1: "Aktenplatz nutzt die",
          bold1: "Automatisierung",
          normal2: ", um:",
        },
        point1:
          "Offer education upon request by featuring initial onboarding tours, advanced videos, and tutorials.",
        point2:
          "Provide a platform for user communication by adding the chat widget.",
      },
      about3: {
        pointsTitle: {
          normal1: "The application uses the",
          bold1: "help center widget",
          normal2: "to:",
        },
        point1:
          "Offer education upon request by featuring initial tours, advanced videos, and tutorials",
        point2:
          'Provide a platform for communication by adding the chat widget or a "book an individual demo" link.',
        para: "FitnessPlayer customized the design and position of onboarding elements within the app. This helps keep onboarding features in brand alignment.",
      },
      usecase2: {
        title: "Use Case #2",
        challengeTitle: {
          white: "The Challenge",
          black: "for FitnessPlayer:",
        },
        challenge:
          "Discovering what users think about the app and identifying areas for improvement",
        solutionTitle: "The Solution:",
        solution: "Using in-app surveys.",
        solutionDescription: {
          normal1: "In addition to the",
          bold1: "support chat",
          normal2:
            "(incorporated into the help center widget), FitnessPlayer uses",
          bold2: "n-app surveys",
          normal3:
            "to collect relevant user feedback. This tool is particularly helpful for B2C businesses, serving as a simplified yet effective alternative to customer success feedback calls. The application uses and interconnects two types of surveys:",
        },
      },
      npsSurvey: {
        pointsTitle: "NPS Survey to identify:",
        point1: "Users who can promote FitnessPlayer",
        point2: "Users who are at risk of churning from their pricing plan",
      },
      cancellationSurvey: {
        bold: "Cancellation Survey",
        normal:
          "to target users who may consider canceling their subscription and gather feedback to improve app functionality.",
      },
      netPromoterSurvey: {
        normal1: "In the",
        bold1: "Net Promoter Survey",
        normal2:
          ', FitnessPlayer asks its users one question: "How likely would they be to recommend the app to a colleague or friend?" For respondents indicating a negative likelihood to recommend (selecting 0-6 out of a total of 10 points), they are redirected to the',
        bold2: "Cancellation Survey",
        normal3:
          ". In this survey, users are asked how the app can enhance their customer experience and retain their loyalty, preventing them from churning.",
      },
      apiSurvey:
        "In these situations, Product Fruits also recommends API surveys. These enable triggers for specific actions based on the users response. For example, offering users at risk of churn a discount or special offer.",
      review2:
        "The NPS Survey is truly a super easy way for us to collect more knowledge about the status of our service, and it also gives us insights into which features we should focus on next, based on our users' wishes.",
      about: {
        title: "About the",
        description1: {
          link: "FitnessPlayer",
          normal:
            "is the ultimate companion for fitness studios and professional trainers in Sweden and the UK. It's a Swedish-based fitness app that makes preparation for fitness classes a breeze!",
        },
        description2: {
          normal:
            "With an extensive music selection for every type of sports class, trainers can cut their preparation time by a whopping 80% and use 100% legal music - eliminating any risks associated with unauthorized music use. No more worries - FitnessPlayer is here to be your best friend in fitness! More at",
          link: "www.fitnessplayer.co.uk",
        },
        point1: "123 000 active playlists created by fitness professionals",
        point2: "7 000+ active users",
        point3:
          "Covering Sweden and the UK, currently expanding into new EU markets",
      },
      wantToLearn: {
        title: "Want to learn how to improve your trial conversion rate?",
        description: {
          link: "Talk to user onboarding specialists",
          normal: "today.",
        },
      },
      interested: {
        title: "Interested?",
        description:
          "Would you like to learn how to set up your own user onboarding? Book a call with our adoption expert!",
        button1: "Try For Free",
        button2: "Book a Demo",
        ps: "No credit card needed, free 14-day trial.",
      },
    },
    application: {
      title: "The ideal solution in process and document management",
      description:
        "Use cases for managing, organizing, archiving, sharing, searching, approving documents...",
      heading: "The ideal solution in process and document management",
      sub: "Manage, organize, archive, share, search, approve documents... your use case is sure to be included",
      bottomText:
        "Experience maximum efficiency in your workflow: Discover how file space can also be used for your application. Inspired by selected cases, we offer you individual solutions for your challenges.",
      btnText: "Contact sales now",
      urlLang: "Learn more",
      boxes: [
        {
          title: "Paperless Office",
          img: "paper-plane",
          desc: "Paperless: The Future of Document Management",
          url: "/papierlos",
        },
        {
          title: "Digitization of Business Processes",
          img: "diagram-project",
          desc: "Improving business practices through digital transformation",
          // url: "/businessprocesses",
        },
        {
          title: "Revision-proof archiving",
          img: "box-archive",
          desc: "Archiving - automatic, legally secure, GoBD-compliant",
          url: "/revisionssicher",
        },
        {
          title: "Property Management",
          img: "building",
          desc: "More efficiency, better overview and satisfied tenants",
          // url: "/real estate",
        },
        {
          title: "Claim Management",
          img: "car-burst",
          desc: "Aktenplatz brings efficiency and transparency to the management of insurance claims",
          // url: "/insurance",
        },
        {
          title: "Digital Delivery Notes",
          img: "dolly",
          desc: "Missing delivery notes are a thing of the past",
          url: "/lieferschein",
        },
        {
          title: "Task Management",
          img: "list-check",
          desc: "Increase Productivity: Discover the Benefits of Digital Task Management",
          // url: "/tasks",
        },
        {
          title: "Location Independence",
          img: "map-pin",
          desc: "Flexible and efficient work with documents, even on the go directly from our app",
          // url: "/place",
        },
        {
          title: "Idea Management",
          img: "comments",
          desc: "Unleash the creative energy of your team and shape your ideas into projects that optimize business success.",
          // url: "/ideas",
        },
        {
          title: "Board Mail",
          img: "envelopes-bulk",
          desc: "Turn mail communication into an efficient Kanban board",
          url: "/mail",
        },
        {
          title: "Time Tracking",
          img: "business-time",
          desc: "Time tracking is the key to effective documentation",
          // url: "/time tracking",
        },
      ],
    },
    teams: {
      title: "Modern teams need modern software",
      description:
        "Teams need the best software to manage their documents, tasks and appointments",
      heading: "Your team needs file space!",
      sub: "No matter what you digitize: aktenplatz adapts to every need.",
      bottomText:
        "...these are just a few selected teams that take advantage of aktenplatz. Isn't your team one of them yet? Contact us and we'll show you how aktenplatz can benefit your team.",
      btnText: "Contact sales now",
      urlLang: "Learn more",
      boxes: [
        {
          title: "Customer Care",
          img: "bell-concierge",
          desc: "Improve customer satisfaction through efficient documentation in customer support",
          // url: "#",
        },
        {
          title: "Personnel Management",
          img: "address-book",
          desc: "Revolutionize your personnel management with the digital personnel file",
          url: "/persmanagement",
        },
        {
          title: "Sales",
          img: "handshake",
          desc: "Maximize your sales through efficient documentation - more time for customers, better decisions, more success",
          // url: "/sales",
        },
        {
          title: "Product Management",
          img: "gift",
          desc: "Optimize your product management through structured documentation - an important step towards success",
          // url: "/product",
        },
        {
          title: "Marketing",
          img: "lightbulb",
          desc: "Optimize your marketing with efficient documentation",
          // url: "",
        },
        {
          title: "Event Organization",
          img: "champagne-glasses",
          desc: "Efficient event planning through digitization",
          url: "/events",
        },
        {
          title: "Management",
          img: "compass",
          desc: "Support for management tasks completely rethought",
          url: "/ceo",
        },
        {
          title: "IT Department",
          img: "computer",
          desc: "Master the IT documentation easily with Aktenplatz",
          // url: "#",
        },
        {
          title: "Enterprise Wide",
          img: "building",
          desc: "Intelligent search and classification technologies ensure better accessibility and overview of knowledge in the company",
          // url: "#",
        },

        // {
        // title: "Product Roadmap",
        // img: "/img/app-ico7.svg",
        // desc: "Plan your product roadmap graphically and with ease that your customers will love!",
        // url: "",
        // },
      ],
    },
    branchen: {
      title: "A simple digitization for every industry",
      description:
        "Your industry has special requirements in the field of digitization? We have the perfect solution for you. Shall we bet?",
      heading: "The simple solution for every industry!",
      sub: "We support every industry with the different challenges of digitization.",
      bottomText:
        "Discover the advantages of aktenplatz in your industry - these industries are already working successfully with us. If your industry is not included, no problem. Contact us and let us find out together how aktenplatz can also be used in your industry. ",
      btnText: "Contact sales now",
      urlLang: "Learn more",
      boxes: [
        {
          title: "Construction & Architecture",
          img: "person-digging",
          desc: "Construction documents are well organized and easy to find, which facilitates overview and planning",
          url: "/bauen",
        },
        {
          title: "Craft",
          img: "screwdriver-wrench",
          desc: "Use of a digital file to manage projects, tasks and appointments",
          // url: "/craft",
        },
        {
          title: "Real Estate & Property Management",
          img: "house",
          desc: "Improved decision-making through quick access to documents in real estate and property management",
          // url: "",
        },
        {
          title: "Tax Advisor & Income Tax Assistance",
          img: "file-invoice-dollar",
          desc: "The paperless file has been thought through. Work as before with all the advantages",
          url: "/steuerkanzleien",
        },
        {
          title: "Finance & Credit",
          img: "piggy-bank",
          desc: "Improve your financial processes through efficient digital file management",
          // url: "",
        },
        {
          title: "Doctors",
          img: "user-doctor",
          desc: "Efficient file organization saves time when searching for and managing patient documents",
          url: "/Kieferorthopaedie",
        },
        {
          title: "Healthcare",
          img: "hospital",
          desc: "Digital document management improves patient care in healthcare",
          // url: "",
        },
        {
          title: "Administration and Public Service",
          img: "school-flag",
          desc: "Challenges from municipal and public service directly considered",
          url: "/odienst",
        },
        {
          title: "Trade",
          img: "store",
          desc: "Fast and efficient filing: A digital back office in retail simplifies the management of rental properties and invoices",
          // url: "",
        },
        {
          title: "Industry",
          img: "industry",
          desc: "Increasing transparency and traceability in production through digital documentation",
          // url: "",
        },
        {
          title: "Media",
          img: "photo-film",
          desc: "Digital file and project management ensures faster workflows in TV, news and print",
          // url: "#",
        },
        {
          title: "Tourism",
          img: "plane-departure",
          desc: "From the travel agency to the hotel: Digital file management is revolutionizing processes in tourism",
          // url: "",
        },
      ],
    },
    cases: {
      title: "Case studies",
      description:
        "Get inspired by our customers' onboarding stories and their results.",
      heading: "Case studies",
      sub: "Get inspired by our customers' onboarding stories and their results.",
      bottomText:
        "Entdecken Sie die Vorteile von aktenplatz in Ihrer Branche - diese Branchen arbeiten bereits erfolgreich mit uns. Sollte Ihre Branche nicht dabei sein, kein Problem. Kontaktieren Sie uns und lassen Sie uns gemeinsam herausfinden, wie aktenplatz auch in Ihrer Branche zum Einsatz kommen kann.",
      btnText: "Jetzt Vertrieb kontaktieren",
      urlLang: "Mehr erfahren",
      boxes: [
        {
          title: "ALH Lohnsteuerhilfe Ladewig",
          img: "piggy-bank",
          desc: "Use of a digital file to manage master data, tasks and appointments",
          // url: "/reference_alh",
        },
        {
          title: "Docubyte",
          img: "pen-ruler",
          desc: "Construction documents are well organized and easy to find, making overview and planning easier",
          // url: "/handwerk",
        },
      ],
    },
    webinars: {
      title: "Webinars",
      description:
        "Join our upcoming live or on-demand webinars to learn about the future of onboarding from world-class experts.",
      heading: "Webinars",
      sub: "Join our upcoming live or on-demand webinars to learn about the future of onboarding from world-class experts.",
      bottomText:
        "Discover the advantages of aktenplatz in your industry - these industries are already working successfully with us. If your industry isn't included, no problem. Contact us and let us find out together how file space can also be used in your industry.",
      btnText: "Contact sales now",
      urlLang: "Learn more",
      boxes: [
        {
          title: "The secret of automation",
          img: "",
          desc: "Construction documents are well organized and easy to find, making overview and planning easier",
          url: "",
          youtube:
            "https://www.youtube.com/embed/c1xjjnAgHME?si=sujpA2K2MX2Apg0e&amp;controls=1",
        },
        // {
        //   title: "Digitalisierung für Einsteiger",
        //   img: "",
        //   desc: "Nutzung einer digitalen Akte für die Verwaltung von Projekten, Aufgaben und Terminen",
        //   // url: "/handwerk",
        //   youtube: "https://www.youtube.com/embed/c1xjjnAgHME?si=sujpA2K2MX2Apg0e&amp;controls=1"
        // },
      ],
    },
    cheat: {
      title: "Start now with file space",
      description:
        "Not sure which elements to choose for your onboarding flow? Our cheat sheet will help you immediately.",
      heading: "Start now with file space",
      sub: "Not sure which elements to choose for your onboarding flow? Our cheat sheet will help you immediately.",
      bottomText:
        "Not sure which elements to choose for your onboarding flow? Our cheat sheet will help you immediately.",
      btnText: "Get your cheat sheet",
      urlLang: "Learn more",
      formTitle: "Where should we send your cheat sheet?",
      formButton: "Confirm",
    },
    contact: {
      title: "Quick and uncomplicated: contact us using the form",
      description:
        "Talk to our sales team. We are happy to help you to find the perfect support",
      desc: "Do you need more information or would you like a personalized offer? Send us a message and we will get back to you as soon as possible. We would also be happy to answer any product questions personally.",
      heading: "Talk to our sales team",
      note: "Fields marked with an asterisk (*) are required.",
      label1: "first name",
      label2: "last name",
      label3: "Company Email",
      label4: "phone number",
      label5: "country",
      label6: "Size",
      label7: "What would you like to discuss with us?",
      messagePlaceholder:
        "Tell us about your team and give us some details about a project or process you'd like to capture in Aktenplatz.",
      policies:
        "By checking the box and clicking submit, you agree to the Privacy Policy",
      btn: "Send",
      choose: "please choose...",
    },
    appointment: {
      title: "Arrange a free demo appointment now",
      description:
        "Our experts have an open ear for your individual requirements",
      headline: "Arrange a demo appointment now",
      headline_alt: "Individual offer for tailor-made digitization",
      desc1:
        "Do you have problems having all the right documents in front of you at all times? Does an employee's absence lead to a regular standstill? Have the desired advantages of digitization not yet materialized?",
      desc1_alt: "At aktenplatz.de we understand that every company has unique requirements when it comes to digitization. That's why we don't offer flat rates, but tailor-made solutions that are tailored exactly to your needs.",
      link1: "Let's talk about it.",
      form: {
        title: "Book your 1-on-1 demo, no strings attached",
        label1: "First name",
        label2: "Last name",
        label3: "Business email",
        label4: "Company Size",
        placeholder4: "Company Size",
        label5: "Country",
        placeholder5: "Country",
        btn: "Choose date and time",
      },
    },
    thankYou: {
      title: "Thank you!",
      description: "Our experts are ready to listen to your individual requirements",
      headline: "Thank you for your purchase - Book your setup now!",
      coFounder: "Co-founder",
      desc1: "To ensure a smooth start, we offer you a setup appointment with our expert team. This appointment is already included in the price. Book your appointment now and start using our software optimally.",
      link1: "Let's set up your aktenplatz now!",
    },
    header: {
      link1: "Functions",
      link2: "Prices",
      link3: "Solutions",
      link4: "Tax Consultant",
      link5: "Orthodontics",
      link6: "Audit-proof Archive",
      link7: "Demo Appointment",
      link8: "Contact sales team",
      link9: "Blog",
      link10: "Manuals",
      link11: "Reference",
      btnText: "Book a demo",
      mobLink1: "Advantages",
      mobLink2: "Trust",
      demo: "Schedule a demo",
      test: "Try for free",
      col1: {
        heading: "Use Cases",
        viewUrl: "/application",
        view: "Show all use cases",
        options: [
          {
            img: "box-archive",
            title: "Revision-proof archiving",
            desc: "Archiving - automatic, legally secure, GoBD-compliant",
            url: "/revisionssicher",
          },
          {
            img: "paper-plane",
            title: "Paperless Office",
            desc: "Paperless: The Future of Document Management",
            url: "/papierlos",
          },
          {
            img: "dolly",
            title: "Digital Delivery Notes",
            desc: "Missing delivery notes are a thing of the past",
            url: "/lieferschein",
          },
          {
            img: "envelopes-bulk",
            title: "Board Mail",
            desc: "Turn mail communication into an efficient Kanban board",
            url: "/mail",
          },
        ],
      },
      col2: {
        heading: "Teams",
        viewUrl: "/teams",
        view: "Show all teams",
        options: [
          // {
          // img: "bell concierge",
          // title: "Customer Care",
          // desc: "Manage your sales process from first lead to close",
          // url: "#",
          // },
          {
            img: "address-book",
            title: "Personnel Management",
            desc: "Revolutionize your personnel management with the digital personnel file",
            url: "/persmanagement",
          },
          {
            img: "compass",
            title: "Management",
            desc: "Support for management tasks completely rethought",
            url: "/ceo",
          },
          {
            img: "champagne-glasses",
            title: "Event Organization",
            desc: "Efficient event planning through digitization",
            url: "/events",
          },
        ],
      },
      col3: {
        heading: "Industries",
        view: "Show all branches",
        viewUrl: "/branchen",
        options: [
          {
            img: "person-digging",
            title: "Construction & Architecture",
            desc: "Construction documents are well organized and easy to find, which facilitates overview and planning",
            url: "/bauen",
          },
          {
            img: "user-doctor",
            title: "Doctors",
            desc: "Efficient file organization saves time when searching for and managing patient documents",
            url: "/Kieferorthopaedie",
          },
          {
            img: "file-invoice-dollar",
            title: "Tax Advisor & Income Tax Assistance",
            desc: "The paperless file has been thought through. Work as before with all the advantages",
            url: "/steuerkanzleien",
          },
          {
            img: "school-flag",
            title: "Administration & Public Service",
            desc: "Challenges from municipal and public service directly considered",
            url: "/odienst",
          },
        ],
      },
      col4Title: "Awards",
      col1Resources: {
        heading: "Wissenswertes",
        viewUrl: "/application",
        // view: "Alle Anwendungsfälle anzeigen",
        options: [
          {
            img: "medal",
            title: "Case studies",
            desc: "Get inspired by the successfull onboarding stories of our customers",
            url: "/cases",
          },
          {
            img: "podcast",
            title: "Webinars",
            desc: "Join our training webinars and see how to make users love our app",
            url: "/webinars",
          },
          {
            img: "blog",
            title: "Blog",
            desc: "Learn about best practices, rends & hacks",
            url: "https://blog.aktenplatz.de",
          },
        ],
      },
      col2Resources: {
        heading: "Dokumentation",
        viewUrl: "/teams",
        // view: "Alle Teams anzeigen",
        options: [
          {
            img: "book",
            title: "Documentation",
            desc: "Documentation of all functions in the file space",
            url: "https://help.aktenplatz.de/en",
          },
          {
            img: "clapperboard",
            title: "Video tutorials",
            desc: "Take inspiration from our tutorials",
            url: "https://www.youtube.com/playlist?list=PLUy_v4DyRSxPwNRDvtWjiski9_iMKxjUb",
          },
          {
            img: "clipboard-list",
            title: "Cheat sheet",
            desc: "Download a step-by-step guide to digitalize your processes",
            url: "/cheat",
          },
        ],
      },
    },
    home: {
      general: {
        title: "The software which organizes & structures the work digitally",
        titleTennis:
          "The software digitally organizes and structures tennis training",
        description:
          "No more constantly switching between folders, files, emails and other tools to keep your work on track. Track and manage everything from one central place - your file space",
        descriptionTennis:
          "Modern tools for tennis coaches to document, manage and achieve success!",
        knowMore: "more",
        secureHeadline: "Trustworthy and secure",
        secureHeadlineSub: "Our insurance companies and partners",
        startNow: "Start now",
        fileControlHeadline: "All files under control with file space",
        fileControlHeadlineTennis: "Coaching from planning to analysis",
        fileControlDesc: "Try Aktenplatz free for 14 days.",
        fileControlDescTennis:
          "Test mySport.tennis for 14 days free of charge.",
        fileControlDesc2: "No credit card required.",
        btnText: "Book a demo",
        persQuote:
          "My goal is to build software that supports people in their daily work and does not frustrate them.",
        coFounder: "Co-Founder",
        allFunctions: "All functions",
      },
      section1: {
        title: "File space organizes your files",
        titleTennis: "Organized & structured the coaching",
        desc: "For small and medium-sized offices we create a clear tracking for your files. Your established processes from the analogue world are transferred to the digital world!",
        descTennis:
          "Modern aids for tennis coaches for documentation, administration and achievement of success!",
        btnText: "Get started for free",
        subDesc: "Start immediately without a credit card.",
        review1:
          "“With Aktenplatz, my back office staff and I know which files (patients) we urgently need to take care of.“",
        review1Tennis:
          "“With digital task management, we can optimize tennis training by determining targeted exercises and goals.“",
        review2:
          "“With Aktenplatz we manage our construction projects and store documents and contacts centrally.“",
        review2Tennis:
          "“By providing training videos, we can offer our players visual instructions and feedback.“",
        review3:
          "“With Aktenplatz, we no longer miss important deadlines for our clients. We keep track of resubmissions and agreed appointments“",
        review3Tennis:
          "“Thanks to the progress documentation, we can follow the training course of our players closely to continuously increase their performance“",
      },
      section2: {
        partOneTitle: "Store processes digitally:",
        partOneTitleTennis: "Training planning and management",
        partOneTitleSub: "Save yourself time and nerves",
        partOneText:
          "Processes and filing are the basic building blocks for organizing work in the filing area. Each process can be provided with checklists, due dates, attachments and much more. From here you can assign tasks, set appointments and contacts, sign documents and much more. Each process is assigned to a repository. With trays, you can organize your work better - you can move tasks around the trays, track every movement and sort them by name, priority or progress.",
        partOneTextTennis:
          "With our software, tennis coaches and clubs can easily create and manage detailed training plans. The intuitive user interface enables the addition of exercises, play forms and focus for every training session. Training plans can be adapted for individual players or for the entire training group. The possibility of viewing and analyzing previous training plans supports the long -term development of the players and ensures a targeted increase in performance.",
        partTwoTitle: "Operations contain everything you need",
        partTwoTitleTennis: "Player progress and performance analysis",
        partTwoText:
          "Create a file location case with one click and find out what you can do with it. From digital files to complex project management. A task is the ideal division for dividing your work into smaller steps. Your virtual process is the central access to all your information. No matter whether links or files. Simply drag any piece of information onto your digital process and it's already saved. You'll never have to search through confusing inboxes, spreadsheets or paper files again.",
        partTwoTextTennis:
          "Due to the comprehensive logging of player performance and progress, MySport.Tennis offers valuable insights into the individual development of each player. The software pursues precisely statistical data, such as impact accuracy, frequency of play and physical fitness. In this way, trainers can identify and weaken the players and create based personalized training plans based on them. With a continuous performance analysis, players can be motivated and promoted in a targeted manner.",
        partThreeTitle: "Link what belongs together",
        partThreeTitleTennis: "Problem documentation and solution management",
        partThreeText:
          "A workplace is rarely purely digital. Whether you don't want to do without your paper files in the office or want to link a maintenance contract to a system in the trade. No problem! With one click you can create the Aktenplatz sticker in the form of a QR code. Now you can call up the digital process directly with your mobile phone camera.",
        partThreeTextTennis:
          "The area of problem documentation enables trainers and players to record challenges and difficulties during the training process. From injuries to tactical difficulties, all aspects can be recorded. The software offers a clear presentation of recognizing recurring problems and developing effective solutions. The systematic management of solutions leads to a continuous improvement of the game results.",
        partFourTitle: "Best to work together",
        partFourTitleTennis: "Team communication and gambling loyalty",
        partFourText:
          "Capture your team's work in one common place. Invite your colleagues to your projects, assign the digital processes to each other and store important information among each other directly in the digital processes. From anywhere and whenever you want, you can make important information and materials directly accessible to your colleagues.",
        partFourTextTennis:
          "Our software promotes efficient team communication and strengthens the bond between trainers, players and clubs. The platform enables the direct exchange of information, training plans and feedback. Players can share their success and progress and motivate each other. Parents or legal guardians can also be integrated into the communication process in order to gain a comprehensive insight into the training development of their children. This transparent and open communication contributes to the positive team dynamics and long -term binding of the players to the club.",
      },
      functions: {
        advantages: "Your advantages",
        headline: "Functional overview",
        privacy: "Privacy and Security",
        privacyDesc:
          "Enjoy full transparency and the certainty that you can always see where something is stored. If you wish, also on your local server.",
        vWork: "Virtual Desk",
        vWorkDesc:
          "Let your files continue to move from desk to desk. Only now do you know who has which file in the digital world.",
        vWorkDescTennis:
          "Always have your training files on hand. Whether on a training trip, on the tennis court or at home.",
        reminder: "Reminders",
        reminderDesc:
          "Our system informs you about files for resubmission. Don't miss any more deadlines and take the initiative back into your own hands.",
        reminderDescTennis:
          "Our system informs you about training data for the resubmission. Don't miss any more deadlines and take the initiative back in hand.",
        integration: "Integration",
        integrationDesc:
          "We integrate your existing digital system directly. It doesn't matter whether it's tax consulting software, filing systems or practice management systems.",
        integrationDescTennis:
          "We bind digital systems directly. No matter whether health data, EKG values or fitness bracelets.",
        team: "Teamwork",
        teamDesc:
          "Write comments, store documents centrally. So that you, your employees and your customers have all information about the file at a glance.",
        teamDescTennis:
          "Write comments, put documents centrally. So that you, coaches and athletes have all information about training at a glance.",
        smartphone: "Smartphone/tablet",
        smartphoneDesc:
          "So that nothing slips through your fingers, we also offer our file management on your mobile phone and tablet.",
        smartphoneDescTennis:
          "So that nothing really slips through your fingers, we also offer all training data on your mobile phone and tablet.",
      },
      section3: {
        modules: "Product Modules",
        headline: "One platform to coordinate all your work",
        desc: "Integrate the apps your team is already working with into Aktenplatz. With more than 50 integrations, you can connect everything your team needs for communication, collaboration and coordination in Aktenplatz.",
        descTennis:
          "Tie the apps with which your team is already working in mysport.tennis. With more than 50 integrations, you can link everything your team needs to communicate, collaboration and coordination in mysport.tennis.",
        mail: "Email Management",
        mailUrl: "/mail",
        mailp1:
          "Our innovative email function with integrated Kanban offers a new way of email management. Kanban is a visual method for organizing tasks that was originally developed in production control. In the email function you can split emails into different Arrange columns like 'to do', 'in progress' and 'done' to create a clear to-do list.",
        mailp2:
          "This visual organization helps you stay organized and manage your email tasks effectively. For example, you can move all emails that still need attention to the 'to do' column and then edit one by one while moving completed emails to the 'completed' column.",
        mailp3:
          "Now take advantage of our email function with integrated Kanban for optimized email organization.",
        archive: "Archiving",
        archiveUrl: "/revisionssicher",
        archivep1:
          "Our digital file archiving offers a secure and efficient solution for the management of your important business and document data, which corresponds to the GoBD (Principles for the proper management and storage of books, records and documents in electronic form as well as for data access) guidelines.",
        archivep2:
          "Anywhere, anytime access, cloud storage and protection against data loss are just a few of the benefits of digital file archiving. Document search and management becomes easier through the use of keywords and metadata. With a digital file archiving solution, you can access your documents from anywhere and access at any time and ensure that your important data is safe and accessible at all times.",
        archivep3:
          "Digital file archiving that complies with the GoBD guidelines also offers a high level of data security and guarantees the legal admissibility of storing your business and document data electronically. Now choose a digital file archiving solution to optimize and ensure your document management processes that you comply with the legal requirements.",
        team: "Teamwork",
        teamUrl: "/teams",
        teamp1:
          "Aktenplatz enables teams to work together more effectively and productively. Teamwork is essential in many areas of everyday work in order to successfully complete orders and achieve common goals.",
        teamp2:
          "Teamwork in modern software makes it possible to share information and data in real time, to collaborate and to optimize processes. Using our application, teams can communicate with each other in real time and coordinate their work.",
        teamp3:
          "Invest in modern software solutions that promote and improve your team's collaboration in order to complete work steps faster and more efficiently and to achieve common goals. Good teamwork is crucial for success in any company and can be improved through the use of modern software solutions become.",
        automation: "Automation",
        automationUrl: "/application",
        automationp1:
          "Our software can significantly automate and improve office work. With a variety of tools and functions, such as automated task lists, notifications and workflows, you can optimize your processes and save time and resources.",
        automationp2:
          "Automated office work allows you to use your time more effectively and focus on more important tasks. Aktenplatz also helps manage tasks and deadlines, resulting in better organization and greater efficiency.",
        automationp3:
          "Invest in file space to automate your office work and streamline your processes. Save time and resources and improve your organization and efficiency.",
        contacts: "Contacts",
        contactsUrl: "/application",
        contactsp1:
          "Modern contact management is characterized by an intuitive user interface, centralized access to all contacts and a wide range of functions, such as managing contact details, tracking communications and interactions, and the ability to organize contacts into custom groups and lists organize.",
        contactsp2:
          "Modern contact management solutions also offer the ability to import and sync contacts from different sources, such as email address books, social media accounts and CRM systems. This way you have all contacts in one central place and can access them anytime, anywhere.",
        contactsp3:
          "Invest in a modern contact management solution like ours to organize, manage and track your contacts. Improve your communication, lead more effective customer relationships and save time and resources.",
        folder: "File Storage",
        folderUrl: "/papierlos",
        folderp1:
          "A modern file storage system like Aktenplatz is an important part of any modern office environment. These solutions offer a simple and secure way to store, share and organize documents and data in the cloud.",
        folderp2:
          "You can organize your documents in user-defined folders and categories and have access to your files from anywhere at any time. In addition, you can easily manage access permissions for your files and involve your team members to work on documents together.",
        folderp3:
          "Invest in Aktenplatz's modern file storage to manage, organize and share your documents. Take advantage of a paperless environment and improve your efficiency, collaboration and data security.",
      },
    },
    footer: {
      contact: "Contact",
      datenschutz: "Privacy Policy",
      agb: "Terms and Conditions",
      impressum: "Imprint",
      status: "Status",
    },
    price: {
      title: "Software costs: comparison of prices and plans",
      description:
        "Here you will find Aktenplatz's current price list and the best plan for your needs",
      headline: "The best plan for your needs",
      pricing: "Pricing",
      desc: "Here you will find the current price list of Aktenplatz. All prices are plus 19% VAT.",
      monthly: "Monthly billing",
      anually: "Annual billing (save up to 33%)",
      package1Title: "Single Seat",
      package2Title: "Team",
      package3Title: "Professional",
      perMonth: "per month -",
      packageDescYear: "billed annually",
      packageDescMonth: "monthly billing",
      package1Desc:
        "Our entry-level package for all freelancers. Get all the functions you need to sort your files.",
      package2Desc:
        "Do you work in a team? This package offers you all the functions you need to keep an eye on your files and edit them with your colleagues.",
      package3Desc:
        "Do you need special interfaces to existing software? Then this package is just right for you.",
      btnTest: "Try for free",
      btnBuy: "Buy now",
      shortProfile: "Short Profile",
      description: "description",
      inclusiveStorage: "Storage included",
      moreSpace: "Each additional 100GB of storage",
      moreUser: "Each user",
      characteristics: "characteristics",
      issueView: "Filing cabinet view",
      calView: "Calendar View",
      userRoles: "User / Role Concept",
      customFields: "Custom Fields",
      externalAccess: "External Access",
      push: "Push notifications",
      issuePlace: "Document Storage",
      explorer: "Access via Explorer (similar to network drive)",
      reminder: "reminders",
      smartphone: "Smartphone and tablet interface",
      scan: "Document scan from app",
      tasks: "Task Management (TODO)",
      label: "Paper Files Label",
      qr: "QR Code Scanner",
      interfaces: "Interfaces",
      outlook: "Outlook Calendar and Contacts",
      datev: "Datev",
      lexware: "Lexware",
      praxident: "Praxident",
      steuersoft: "Steuersoft",
      revsafe: "Revision Safe Archive",
      installation: "installation",
      cloud: "Cloud",
      local: "Local",
      singleSign: "Single Sign On",
      templates: "Templetes",
      security: "security",
      dsgvo: "GDPR compliant",
      rz: "German data center",
    },
    steuerkanzleien: {
      title:
        "Digital tax office: Efficient tax advice through modern technology",
      description:
        "File management for tax offices and consultants rethought. Inexpensive and efficient, completely different from DATEV and yet compatible",
      headline: "Your path to a digital law firm",
      intro:
        "Do you have problems having all the right documents in front of you at all times? Does an employee's absence lead to a regular standstill? Have the desired advantages of digitization not yet materialized?",
      head1: "The problem of conventional file management",
      desc1:
        "Traditional management of tax files has always been a challenge. When you need something fast, important documents are missing from your office or filing system. Additionally, it can be difficult to find the most current version of files when they are over the course of their lifespan has been revised several times.",
      desc2:
        "It takes tedious work just so you have all the information at hand when you need it. This is both very time consuming and the paper form wastes an enormous amount of space.",
      desc3:
        "In theory, digitization is a remedy, but in practice it simply fails. You can't scan in old files and the software that's supposed to make your work easier just doesn't suit your working habits! It's cumbersome and rigid causing more chaos instead of assisting you as intended.",
      desc4:
        "We have got to work and are now proud to present our well thought-out document management system Aktenplatz.",
      head2: "Aktenplatz takes you to tax advice 4.0",
      desc5:
        "The term 'tax advice 4.0' is based on an initiative of the federal government 'Industry 4.0' and describes the comprehensive digitization of business processes through the progressive networking of all areas in production and services with modern information and communication technology",
      desc6:
        "What if there was a way to organize your files efficiently and neatly? A way that would let you find any document in seconds, regardless of who filed it or when it was last updated? Here's ours file management software Aktenplatz comes into play.",
      desc7:
        "We have developed a comprehensive solution especially for tax consultants. A solution that is individually tailored to your needs and adapted in such a way that it harmonises with your usual work steps. You avoid hours of manual setting of the program and can get started immediately!",
      desc8:
        "In contrast to other tools, we don't offer you any unnecessary sensory overload of functions, but exactly those that you need.",
      desc9:
        "To make this possible, we rely on an individual approach to records management. One of our experts will come to you and take a close look at the various workflows in your office. He will then ensure that everything is in order from start to finish is set up correctly.",
      desc10:
        "This way we can ensure that you receive the effective service you expect. Our goal is to make your life easier. It starts with our software being a perfect fit for your business.",
      point1: "Successfully reduce paper costs",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head3: "Your results from working with us",
      desc11:
        "Aktenplatz is not a revolutionary new program, but it is the first to combine all your important files, contacts, notes and appointments into a single workflow. No more fiddling with multiple programs or folders! With Aktenplatz you can quickly and easily find the one you need files and the respective status of these.",
      desc12:
        "You can also track who has access to the individual files and what changes have been made. Aktenplatz also makes it easier to exchange information with colleagues, as it combines internal communication regarding specific files in one program and thus significantly simplifies it.",
      desc13:
        "Even paper files that you only have to keep for the archive can be tracked down in your overview thanks to a special sticker and called up quickly when you have questions. Aktenplatz is the perfect solution for busy tax consultants who need to keep track of their work .",
      desc14:
        "Not only benefit from our software, but also from our excellent customer support. Whenever you have a question or need help, the Aktenplatz support team will come to you quickly and reliably and answer your request comprehensively. If you are not sure If Aktenplatz is right for you, give it a try. We are sure that you will be impressed by both the software and the service. For more information, please call us anytime!",
    },
    revSafe: {
      title: "Revision-proof archiving - automatic and legally compliant",
      description:
        "Backing up your business data on hard drives or simple cloud storage is not enough, we have the GoBD-compliant solution",
      headline: "Archiving - automatic and legally secure",
      desc1:
        "The legal situation for companies in Germany is clear: Backing up your business data on hard drives or simple cloud storage is not sufficient to meet the legal requirements. Data must be stored in an unchangeable memory.",
      head1: "Revision-proof and tamper-proof",
      desc2:
        "With the archiving of file space and the unchangeable storage of Microsoft Azure, you meet the legally binding criteria of revision security: All files are archived in their original state in a central location in German data centers and are unchangeable.",
      desc3:
        "Practical: If you have accidentally deleted files, they are still contained in the archive and can be restored at any time. Deleting archived documents within the archive is technically impossible. This means that viruses and external attackers cannot harm your data. Update Your documents are always stored in a new version every day. You can also access older versions at any time.",
      head2: "Legally secure and GoBD-compliant",
      desc4:
        "GoBD means principles for the proper management and storage of books, records and documents in electronic form. It is a BMF letter. The GoBD regulates which basic principles entrepreneurs must observe for their books and other records so that they can be used for tax purposes of proof are recognized by the tax authorities.",
      desc5:
        "According to the GoBD, the following requirements must be observed when keeping books in electronic or paper form: Principle of traceability and verifiability, principles of truth, clarity and continuous recording, completeness, individual recording obligation, correctness, timely bookings and records, order and immutability.",
      head3: "Cyber Attack Protection",
      desc6:
        "In the event of a cyber attack, every second counts and downtimes are not only painful, but above all expensive. Companies must therefore not lose a minute, but must ensure reliable data protection better today than tomorrow. With Aktenplatz we offer you exactly this protection, completely automatically without you having to take care of it.",
      desc7:
        "Let's play through a potential data loss scenario: There is a virus on your end device. It encrypts all your data. Not only locally but also on your cloud storage. You may not notice this encryption directly and finally you cannot recover your data. Archiving with Aktenplatz and Microsoft Azure will help you out now. All data in your archive is unchangeable and therefore cannot be encrypted or rendered unusable by viruses.",
      head4: "Why a Microsoft product?",
      desc8:
        "Impervious Storage for Azure Blobs is a Microsoft product to which we provide a specific interface in the file space. It is designed to be used by financial institutions and other industries to meet SEC 17a-4( f), CFTC 1.31©-(d), FINRA, etc. A technical white paper detailing how this feature helps with these regulatory requirements can be found at",
      desc9: "download. That",
      desc10:
        "contains detailed information about the compliance certifications.",
      desc11:
        "Users get maximum data protection by using immutable storage for the Azure Blob service to ensure that data cannot be modified or deleted by users, even if they have account management permissions.",
      desc12:
        "With immutable storage for Azure Storage Blobs, users can store sensitive information critical to evidence preservation, criminal investigations, etc. in a tamper-proof state for the desired length of time.",
      desc13:
        "Legal hold requires specific legal requirements which Microsoft fully meets with its product Azure Blobs. They provide a temporary immutability policy that can be applied for legal investigation purposes or general protection policies. A legal hold stores data in a WORM format (Write-Once, Read-Many) until explicitly deleted. When a legal hold period is set, files can be created and read, but not modified or deleted. Use a legal hold , when the length of time the data must be retained in a WORM state is unknown.",
      desc14:
        "By using the product developed and operated by Microsoft, we offer you long-term and secure storage of your archive data, which meets all the necessary certifications.",
      p1: "Secure Document Storage",
      p2: "Legal Compliance",
      p3: "Backup in German data centers",
      p4: "Privacy and security given",
      p5: "Automatic Archiving",
      p6: "GoBD compliant",
      head5: "Your results from working with us",
      desc15:
        "“Aktenplatz” is a software solution that combines all your files, notes and appointments into a structured workflow. With “Aktenplatz” you can quickly and easily find the required file and the respective status of it. Notes, pictures, emails and more Documents can be added to the file from any workstation. You can always see who edited which file and when. Yellow Post-It stickers are finally a thing of the past,",
      desc16:
        "Through our archive interface to Microsoft Azure, we offer you an audit-proof archive storage in which all your documents are stored completely, automatically and unchangeably.",
    },
    paperless: {
      title: "Paperless office - Efficient through modern file management",
      description:
        "A paperless office: increasing efficiency through modern document management",
      headline:
        "A paperless office: increasing efficiency through modern document management",
      intro:
        "Efficient document management is essential in every office. A modern and digital solution not only offers time savings, but also a better overview and accessibility of important documents. Aktenplatz is one such solution that supports a modern, paperless office and the management of Documents simplified. Read on to learn more about how aktenplatz can optimize your office.",
      head1: "Introduction to the paperless office",
      desc1:
        "A modern paperless office - a vision that for a long time could only be seen in science fiction films has become reality today. Digitization has ensured that companies and organizations can simplify and optimize their business processes. A modern office culture can only be achieved, however, if management uses the technical possibilities to eliminate paper management and manual processes. A modern paperless office is not only ecological and cost-effective, it also enables faster and easier communication and collaboration.",
      desc2:
        "An essential part of a modern paperless office is digital document management. Managing paper documents is time-consuming, confusing and prone to errors. Digital file management, on the other hand, offers a secure and centralized solution for managing all documents. Companies can use it to speed up their business processes and improve.",
      head2: "Advantages of digital document management",
      desc3:
        "The aktenplatz software is an excellent solution for digital document management. The user interface is easy to use and offers a clear structure for managing documents. The software supports the organization in managing their documents and enables them to be processed quickly and easily An intuitive search and filter functions make it much easier to find specific documents.",
      desc4:
        "Another advantage of aktenplatz is that it offers an integrated workflow solution. With this function, companies can automate and improve their business processes. An example of this is the approval process for documents. Instead of sending emails back and forth manually, an approval process can be started within the software and the responsible parties will receive a notification when it is time to approve the document.",
      desc5:
        "The modern paper-free office is able to automate and accelerate a wide range of processes. It allows for easy and quick filing and management of documents, resulting in better organization and efficiency. With a With digital document management such as aktenplatz, the modern office can securely retrieve its documents at any time and from anywhere.",
      desc6:
        "Searching for and finding documents is child's play with the aktenplatz software. The simple operation and the option of uploading documents using drag & drop simplify everyday work considerably. In addition, you can use aktenplatz to quickly find the desired documents using a full-text search and also edit them directly.",
      desc7:
        "Another advantage of aktenplatz is the possibility to edit documents with several users at the same time. This facilitates teamwork and leads to better communication. In addition, you can use the integrated approval process to track who made which changes to the documents at any time has.",
      point1: "Avoiding mountains of paper and saving space",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head3:
        "Secure data management: How we guarantee the protection of sensitive information in the paperless office",
      desc8:
        "The security of the data is also guaranteed at aktenplatz. The data is stored on highly secure servers in Germany and is protected by several layers of security. So you can be sure that the data is protected at all times and does not fall into the wrong hands.",
      head4: "Conclusion: Why switching to a paperless office is worthwhile",
      desc9:
        "In summary, aktenplatz is a valuable support for the modern paperless office. It simplifies document management, speeds up processes and protects data. If you want to run a modern and efficient office, you should definitely consider the implementation think about digital document management like aktenplatz.",
    },
    delivery: {
      title: "Software supports delivery note management",
      description:
        "Paperless efficiency: How software supports the management of delivery notes",
      headline:
        "Paperless efficiency: How software supports the management of delivery notes",
      intro:
        "Paperless management of delivery notes can bring many advantages for companies. Through the use of modern technologies such as file space, an efficient and faster process can be guaranteed. A paperless workflow not only saves time and money, but also leads to a greater overview and transparency in document management.",
      head1:
        "Optimization of the delivery note management by aktenplatz: increase in efficiency and process improvement",
      desc1:
        "A modern delivery process with paperless delivery notes is an example of the use of digital technologies in companies. It offers many advantages compared to conventional paper processes. This process can be made even more efficient by using document management software such as aktenplatz.",
      desc2:
        "A paperless delivery process begins with the creation of the delivery notes. These can be created directly in the software without the need for paper or pens. The information is transferred directly into digital form and is therefore immediately available.",
      desc3:
        "During the transport, the delivery notes can be viewed at any time. This means that there are no more delays or errors when checking paper documents. Instead, the data can be retrieved directly on a computer or a mobile device.",
      desc4:
        "Checking the delivery notes at the receiving point is also simplified. The information can be checked directly on the computer or mobile device without the need to scan or photograph paper documents.",
      desc5:
        "After verification, the delivery notes are automatically archived in the document management software. This means that they can be found quickly and easily at any time if they are needed again later.",
      head2:
        "Improved efficiency and organization: 6 advantages of aktenplatz when managing digital delivery notes",
      desc6:
        "Aktenplatz is modern software that supports document management in the paperless office. Here are 6 points that show the advantages of aktenplatz when managing delivery notes:",
      upoint1:
        "Time savings: Delivery notes can be recorded digitally with aktenplatz in a few seconds, which means time savings compared to the manual filing of paper documents.",
      upoint2:
        "Clear archiving: The delivery notes are archived in aktenplatz in a clear and easily accessible manner, which enables a quick search for the required documents.",
      upoint3:
        "Automated processing: aktenplatz supports the automated processing of delivery notes, which leads to faster and error-free processing.",
      upoint4:
        "Paper reduction: The digital processing of delivery notes can reduce the amount of paper in the office, which not only protects the environment, but also reduces the space requirement in the office.",
      upoint5:
        "Real-time monitoring: With aktenplatz, the processing of delivery notes can be monitored in real time, which enables a quick reaction to possible problems.",
      upoint6:
        "Cost Savings: Using aktenplatz to manage delivery notes can result in significant cost savings as paper, printers and filing shelves are no longer needed and office processes can be made more efficient.",
      point1: "Avoiding mountains of paper and saving space",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head3: "Your results from working with us",
      desc7:
        "In summary, it can be said that a paperless delivery process with the support of aktenplatz offers many advantages. It saves time and reduces errors when checking documents. In addition, it is easier to archive the delivery notes and find them again later. Companies that If you switch to a paperless delivery process, you will increase your efficiency and protect the environment at the same time.",
    },
    mail: {
      title: "Managing Emails Efficiently Using Kanban",
      description:
        "A simple and clear way to manage emails and streamline workflow",
      headline: "Bulletin Board Mail: Managing Emails Efficiently Using Kanban",
      intro:
        "With the advent of pinboard mail, the way you manage and organize email will be fundamentally changed. This new technology offers a simple and clear way to manage email and streamline the workflow. Read on to learn how Wall Mail can help you be more productive and efficient.",
      head1: "What is whiteboard mail?",
      desc1:
        "E-mail is an indispensable tool in modern business life, but managing e-mails can quickly become a challenge. Especially when it comes to keeping track of the processing status and priority of tasks, it can be difficult to create a develop a systematic approach. This is where whiteboard mail comes in.",
      desc2:
        "Bulletin Board Mail is a type of email management system based on the concept of Kanban. Kanban is a method for visualizing processes originally developed in the automotive industry. bulletin board mail uses this method, to manage emails in an organized manner. Instead of collecting emails in one inbox, they are grouped into different categories such as 'To Do', 'In Progress' and 'Done'. This allows the user to track the progress of each task and set priorities.",
      head2: "These benefits help increase productivity",
      desc3:
        "One of the most important benefits of whiteboard mail is the increase in productivity. Since all emails are visible and well-organized, it is easier to keep track and focus on the most important tasks. In addition, the time time spent searching and organizing emails can be minimized. Since each task is grouped into a specific category, the user can plan their work more efficiently and ensure no tasks are forgotten.",
      desc4:
        "Another benefit of whiteboard mail is the ability to easily collaborate with others. Since each task is grouped into a specific category, each employee can see which tasks are being worked on by others and where they can step in to help. This greatly improves team communication and collaboration, making it easier to monitor the progress of a task and ensure everyone is doing their part to ensure it is completed efficiently.",
      desc5:
        "Wall mail also stands for transparency in the work process. Since all tasks are visible and well organized, it is easy to keep track and clarify responsibilities. In addition, it can happen that tasks are passed back and forth between different departments . Wall mail ensures everyone involved knows where a task is and who is responsible for it.",
      desc6:
        "Not to forget the ease with which it can be adapted to the needs of a company. Being a flexible system, it can be easily adapted to the needs of any team. In addition, it can be integrated with other tools and Applications can be integrated to enable seamless collaboration.",
      point1: "Avoiding mountains of paper and saving space",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head3: "In summary: Improved collaboration and efficiency with Wall Mail",
      desc7:
        "In summary, whiteboard mail is an efficient solution for managing emails. By using Kanban, emails are well organized and easier to keep track of. In addition, it offers benefits such as increased productivity, Improved collaboration, transparency in the work process and easy customization. Businesses looking for an effective solution for managing their email should consider Wall Mail.",
    },
    persm: {
      title: "Simple Digital Personnel File: Efficient HR Management ",
      description:
        "Personnel administration made easy. The software for small and medium-sized companies. Simple, flexible and secure.",
      headline: "From the flood of paper to the digital personnel file",
      intro:
        "In modern personnel management, digitization is playing an increasingly important role. It is not only important to save documents in digital form, but also to automate and optimize processes. A document management system such as aktenplatz offers numerous advantages in order to To make the process more efficient and secure. In this article we take a closer look at the process of human resources management and show how aktenplatz can help.",
      head1: "Why digital personnel files make sense for companies",
      desc1:
        "The first step in the human resources management process is the hiring of new employees. With aktenplatz, human resources departments can electronically manage the documents related to the hiring of employees. From application documents to CVs and employment contracts, all relevant documents can be stored in a central digital database and easily searchable. This speeds up the hiring process and ensures important documents are not lost or accidentally deleted.",
      desc2:
        "Once an employee has been hired, training records and other documents need to be managed. This is where aktenplatz comes into play. Training records can be stored and organized digitally to ensure that each employee completes the necessary training and obtains the necessary certifications. In addition, can Vacation requests, timesheets and other HR-related documents are stored in the digital database to simplify and speed up access to information.",
      desc3:
        "Another advantage of aktenplatz im Personalmanagement is the possibility to keep sensitive documents safe. Confidential information such as social security numbers, bank details and performance reviews can be protected by secure passwords and access restrictions. This ensures compliance with data protection regulations and reduces the risk of data loss or - theft.",
      desc4:
        "In addition, aktenplatz can also help manage employee files and retain information after employees leave the company. aktenplatz allows companies to archive former employees' data to ensure it is easily accessible when needed. This is special important when it comes to employment law matters or when former employees are to be rehired.",
      head2:
        "Better collaboration through easy and quick access to information",
      desc5:
        "In addition to improving people management processes, using aktenplatz can also help increase the efficiency of the entire organization. By automating processes and reducing manual effort, employees can spend more time on important tasks and increase productivity increase overall. In addition, digital workflows can help minimize errors and delays, resulting in smoother operations overall.",
      desc6:
        "Aktenplatz also supports you in collaborating across the company. The system allows documents and files to be shared between different departments and employees. This facilitates collaboration when hiring new employees, managing vacation requests and other HR matters. Employees can access all the necessary documents and information to do their job effectively without resorting to manual processes.",
      point1: "Existing processes will be adopted",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head3: "In summary: Improved collaboration and efficiency with Wall Mail",
      desc7:
        "In summary, whiteboard mail is an efficient solution for managing emails. By using Kanban, emails are well organized and easier to keep track of. In addition, it offers benefits such as increased productivity, Improved collaboration, transparency in the work process and easy customization. Businesses looking for an effective solution for managing their email should consider Wall Mail.",
    },
    ceo: {
      title: "Cross-company DMS: Efficient collaboration",
      description:
        "A document management system offers management many advantages, especially in cross-company aspects such as cooperation with partners and customers",
      headline:
        "Efficient and secure: Cross-company document management with aktenplatz",
      intro:
        "In today's digital world, managing corporate documents is critical for senior management. A good document management system can help save time and money, increase productivity, and ensure important information is always available and protected. In In this article we will deal with the advantages of a document management system with file space for the management and in particular with cross-company aspects.",
      head1: "Cross-company collaboration",
      desc1:
        "One of the great strengths of aktenplatz is the cross-company cooperation. An effective exchange of documents and information is important for every company in order to design projects and business processes successfully. With aktenplatz, documents and information can be easily and securely shared with partners and suppliers . This enables optimal cooperation and coordination. For example, contracts with customers and suppliers can be digitized and stored securely so that all the necessary information is available at all times.",
      point1: "Avoiding mountains of paper and saving space",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head2: "Business Process Automation",
      desc2:
        "Another big advantage of aktenplatz is the possibility of automating business processes. Many business processes are time-consuming and require a lot of manual work, such as the processing of invoices. This process can be automated with aktenplatz. Invoices can be entered directly in the system and are automatically forwarded to the appropriate contact person. This not only saves time and resources, but also minimizes the risk of errors.",
      head3: "Easy integration of third-party systems",
      desc3:
        "Most companies already use a variety of different software systems to manage their business processes. One of the strengths of aktenplatz is the simple integration of third-party systems. This means that existing systems can be seamlessly integrated into the document management process. Documents and information can thus be directly imported and processed from other systems such as the ERP system.",
      head4: "Secure archiving of documents",
      desc4:
        "Another big advantage of aktenplatz is the secure archiving of documents. Every company has to archive a large number of documents, from contracts to invoices to personnel files. With aktenplatz, all documents can be stored securely and encrypted. The clear structuring of the Documents can be accessed at any time to all necessary information. The security of the data has the highest priority, so companies can be sure that their data is in good hands.",
      head5: "Efficient: Management benefits",
      desc5:
        "The introduction of a document management system with aktenplatz offers the management many advantages, especially in cross-company aspects such as cooperation with partners and customers. Processes can be accelerated and costs reduced through efficient administration and quick access to documents. The digital personnel file also makes things easier HR management and can help ensure that the company is perceived as modern and innovative.",
    },
    events: {
      title: "Plan & Organize Events Efficiently: Tips and Tricks",
      description:
        "With a document management system like aktenplatz, all relevant documents can be stored in one central location",
      headline:
        "Efficient event planning thanks to document management: How to optimize your processes",
      intro:
        "Organizing events can be a tedious and complex affair that requires a lot of time and resources. A document management system like Aktenplatz can help to simplify and streamline the entire process. This article explains the advantages of such a system Systems for the organization of events and activities considered in more detail.",
      head1: "Central storage of all documents",
      desc1:
        "With a document management system like aktenplatz, all relevant documents can be stored in a central location. This makes it possible to access and edit all important documents quickly and easily. This is particularly important if several people are involved in the organization of the event and everyone must have access to the same documents.",
      desc1_1:
        "Improving teamwork is a key goal when using Aktenplatz. All team members and, if desired, external partners have access to the same information and can collaborate in real time. This facilitates collaboration by letting everyone know the current status of the project and keeps track of everyone's progress. Also, the system allows for assignment of tasks and tracking of progress, which helps keep everyone on the team accountable and tasks can be completed more efficiently, which can help the event run more smoothly and possible errors or delays are avoided.",
      point1: "Avoiding mountains of paper and saving space",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head2: "Automation of Tasks",
      desc2:
        "Another advantage of aktenplatz is the ability to automate routine tasks. For example, reminders for important dates such as registration deadlines or delivery dates can be sent automatically. This reduces the effort involved in manually managing appointments and ensures that important deadlines are not missed become.",
      head3: "Increase data security",
      desc3:
        "A document management system like aktenplatz makes it possible to securely store sensitive data such as personal information and financial data. The use of encrypted connections and the storage of data in highly secure data centers ensure a high level of data security. This is particularly important since many events contain personal data.",
      head4: "Why a document management system is a must for successful events",
      desc4:
        "All in all, a document management system like aktenplatz offers an efficient and structured way of simplifying the planning and organization of events. The central management and easy access to all relevant information and documents means that misunderstandings and errors can be avoided, which leads to a smooth and successful event. Such a system is therefore a must for every event that is to be carried out professionally and successfully.",
    },
    build: {
      title:
        "Efficient Construction Planning & Architecture: Optimized Work Processes",
      description:
        "Careful documentation and organization are essential in construction planning and architecture, we have a suggestion for you",
      headline:
        "How a document management system optimizes construction planning and architecture",
      intro:
        "In construction planning and architecture, careful documentation and organization are essential to successfully implement projects. Document management systems can play an important role here, as they enable efficient management of information and seamless collaboration between project participants. In particular, a document management system with file space offers numerous advantages that can help architects, engineers and construction companies to optimize their work processes and save time and resources.",
      head1:
        "Advantages of digital documentation in construction planning and architecture",
      desc1:
        "One of the greatest advantages of a document management system with file space in construction planning is the ability to store and manage documents and plans centrally. Central storage means that project participants can access all relevant information, regardless of their location or their function in the project team. This saves time and prevents important information from being lost or having to be created several times. Changes and updates can also be viewed quickly and easily by everyone involved in the project, without the need for long email chains or face-to-face meetings.",
      desc1_1:
        "Another advantage of document management systems with filing space in construction planning is the possibility of facilitating teamwork. The central storage and management of documents and plans means that everyone involved in the project has access to the same information. This ensures that everyone involved in are on the same page and can work together on a project. The document management system can also make it easier to coordinate appointments and tasks. Tasks can be assigned and monitored to ensure that everyone involved in the project fulfills their responsibilities.",
      point1: "Avoiding mountains of paper and saving space",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head2: "Automation of Tasks",
      desc2:
        "Another advantage of aktenplatz is the ability to automate routine tasks. For example, reminders for important dates such as registration deadlines or delivery dates can be sent automatically. This reduces the effort involved in manually managing appointments and ensures that important deadlines are not missed become.",
      head3: "Increase data security",
      desc3:
        "A document management system like aktenplatz makes it possible to securely store sensitive data such as personal information and financial data. The use of encrypted connections and the storage of data in highly secure data centers ensure a high level of data security. This is particularly important since many events contain personal data.",
      head4: "Why a document management system is a must for successful events",
      desc4:
        "All in all, a document management system like aktenplatz offers an efficient and structured way of simplifying the planning and organization of events. The central management and easy access to all relevant information and documents means that misunderstandings and errors can be avoided, which leads to a smooth and successful event. Such a system is therefore a must for every event that is to be carried out professionally and successfully.",
    },
    odienst: {
      title: "Digital public service: Paperless for more efficient processes",
      description:
        "Faster, safer and more effective: why authorities should rely on aktenplatz",
      headline: "How aktenplatz can support authorities and the public service",
      intro:
        "In public administration, large quantities of documents have to be processed, archived and managed every day. This can be an immense challenge that takes up time and resources. But a document management system (DMS) with file space can help authorities and public services help to overcome these challenges. In this article we will look at the benefits of a DMS for the public sector.",
      head1: "Increased efficiency through central data storage",
      desc1:
        "Authorities and the public service manage a large number of documents and information. A document management system with file space makes it possible to store these documents centrally and clearly. The documents can therefore be found quickly and are available at any time. The manual search for documents and information is no longer necessary. This saves time and increases efficiency at work.",
      desc1_1:
        "By using a DMS with file space, the effort for printing and archiving documents can be significantly reduced. A paperless office not only saves space, but also costs. By reducing paper consumption, authorities and public services can improve their environmental balance .",
      point1: "Avoiding mountains of paper and saving space",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head2: "Security and privacy",
      desc2:
        "Confidential information and personal data are often processed in public authorities and public services. A DMS with file space offers secure and encrypted storage of this data. Only authorized persons have access to the documents and information. A DMS thus contributes to compliance with data protection regulations at.",
      head3: "Transparent processes and fast processing times",
      desc3:
        "A DMS with file space offers authorities and the public sector the opportunity to manage documents and information transparently. Every step within a process is documented in a comprehensible manner. This shortens processing times and enables inquiries and tasks to be completed more quickly.",
      head4: "Easy teamwork",
      desc4:
        "In authorities and the public service, many people often work on a project or a task. A DMS with file space enables easy teamwork. Documents can be edited at the same time and versioning is carried out automatically. Everyone involved always has access to the latest version. This saves time and prevents errors.",
      head5:
        "Faster, safer and more effective: Why authorities should rely on aktenplatz",
      desc5:
        "A document management system with file space is an indispensable tool for authorities and the public service to work more efficiently. The central data storage, the high level of security and data protection, transparent processes and fast processing times as well as easy teamwork lead to a more efficient way of working. By reducing paper consumption, cost savings can also be achieved and the environmental footprint improved.",
    },
    doctor: {
      title: "Your way to a digital patient file",
      description:
        "Aktenplatz is the best software solution that combines all your patient files, notes and appointments into a structured workflow",
      headline: "Your way to the digital patient file",
      intro:
        "Do you have problems having all the right documents in front of you at all times? Does an employee's absence lead to a regular standstill? Have the desired advantages of digitization not yet materialized?",
      head1:
        "The software solution that allows you to keep track of your patient files",
      desc1:
        "You have a medical practice and would like to manage your analogue and digital patient files clearly with a single piece of software? With Aktenplatz you have the option of running consistent file management in parallel on all computers in your practice. You can manage your files virtually and access them whenever you want. You keep track of which file is being processed by which employee, information is no longer lost and additional files and links can be added. In addition, you can quickly and easily forward tasks to the back office while you are being treated.",
      head2: "With Aktenplatz you keep track of all activities",
      desc2:
        "With Aktenplatz as a software solution for your files, you have the opportunity to manage your digital and analog files efficiently and clearly. With our software, you can organize them similar to the filing system of your desk and find files in seconds. Here is a small example, how you can integrate Aktenplatz directly into the workplace:",
      upoint1:
        "During the treatment, a task for the back office arises (e.g. requesting a plan extension)",
      upoint2:
        "You assign the abbreviation PL directly in your practice management software.",
      upoint3:
        "The back office issues a note to the doctor to write a plan extension for this patient.",
      upoint4:
        "The file then ends up directly in the doctor's digital filing system.",
      upoint5:
        "The doctor now writes the extension and forwards the file digitally to the back office to be sent to the health insurance company.",
      upoint6:
        "The back office in turn deletes the abbreviation after the task has been completed and the file disappears from the back office stack.",
      desc3:
        "This usual process from everyday practice can of course be transferred to other routines in your practice. A few more examples: In the case of open patient invoices, every practice employee can track who has already made a phone call and when the next call is due. You can control the process of writing a doctor's letter or be reminded of new appointments in the event of missed appointments.",
      desc4:
        "In contrast to other tools, we offer a tidy software that is intuitive to use. Aktenplatz will be fully integrated into your practice management system. Our support team will help you set up the integration.",
      point1: "Integration into your practice",
      point2: "Networking teamwork quickly and reliably",
      point3: "Tracking communication easily",
      point4: "data protection and security given",
      point5: "Automate processes and grow",
      point6: "Mobile apps and integrations",
      head3: "Your results from working with us",
      desc5:
        "Aktenplatz is the first software solution that combines all your patient files, notes and appointments into a structured workflow.With Aktenplatz you can quickly and easily find the required file and the respective status of it.Notes, pictures, E- mails and other documents can be added to the file from any workstation.You can always see who edited which file and when.Yellow Post- It stickers are finally a thing of the past",
      desc6:
        "Even paper files that you only have to keep for the archive can be tracked down in your database thanks to an attached QR code and can be called up quickly if you have any questions. Aktenplatz is the perfect solution for busy doctors who need an overview of have to keep their job.",
    },
  },
};
