import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation } from "react-router";

const Kieferorthopaedie = ({ content, footer }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
      </Helmet>

      <div className="h-screen flex flex-col body">
        <section className="header-container">
          <div className="banner-section-tax flex flex-col space-y-5 lg:flex-row container mx-auto mt-10 mb-32">
            <div className="banner-col-tax  flex flex-1 flex-col items-center text-center pt-10 lg:items-start lg:pt-40 lg:text-left col-1 xl:ml-[60px] 2xl:ml-[190px]">
              <h1 className="banner-title-tax pt-2 pb-3 text-4xl font-bold max-w-xs mx-auto lg:text-5xl lg:max-w-none lg:pb-6 lg:leading-tight">
                {content.headline}
              </h1>
              <div className="banner-col-1-content-tax px-4 lg:max-w-lg">
                <p className="banner-description-tax  text-[17px] opacity-80  leading-8">
                  {content.intro}
                </p>
              </div>
            </div>
            <div className="banner-col col-2-tax flex flex-row flex-1 justify-center pt-10 lg:pt-0  xl:mr-[60px] 2xl:mr-[190px] self-end w-full h-[300px]">
              <img src="/img/kanbanBoard_small.jpg" alt="kanban board small" className="tax-image max-w-xs lg:max-w-[500px]" />
              {/* <!-- <embed src="https://www.youtube.com/embed/Y3Wv2AoGiv0" wmode="transparent" type="video/mp4" width="100%"
            height="300px" allow="autoplay; encrypted-media; picture-in-picture" allowfullscreen
            title="Keyboard Cat"> --> */}
              {/* <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/JSbejcfKgBU?controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe> */}
            </div>
          </div>

          <div className="mx-auto sm:container  lg:max-w-[880px] px-[20px] mb-20">
            <div className="text-3xl font-bold mb-4">
              {content.head1}
            </div>
            <div className="opacity-80 leading-relaxed mb-14">
              {content.desc1}
              <br />
              {content.desc2}
            </div>
            <br />
            <div className="text-3xl font-bold mb-4">
              {content.head2}
            </div>
            <p className="opacity-80 leading-relaxed mb-14">
              {content.desc3}
              <br />
              <br />
              {content.desc4}
              <br />
              <br />
              {content.desc5}
              <br />
              <br />
              {content.desc6}
            </p>
            <br />
            <br />
            <div className="grid grid-col-1 sm:grid-cols-2 gap-y-10 mb-20 gap-x-5">
              <div className="flex items-center gap-5">
                <div className="">
                  <img src="/img/WritingDesk.png" className="w-10" alt="icon" />
                </div>
                <div className="font-bold">
                  {content.point1}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div className="">
                  <img src="/img/icon2.svg" className="w-10" alt="icon" />
                </div>
                <div className="font-bold">
                  {content.point2}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div className="">
                  <img src="/img/icon3.svg" className="w-10" alt="icon" />
                </div>
                <div className="font-bold">
                  {content.point3}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div className="">
                  <img src="/img/icon4.svg" className="w-10" alt="icon" />
                </div>
                <div className="font-bold">
                  {content.point4}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div className="">
                  <img src="/img/icon5.svg" className="w-10" alt="icon" />
                </div>
                <div className="font-bold">
                  {content.point5}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div className="">
                  <img src="/img/icon6.svg" className="w-10" alt="icon" />
                </div>
                <div className="font-bold">
                  {content.point6}
                </div>
              </div>
            </div>
            <br />
            <br />

            <div className="text-3xl font-bold mb-4">
              {content.head3}
            </div>

            <p className="opacity-80 leading-relaxed mb-14">
              {content.desc7}
            </p>

            {/* <!-- <div className="text-3xl font-bold mb-4">Benefit</div>

        <p className="opacity-80 leading-relaxed mb-14">

            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            et
            dolore magna aliqua. Malesuada pellentesque elit eget gravida cum sociis natoque. Tellus mauris a
            diam
            maecenas sed. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris ultrices. Amet
            dictum
            sit amet justo. Felis bibendum ut tristique et egestas quis.
            <br/><br/>
            Et pharetra pharetra massa massa ultricies. Odio ut enim blandit volutpat maecenas.

        </p>





        <div className="text-3xl font-bold mb-4">Results</div>

        <p className="opacity-80 leading-relaxed mb-8">
            Eget mi proin sed libero. Habitasse platea dictumst vestibulum rhoncus est pellentesque. At varius
            vel
            pharetra vel turpis nunc. Vitae semper quis lectus nulla at volutpat diam. Tortor dignissim
            convallis
            aenean et. Varius vel pharetra vel turpis nunc.
        </p>

        <div className="flex flex-col gap-2 pl-4">
            <div className="flex gap-3">
                <div className="min-w-[5px] min-h-[5px] max-w-[5px] max-h-[5px] w-full transform translate-y-2 rounded-full"
                    style="background: rgb(194, 194, 194);"></div>
                <div className="opacity-80 leading-relaxed">Porttitor eget dolor morbi non arcu. Iaculis nunc sed
                    augue
                    lacus viverra vitae congue eu.</div>
            </div>


            <div className="flex gap-3">
                <div className="min-w-[5px] min-h-[5px] max-w-[5px] max-h-[5px] w-full transform translate-y-2 rounded-full"
                    style="background: rgb(194, 194, 194);"></div>
                <div className="opacity-80 leading-relaxed">A lacus vestibulum sed arcu non odio.</div>
            </div>




            <div className="flex gap-3">
                <div className="min-w-[5px] min-h-[5px] max-w-[5px] max-h-[5px] w-full transform translate-y-2 rounded-full"
                    style="background: rgb(194, 194, 194);"></div>
                <div className="opacity-80 leading-relaxed">Velit aliquet sagittis id consectetur purus. Nisl purus
                    in
                    mollis nunc.</div>
            </div>



            <div className="flex gap-3">
                <div className="min-w-[5px] min-h-[5px] max-w-[5px] max-h-[5px] w-full transform translate-y-2 rounded-full"
                    style="background: rgb(194, 194, 194);"></div>
                <div className="opacity-80 leading-relaxed">Blandit massa enim nec dui nunc mattis enim.</div>
            </div>





            <div className="flex gap-3">
                <div className="min-w-[5px] min-h-[5px] max-w-[5px] max-h-[5px] w-full transform translate-y-2 rounded-full"
                    style="background: rgb(194, 194, 194);"></div>
                <div className="opacity-80 leading-relaxed">Erat pellentesque adipiscing commodo elit at imperdiet
                    dui
                    accumsan.</div>
            </div>




            <div className="flex gap-3">
                <div className="min-w-[5px] min-h-[5px] max-w-[5px] max-h-[5px] w-full transform translate-y-2 rounded-full"
                    style="background: rgb(194, 194, 194);"></div>
                <div className="opacity-80 leading-relaxed">Tortor vitae purus faucibus ornare.</div>
            </div>




            <div className="flex gap-3">
                <div className="min-w-[5px] min-h-[5px] max-w-[5px] max-h-[5px] w-full transform translate-y-2 rounded-full"
                    style="background: rgb(194, 194, 194);"></div>
                <div className="opacity-80 leading-relaxed">Id donec ultrices tincidunt arcu non sodales neque.
                </div>
            </div> --> */}
          </div>

          <Footer content={footer} />
        </section>
      </div>
    </>
  );
};

export default Kieferorthopaedie;
