import Footer from "../components/Footer";
import Slider1 from "../components/Slider1";
import Slider2 from "../components/Slider2";
import useOutsideClick from "../hooks/useOutsideClick";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Reviews from "../components/Reviews";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

//the details component that opens when you click the icons in second section
const FolderDetails = ({
  img,
  desc1,
  desc2,
  desc3,
  name,
  setOpenDetails,
  buttonText,
  url,
}) => {
  const handleClickOutside = () => {
    setOpenDetails(Array(6).fill(false));
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <div
      ref={ref}
      className="details-div  cursor-auto xl:top-0 z-40 flex justify-center w-[95%] sm:w-full min-w-[350px] items-start flex-col rounded p-5 absolute right-1/2 sm:translate-x-0 translate-x-1/2 xl:left-[100%] top-[102%] bg-white shadow-lg"
    >
      <img
        src="/img/close.svg"
        alt=""
        onClick={() => {
          setOpenDetails(Array(6).fill(false));
        }}
        className="absolute cursor-pointer top-[6px] right-[6px]"
      />
      <img src={img} alt="close icon" />
      <h2 className="text-orange text-[30px] font-bold capitalize">{name}</h2>
      <p className="text-[#696969] text-sm font-normal mt-3">{desc1}</p>
      <p className="text-[#696969] text-sm font-normal mt-3">{desc2}</p>
      <p className="text-[#696969] text-sm font-normal mt-3 mb-5">{desc3}</p>
      <Link to={url} className="btn-primary">
        {buttonText}
      </Link>
    </div>
  );
};

function Home({
  content: { general, section1, section2, functions, section3 },
  footer,
}) {
  // const handleClick = () => {
  //   ref.current?.scrollIntoView({ behavior: "smooth" });
  // };
  const [openDetails, setOpenDetails] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  // const location = useLocation();
  // useEffect(() => {
  //   if (window.location.replace("http://localhost:3000/#funktionen")) {
  //     handleClick();
  //   } else if (window.location.replace("https://aktenplatz.de/#funktionen")) {
  //     handleClick();
  //   } else {
  //     window.scrollTo(0, 0);
  //   }
  // }, [location]);

  const ref = useRef(null);
  const openDetailsFunc = (index) => {
    setOpenDetails((prev) => {
      let copy = Array(6).fill(false);
      copy[index] = prev[index];
      if (!prev[index]) {
        copy[index] = true;
        return copy;
      } else {
        return Array(6).fill(false);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>{general.title}</title>
        <meta name="description" content={general.description} />
      </Helmet>

      <div className="lg:overflow-x-visible overflow-x-hidden  h-screen flex flex-col body">
        <section className="header-container ">
          <div className="banner-section container mx-auto mt-0">
            <div className="banner-col col-1 transform lg:-translate-y-[80px]">
              <div className="banner-col-1-content">
                <h1 className="banner-title">{section1.title}</h1>
                <p className="banner-description">{section1.desc}</p>
                <Link to="/termin" rel="noreferrer" className="btn-primary">
                  {section1.btnText}
                </Link>
                <p></p>
                <br />
                <p className="banner-description">{section1.subDesc}</p>
                <div className="app-logos">
                  <a
                    href="https://apps.apple.com/us/app/aktenplatz/id1620033655?itsct=apps_box_badge&amp;itscg=30200"
                    target=" _blank"
                  >
                    <img
                      alt="Download on the App Store"
                      src="/img/apple_appstore.png"
                      style={{ maxHeight: "50px", marginTop: "0px" }}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.aktenplatz.aktenplatz&gl=DE&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Jetzt bei Google Play"
                      src="/img/google-play-badge.png"
                      style={{ maxHeight: "50px", marginTop: "0px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="banner-col col-2 relative max-h-[500px]">
              <a
                href="https://www.youtube.com/watch?v=ce5Fe6UtQl0"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/img/home.svg"
                  alt="banner-logo"
                  className="banner-logo max-h-[512px] transform lg:translate-y-[0px]"
                />
              </a>

              <Reviews
                content={[section1.review1, section1.review2, section1.review3]}
              />
            </div>
          </div>
        </section>
        {/* hexagon section ------------------ */}

        <main className="main container mx-auto pt-10 flex-1">
          <section className="sections section-logos">
            <Slider1 />
          </section>

          <section className="sections section-features">
            <div className="heading-container">
              <h4 className="heading-title" ref={ref} id="funktionen"></h4>
              {/* <!-- <h3 className="heading-section-title">Aktenplatz in vier Schritten</h3> --> */}
            </div>
            <div className="features">
              <div className="feature">
                <div className="feature-content">
                  <h4 className="feature-title">
                    {section2.partOneTitle}
                    <br /> {section2.partOneTitleSub}
                  </h4>
                  <div className="feature-image sm:flex md:flex lg:flex xl:hidden 2xl:hidden">
                    <img
                      src="/img/animations/akt-gif1.gif"
                      style={{
                        width: "450px",
                        maxHeight: "400px",
                        borderRadius: "0px",
                      }}
                      alt="gif"
                    />
                  </div>
                  <p className="feature-description">{section2.partOneText}</p>
                  {/* <!-- <!-- <a href="#" className="btn-primary">Lern mehr</a> --> */}
                </div>
                <div className="feature-image hidden xl:flex 2xl:flex">
                  <img
                    src=" /img/animations/akt-gif1.gif"
                    style={{
                      width: "450px",
                      maxHeight: "400px",
                      borderRadius: "0px",
                    }}
                    alt="gif"
                  />
                </div>
              </div>
              <div className="feature">
                <div className="feature-image hidden xl:flex 2xl:flex">
                  <img
                    src="/img/animations/akt-gif2.gif"
                    style={{
                      width: "640px",
                      maxHeight: "400px",
                      borderRadius: "30px",
                    }}
                    alt="gif"
                  />
                </div>
                <div className="feature-content">
                  <h4 className="feature-title">{section2.partTwoTitle}</h4>
                  <div className="feature-image sm:flex md:flex lg:flex xl:hidden 2xl:hidden">
                    <img
                      src="/img/animations/akt-gif2.gif"
                      style={{
                        width: "450px",
                        maxHeight: "400px",
                        borderRadius: "30px",
                      }}
                      alt="gif"
                    />
                  </div>
                  <p className="feature-description">{section2.partTwoText}</p>
                  {/* <!-- <a href="#" className="btn-primary">Lern mehr</a> --> */}
                </div>
              </div>
              <div className="feature">
                <div className="feature-content">
                  <h4 className="feature-title">{section2.partThreeTitle}</h4>
                  <div className="feature-image sm:flex md:flex lg:flex xl:hidden 2xl:hidden">
                    <img
                      src="/img/animations/akt-gif3.gif"
                      style={{ maxHeight: "400px", borderRadius: "30px" }}
                      alt="gif"
                    />
                  </div>
                  <p className="feature-description">
                    {section2.partThreeText}
                  </p>
                  {/* <!-- <a href="#" className="btn-primary">Lern mehr</a> --> */}
                </div>
                <div className="feature-image hidden xl:flex 2xl:flex">
                  <img
                    src="/img/animations/akt-gif3.gif"
                    style={{
                      width: "640px",
                      MaxHeight: "384px",
                      borderRadius: "30px",
                    }}
                    alt="gif"
                  />
                </div>
              </div>
              <div className="feature">
                <div className="feature-image hidden xl:flex 2xl:flex">
                  <img
                    src="/img/animations/akt-gif4.gif"
                    style={{
                      width: "640px",
                      MaxHeight: "384px",
                      borderRadius: "30px",
                    }}
                    alt="gif"
                  />
                </div>
                <div className="feature-content">
                  <h4 className="feature-title">{section2.partFourTitle}</h4>
                  <div className="feature-image sm:flex md:flex lg:flex xl:hidden 2xl:hidden">
                    <img
                      src="/img/animations/akt-gif4.gif"
                      style={{
                        width: "450px",
                        maxHeight: "400px",
                        borderRadius: "30px",
                      }}
                      alt="gif"
                    />
                  </div>
                  <p className="feature-description">{section2.partFourText}</p>
                  {/* <!-- <a href="#" className="btn-primary">Lern mehr</a> --> */}
                </div>
              </div>
            </div>
          </section>

          <section className="sections section-focus">
            <div className="heading-container">
              <h4 className="heading-title" id="Vorteile">
                {functions.advantages}
              </h4>
              <h3 className="heading-section-title">{functions.headline}</h3>
            </div>
            <div className="focuses lg:border-b border-gray">
              <div className="focus">
                <div className="focus-image">
                  <img
                    src="/img/iconfokus-5.png"
                    width="72px"
                    alt="icon focus"
                  />
                </div>
                <h4 className="focus-title">{functions.privacy}</h4>
                <p className="focus-description">{functions.privacyDesc}</p>
              </div>
              <div className="focus">
                <div className="focus-image">
                  <img
                    src="/img/WritingDesk.png"
                    width="72px"
                    alt="Writing desk icon"
                  />
                </div>
                <h4 className="focus-title">{functions.vWork}</h4>
                <p className="focus-description">{functions.vWorkDesc}</p>
              </div>
              <div className="focus">
                <div className="focus-image">
                  <img
                    src="/img/Notification.png"
                    width="72px"
                    alt="notification icon"
                  />
                </div>
                <h4 className="focus-title">{functions.reminder}</h4>
                <p className="focus-description">{functions.reminderDesc}</p>
              </div>
            </div>
            <div className="focuses">
              <div className="focus">
                <div className="focus-image">
                  <img
                    src="/img/iconfokus-6.png"
                    width="72px"
                    alt="fokus icon"
                  />
                </div>
                <h4 className="focus-title">{functions.integration}</h4>
                <p className="focus-description">{functions.integrationDesc}</p>
              </div>
              <div className="focus">
                <div className="focus-image">
                  <img src="/img/Team.png" width="72px" alt="team icon" />
                </div>
                <h4 className="focus-title">{functions.team}</h4>
                <p className="focus-description">{functions.teamDesc}</p>
              </div>
              <div className="focus">
                <div className="focus-image">
                  <img
                    src="/img/iconfokus-2.png"
                    width="72px"
                    alt="fokus icon"
                  />
                </div>
                <h4 className="focus-title">{functions.smartphone}</h4>
                <p className="focus-description">{functions.smartphoneDesc}</p>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Link to="/preise" className="btn-primary">
                {general.allFunctions}
              </Link>
            </div>
          </section>

          <section className="sections section-focus">
            <div className="container relative lg:mt-0 mt-[40px] mb-0 sm:mb-[40px] mx-auto  px-5 product-module w-full flex justify-center items-center flex-col">
              <div className="heading-container">
                <h4 className="heading-title" id="Produktmodule">
                  {section3.modules}
                </h4>
                <h3 className="heading-section-title">{section3.headline}</h3>
              </div>
              <div className="focus" style={{ maxWidth: "1000px" }}>
                <p className="focus-description">{section3.desc}</p>
              </div>
              <div className="product-grid w-full gap-x-[10px] lg:gap-x-[20px] xl:min-w-0 min-w-[840px]  grid max-w-[1400px] grid-cols-14 grid-rows-[115px_115px_115px] xl:grid-rows-[200px_200px_200px]">
                <div className="hex-border-no-hover translate-y-5 xl:translate-y-[40px] col-start-2 col-end-4">
                  <div className="bg-orange opacity-[0.18] hexagon "></div>
                </div>
                <div className="hex-border-no-hover translate-y-5 xl:translate-y-[40px]">
                  <div className="hexagon bg-black "></div>
                </div>
                <div className="info-wrapper">
                  {openDetails[0] && (
                    <FolderDetails
                      img={"/img/mail.svg"}
                      name={section3.mail}
                      desc1={section3.mailp1}
                      desc2={section3.mailp2}
                      desc3={section3.mailp3}
                      setOpenDetails={setOpenDetails}
                      buttonText={general.knowMore}
                      url={section3.mailUrl}
                    />
                  )}
                  <div
                    onClick={() => openDetailsFunc(0)}
                    className="hex-border translate-y-5 xl:translate-y-[40px]"
                  >
                    <div className="hexagon bg-white ">
                      <img
                        className="xl:w-[69px] w-[40px]"
                        src="/img/mail.svg"
                        alt="mail icon"
                      />
                    </div>
                  </div>
                </div>

                <div className="info-wrapper">
                  {openDetails[1] && (
                    <FolderDetails
                      img={"/img/folder.svg"}
                      name={section3.folder}
                      desc1={section3.folderp1}
                      desc2={section3.folderp2}
                      desc3={section3.folderp3}
                      setOpenDetails={setOpenDetails}
                      buttonText={general.knowMore}
                      url={section3.folderUrl}
                    />
                  )}
                  <div
                    onClick={() => openDetailsFunc(1)}
                    className="hex-border translate-y-5 xl:translate-y-[40px]"
                  >
                    <div className="hexagon bg-white ">
                      <img
                        className="xl:w-[69px] w-[40px]"
                        src="/img/folder.svg"
                        alt="folder icon"
                      />
                    </div>
                  </div>
                </div>

                <div className="hex-border-no-hover translate-y-5 xl:translate-y-[40px]">
                  <div className="hexagon bg-black "></div>
                </div>
                <div className="hex-border-no-hover translate-y-5 xl:translate-y-[40px]">
                  <div className="hexagon bg-orange opacity-[0.18]"></div>
                </div>
                <div className="hex-border-no-hover  col-start-1 col-end-3">
                  <div className="hexagon bg-black"></div>
                </div>
                <div className="hex-border-no-hover">
                  <div className="hexagon bg-orange "></div>
                </div>
                <div className="info-wrapper">
                  {openDetails[2] && (
                    <FolderDetails
                      img={"/img/archive.svg"}
                      name={section3.archive}
                      desc1={section3.archivep1}
                      desc2={section3.archivep2}
                      desc3={section3.archivep3}
                      setOpenDetails={setOpenDetails}
                      buttonText={general.knowMore}
                      url={section3.archiveUrl}
                    />
                  )}
                  <div
                    onClick={() => openDetailsFunc(2)}
                    className="hex-border"
                  >
                    <div className="hexagon bg-white">
                      <img
                        className="xl:w-[69px] w-[40px]"
                        src="/img/archive.svg"
                        alt="archive icon"
                      />
                    </div>
                  </div>
                </div>

                <div className="hex-border-no-hover">
                  <div className="hexagon bg-white">
                    <img
                      className="xl:w-[69px] w-[40px] object-contain"
                      src="/img/logo.png"
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="info-wrapper">
                  {openDetails[3] && (
                    <FolderDetails
                      img={"/img/contact.svg"}
                      name={section3.contacts}
                      desc1={section3.contactsp1}
                      desc2={section3.contactsp2}
                      desc3={section3.contactsp3}
                      setOpenDetails={setOpenDetails}
                      buttonText={general.knowMore}
                      url={section3.contactsUrl}
                    />
                  )}
                  <div
                    className="hex-border"
                    onClick={() => {
                      openDetailsFunc(3);
                    }}
                  >
                    <div className="hexagon bg-white">
                      <img
                        className="pointer-events-none w-[40px] xl:w-[69px]"
                        src="/img/contact.svg"
                        alt="contact icon"
                      />
                    </div>
                  </div>
                </div>

                <div className="hex-border-no-hover">
                  <div className="hexagon bg-orange"></div>
                </div>
                <div className="hex-border-no-hover">
                  <div className="hexagon  bg-black"></div>
                </div>
                <div className="hex-border-no-hover -translate-y-5 xl:translate-y-[-40px] col-start-2 col-end-4">
                  <div className="hexagon   bg-orange opacity-[0.18]"></div>
                </div>
                <div className="hex-border-no-hover -translate-y-5 xl:translate-y-[-40px]">
                  <div className="hexagon  bg-black"></div>
                </div>
                <div className="info-wrapper">
                  {openDetails[4] && (
                    <FolderDetails
                      img={"/img/connect.svg"}
                      name={section3.team}
                      desc1={section3.teamp1}
                      desc2={section3.teamp2}
                      desc3={section3.teamp3}
                      setOpenDetails={setOpenDetails}
                      buttonText={general.knowMore}
                      url={section3.teamUrl}
                    />
                  )}
                  <div
                    onClick={() => openDetailsFunc(4)}
                    className="hex-border -translate-y-5 xl:translate-y-[-40px]"
                  >
                    <div className="hexagon  bg-white">
                      <img
                        className="xl:w-[69px] w-[40px]"
                        src="/img/connect.svg"
                        alt="connect icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="info-wrapper">
                  {openDetails[5] && (
                    <FolderDetails
                      img={"/img/device.svg"}
                      name={section3.automation}
                      desc1={section3.automationp1}
                      desc2={section3.automationp2}
                      desc3={section3.automationp3}
                      setOpenDetails={setOpenDetails}
                      buttonText={general.knowMore}
                      url={section3.automationUrl}
                    />
                  )}
                  <div
                    onClick={() => openDetailsFunc(5)}
                    className="hex-border -translate-y-5 xl:translate-y-[-40px]"
                  >
                    <div className="hexagon  bg-white">
                      <img
                        className="xl:w-[69px] w-[40px]"
                        src="/img/device.svg"
                        alt="device icon"
                      />
                    </div>
                  </div>
                </div>

                <div className="hex-border-no-hover -translate-y-5 xl:translate-y-[-40px]">
                  <div className="hexagon  bg-black"></div>
                </div>
                <div className="hex-border-no-hover -translate-y-5 xl:translate-y-[-40px]">
                  <div className="hexagon  bg-orange opacity-[0.18]"></div>
                </div>
              </div>
            </div>
          </section>

          <section className=" sections section-reviews">
            <div className="heading-container">
              <h4 className="heading-title" id="Vertrauen">
                {general.secureHeadline}
              </h4>
              <h3 className="heading-section-title">
                {general.secureHeadlineSub}
              </h3>
            </div>
            <div className="mySwiper-review">
              <Slider2 />
            </div>
          </section>
        </main>

        <div className="footer-banner">
          <div className="container mx-auto">
            <section className="sections section-quote">
              <div className="quote-col quote-col-1">
                <div className="quote-col-content">
                  <h4 className="heading-title">{general.startNow}</h4>
                  <h2 className="quote-title">{general.fileControlHeadline}</h2>
                  <p className="quote-description">
                    {general.fileControlDesc}
                    <br /> {general.fileControlDesc2}
                  </p>
                  <Link to="/termin" rel="noreferrer" className="btn-primary">
                    {general.btnText}
                  </Link>
                </div>
              </div>
              <div className="quote-col quote-col-2">
                <div className="client-feedback">
                  <div className="client-image-container">
                    <div className="client-image-outer-wrapper">
                      <img
                        className="client-quote-icon"
                        src="/img/Quote.png"
                        alt="quote icon"
                      />
                      <div className="client-image-wrapper">
                        <img
                          className="client-image"
                          src="/img/sascha.png"
                          alt="image sascha ladewig"
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="client-dot"
                    src="/img/Dot.png"
                    alt="dot icon"
                  />
                  <div className="client-content">
                    <p className="client-message">{general.persQuote}</p>
                    <div className="client-details">
                      <h4 className="client-name">Sascha Ladewig</h4>
                      <span className="client-position">
                        {general.coFounder}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer content={footer} />
      </div>
    </>
  );
}

export default Home;
