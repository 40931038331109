import Footer from "../components/Footer";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";
import FormInput from "../components/FormInput";
import toast from "react-hot-toast";

const SheatCheet = ({ content, footer }) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const [formData, setFormData] = useState({
    companyEmail: "",
  });

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const closeModal = () => {
    setIsModalActive(false);
  };

  const showModal = () => {
    setIsModalActive(true);
  };

  //submit handler
  const submitHandler = (e) => {
    e.preventDefault();
    if (!formData.companyEmail)
      return toast.error("Please fill the company email");
    //sending mail
    setLoading(true);
    emailjs
      .sendForm(
        "service_qdyu768",
        "template_qlwxsac",
        formRef.current,
        "j5AWxEsNPh7ca7u_6"
      )
      .then(
        () => {
          setLoading(false);
          toast.success("Message sent successfully");
          setFormData({
            companyEmail: "",
          });
        },
        () => {
          setLoading(false);
          toast.error("Error occured");
        }
      )
      .finally(() => {
        setIsModalActive(false);
      });
  };

  return (
    <>
      {isModalActive && (
        <div className="bg-black fixed left-0 top-0 right-0 bottom-0 z-50 bg-opacity-50 flex justify-center items-center">
          <div className="rounded-md w-[calc(100vw-40px)] max-w-[660px] px-4 pt-3 pb-14 bg-white fixed left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-20">
            <div
              className="flex justify-end items-center text-3xl text-black opacity-80 cursor-pointer"
              onClick={closeModal}
            >
              &times;
            </div>
            <div className="mt-2 px-5">
              <h3 className="text-[40px] text-[transparent] text-center font-bold bg-gradient-to-r from-[#f4b54b] to-[#f75b2a] bg-clip-text">
                {content.formTitle}
              </h3>

              <form ref={formRef} onSubmit={submitHandler}>
                <div className="my-4 max-w-[400px] mx-auto">
                  <FormInput
                    value={formData.companyEmail}
                    handler={inputHandler}
                    label={content.label3}
                    name="companyEmail"
                    placeholder="name@firma.de"
                  />
                  <input
                    type="text"
                    className="hidden"
                    hidden
                    name="message"
                    value={"URL: " + window.location.href}
                  />
                </div>

                <div className="flex justify-center">
                  <button
                    onClick={showModal}
                    className="btn-primary flex justify-center items-center gap-3 rounded-full"
                  >
                    {loading ? (
                      <img
                        className="w-7 object-contain"
                        src="/img/loader.gif"
                        alt="load icon"
                      />
                    ) : (
                      content.formButton
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-rows-[1fr__auto] min-h-screen w-full">
        <Helmet>
          <title>{content.title}</title>
          <meta name="description" content={content.description} />
        </Helmet>
        <div className="flex pb-12 px-5 justify-center bg-[#fff8ef] pt-[40px] md:pt-[60px] items-center flex-col w-full">
          <div className="container flex justify-center flex-col  items-center gap-2 md:gap-[35px]">
            <h2 className="leading-none text-black font-bold text-center text-2xl  sm:text-[28px] md:text-[35px] xl:text-[48px]">
              {content.heading}
            </h2>
            {/* <p className="text-black text-center font-medium text-base sm:text-xl md:text-[25px] xl:text-[30px]">
            {content.sub}
          </p> */}

            <p className="text-base sm:text-xl lg:text-[20px] text-black text-center mt-3 sm:mt-6 max-w-[800px]">
              {content.bottomText}
            </p>
            <button
              onClick={showModal}
              className="btn-primary flex justify-center items-center gap-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
              {content.btnText}
            </button>
          </div>
        </div>
        <div className="flex items-center bg-[#fff8ef]">
          <img
            src="/img/IdeasImg.png"
            alt="laptop"
            className="w-4/5 max-w-[1200px] mx-auto"
          />
        </div>
        <Footer content={footer} />
      </div>
    </>
  );
};

export default SheatCheet;
