import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation } from "react-router";

const Revisionssicher = ({ content, footer }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
      </Helmet>
      <div className="h-screen flex flex-col body">
        <section className="header-container">
          {/* <!-- START --> */}

          <div className="banner-section-tax flex flex-col space-y-5 lg:flex-row container mx-auto mt-10 mb-32">
            <div className="banner-col-tax flex flex-1 flex-col items-center text-center pt-10 lg:items-start lg:pt-40 lg:text-left col-1 xl:ml-[60px] 2xl:ml-[190px]">
              <div className="banner-col-1-content-tax px-4 lg:max-w-lg">
                <h1 className="banner-title-tax pt-2 pb-3 text-4xl font-bold max-w-xs mx-auto lg:text-5xl lg:max-w-none lg:pb-6 lg:leading-tight">
                  {content.headline}
                </h1>
                <p className="banner-description-tax text-[17px] opacity-80  leading-8">
                  {content.desc1}
                </p>
              </div>
            </div>
            <div className="banner-col col-2-tax flex flex-row flex-1 justify-center pt-10 lg:pt-0 xl:mr-[60px] 2xl:mr-[190px] self-end w-full h-[300px]">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/MakohDl-Uok?controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
              ></iframe>
            </div>
          </div>

          {/* <!-- BLOG START --> */}

          <div className="mx-auto sm:container  lg:max-w-[880px] px-[20px] mb-20">
            <div className="text-3xl font-bold mb-4">
              {content.head1}
            </div>
            <div className="opacity-80 leading-relaxed mb-14">
              {content.desc2}
              <br />
              <br />
              {content.desc3}
              <p></p>
              <br />
              <br />
              <div className="text-3xl font-bold mb-4">
                {content.head2}
              </div>
              {content.desc4}
              <br />
              <br />
              {content.desc5}
              <p></p>
              <br />
              <br />
              <div className="text-3xl font-bold mb-4">
                {content.head3}
              </div>
              {content.desc6}
              <br />
              <br />
              {content.desc7}
              <p></p>
              <br />
              <br />
              <div className="text-3xl font-bold mb-4">
                {content.head4}
              </div>
              <p className="opacity-80 leading-relaxed mb-14">
                {content.desc8}{" "}
                <u>
                  <a
                    href="https://servicetrust.microsoft.com/ViewPage/MSComplianceGuide?command=Download&downloadType=Document&downloadId=19b08fd4-d276-43e8-9461-715981d0ea20&docTab=4ce99610-c9c0-11e7-8c2c-f908a777fa4d_GRC_Assessment_Reports"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Azure Service Trust-Portal
                  </a>
                </u>{" "}
                {content.desc9}{" "}
                <u>
                  <a
                    href="https://www.microsoft.com/en-us/trustcenter/compliance"
                    target="_blank" rel="noreferrer"
                  >
                    Azure Trust Center
                  </a>
                </u>{" "}
                {content.desc10}
                <br />
                <br />
                {content.desc11}
                <br />
                <br />
                {content.desc12}
                <br />
                <br />
                {content.desc13}
                <br />
                <br />
                {content.desc14}
              </p>
              <br />
              <div className="grid grid-col-1 sm:grid-cols-2 gap-y-10 mb-20 gap-x-5">
                <div className="flex items-center gap-5">
                  <div className="">
                    <img
                      src="./img/WritingDesk.png"
                      className="w-10"
                      alt="icon"
                    />
                  </div>
                  <div className="font-bold">{content.p1}</div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="">
                    <img src="./img/icon2.svg" className="w-10" alt="icon" />
                  </div>
                  <div className="font-bold">
                    {content.p2}
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="">
                    <img src="./img/icon3.svg" className="w-10" alt="icon" />
                  </div>
                  <div className="font-bold">
                    {content.p3}
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="">
                    <img src="./img/icon4.svg" className="w-10" alt="icon" />
                  </div>
                  <div className="font-bold">
                    {content.p4}
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="">
                    <img src="./img/icon5.svg" className="w-10" alt="icon" />
                  </div>
                  <div className="font-bold">{content.p5}</div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="">
                    <img src="./img/icon6.svg" className="w-10" alt="icon" />
                  </div>
                  <div className="font-bold">{content.p6}</div>
                </div>
              </div>
              <br />
              <br />
              <div className="text-3xl font-bold mb-4">
                {content.head5}
              </div>
              <p className="opacity-80 leading-relaxed mb-14">
                {content.desc15}
                <br />
                <br />
                {content.desc16}
              </p>
            </div>
          </div>
          {/* <!-- END --> */}
          <Footer content={footer} />
        </section>
      </div>
    </>
  );
};

export default Revisionssicher;
