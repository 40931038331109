import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Switch from "../components/Switch";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";

function Preise({ content, footer }) {
  const [enabled, setEnabled] = useState(false);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description_meta} />
      </Helmet>

      <div className="h-screen flex flex-col body">
        <section className="header-container">
          <section className="sections section-pricing">
            <div className="flex items-center justify-center text-center flex-col mt-10 mb-20 mx-auto px-[20px] lg:px-[40px]">
              <div className="text-orange tracking-wide mb-3">
                {content.pricing}
              </div>
              <div className="text-4xl sm:text-5xl font-bold">{content.headline}</div>
              <div className="mx-auto max-w-[800px] mt-5 leading-relaxed">
                {content.desc}
              </div>
            </div>

            <div className="flex items-center gap-3 w-fit mx-auto pb-7">
              <div>{content.monthly}</div>
              <Switch enabled={enabled} setEnabled={setEnabled} />
              <div>{content.anually}</div>
            </div>

            <div className="main-pricing-flex mx-auto flex items-start  gap-5 mb-20">
              <div className="transform translate-y-[235px] leftbar-pricing">
                <br />
                <br />
                <div className="text-orange font-bold text-xl mb-[0.5rem] w-full bg-white rounded-md px-3 py-2">
                  {content.shortProfile}
                </div>
                <div className="opacity-80">{content.description}</div>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <div className="flex flex-col gap-4 mb-10">
                  <div className="opacity-80">{content.inclusiveStorage}</div>
                  <div className="opacity-80">{content.moreSpace}</div>
                  <div className="opacity-80">{content.moreUser}</div>
                </div>

                <div className="text-orange font-bold text-xl mb-[0.5rem] w-full bg-white rounded-md px-3 py-2">
                  {content.characteristics}
                </div>

                <div className="flex flex-col gap-[3.2rem] mb-10">
                  <div className="opacity-80">{content.issueView}</div>
                  <div className="opacity-80">{content.calView}</div>
                  <div className="opacity-80">{content.userRoles}</div>
                  <div className="opacity-80">{content.customFields}</div>
                  <div className="opacity-80">{content.externalAccess}</div>
                  <div className="opacity-80">{content.push}</div>
                  <div className="opacity-80">{content.issuePlace}</div>
                  <div className="opacity-80">
                    {content.explorer}
                  </div>
                  <div className="opacity-80">{content.reminder}</div>
                  <div className="opacity-80">
                    {content.smartphone}
                  </div>
                  <div className="opacity-80">{content.scan}</div>
                  <div className="opacity-80">{content.tasks}</div>
                  <div className="opacity-80">{content.label}</div>
                  <div className="opacity-80">{content.qr}</div>
                </div>

                <div className="text-orange font-bold text-xl mb-[0.5rem] w-full bg-white rounded-md px-3 py-2">
                  {content.interfaces}
                </div>

                <div className="flex flex-col gap-[3.2rem] mb-10">
                  <div className="opacity-80">
                    {content.outlook}
                  </div>
                  <div className="opacity-80">{content.datev}</div>
                  <div className="opacity-80">{content.lexware}</div>
                  <div className="opacity-80">{content.praxident}</div>
                  <div className="opacity-80">{content.steuersoft}</div>
                  <div className="opacity-80">{content.revsafe}</div>
                </div>

                <div className="text-orange font-bold text-xl mb-[0.5rem] w-full bg-white rounded-md px-3 py-2">
                  {content.installation}
                </div>

                <div className="flex flex-col gap-[3.2rem] mb-10">
                  <div className="opacity-80">{content.cloud}</div>
                  <div className="opacity-80">{content.local}</div>
                  <div className="opacity-80">{content.singleSign}</div>
                  <div className="opacity-80">{content.templates}</div>
                </div>

                <div className="text-orange font-bold text-xl mb-[0.5rem] w-full bg-white rounded-md px-3 py-2">
                  {content.security}
                </div>

                <div className="flex flex-col gap-[3.2rem]">
                  <div className="opacity-80">{content.dsgvo}</div>
                  <div className="opacity-80">{content.rz}</div>
                </div>
              </div>

              {/* <!-- PRICING --> */}

              <div className=" pricing-cards-flex gap-5">
                <div className="pricing text-center bg-white  w-full relative">
                  <div className="pricing-title flex items-center justify-center text-xl font-bold  bg-[#FFF2ED] text-orange text-center px-5 py-4">
                    {content.package1Title}
                  </div>
                  <div className="w-full px-4">
                    <div className="text-4xl font-bold opacity-80 mt-5 mb-1">{enabled === false ? "15,00 €" : "10,00 €"}</div>
                    <div className="text-[#8D9AA9] text-base">{content.perMonth}<br />{enabled === false ? content.packageDescMonth : content.packageDescYear}</div>
                    {content.perMonth.includes("month") ? <br /> : ""}
                    <a
                      href={enabled === false ? "https://buy.stripe.com/fZe00DcaI79v41y7su" : "https://buy.stripe.com/aEU9Bd6Qo2TfdC828b"}
                      target="_blank" rel="noreferrer"
                    >
                      <button className="pricing-btn w-full rounded py-2 mt-7 transition text-orange hover:text-white font-bold">
                        {content.btnBuy}
                      </button>
                    </a>
                    <Link
                      to="/termin"
                      rel="noreferrer"
                    >
                      <button className="pricing-btnTest w-full rounded py-2 mt-3 transition text-orange font-bold">
                        {content.btnTest}
                      </button>
                    </Link>

                    <div
                      className="text-[15px] leading-relaxed mt-10 opacity-80"
                      style={{ height: "300px" }}
                    >
                      {content.package1Desc}
                    </div>

                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.inclusiveStorage}
                    </div>
                    <div className="font-bold opacity-80 mb-5">50GB</div>
                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.moreSpace}
                    </div>
                    <div className="font-bold opacity-80 mb-5">6,00 €</div>
                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.moreUser}
                    </div>
                    <div className="font-bold opacity-80 mb-5">-</div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.characteristics}
                    </div>
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.issueView}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.calView}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.userRoles}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.customFields}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.externalAccess}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.push}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.issuePlace}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.explorer}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.reminder}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.smartphone}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.scan}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.tasks}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.label}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.qr}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[1.0rem]"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.interfaces}
                    </div>
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.outlook}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.datev}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.lexware}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.praxident}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.steuersoft}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.revsafe}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.installation}
                    </div>
                    <br />
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.cloud}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[2.5rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.local}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.singleSign}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.templates}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.security}
                    </div>
                    <br />
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.dsgvo}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.rz}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto"
                      />
                    </div>
                    <br />
                    <br />
                  </div>
                </div>

                <div className="pricing text-center bg-white   w-full relative">
                  {/* <!-- <div
                        className="absolute -top-[15px] left-1/2 popular-tag px-[7px] py-2  bg-orange text-white rounded text-[11px] font-bold tracking-wide">
                        Beliebt</div> --> */}
                  <div className="pricing-title flex items-center justify-center text-xl font-bold  bg-[#FFF2ED] text-orange text-center px-5 py-4">
                    {content.package2Title}
                  </div>
                  <div className="w-full px-5">
                    <div className="text-4xl font-bold opacity-80 mt-5 mb-1">{enabled === false ? "30,00 €" : "20,00 €"}</div>
                    <div className="text-[#8D9AA9] text-base">{content.perMonth}<br />{enabled === false ? content.packageDescMonth : content.packageDescYear}</div>
                    {content.perMonth.includes("month") ? <br /> : ""}
                    <a
                      href={enabled === false ? "https://buy.stripe.com/9AQbJla2AeBX69G144" : "https://buy.stripe.com/5kAaFh4IgfG1apWfYZ"}
                      target="_blank" rel="noreferrer"
                    >
                      <button className="pricing-btn w-full rounded py-2 mt-7 transition text-orange hover:text-white font-bold">
                        {content.btnBuy}
                      </button>
                    </a>
                    <Link
                      to="/termin"
                      rel="noreferrer"
                    >
                      <button className="pricing-btnTest w-full rounded py-2 mt-3 transition text-orange font-bold">
                        {content.btnTest}
                      </button>
                    </Link>

                    <div
                      className="text-[15px] leading-relaxed mt-10 opacity-80"
                      style={{ height: "300px" }}
                    >
                      {content.package2Desc}
                    </div>

                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.inclusiveStorage}
                    </div>
                    <div className="font-bold opacity-80 mb-5">100GB</div>
                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.moreSpace}
                    </div>
                    <div className="font-bold opacity-80 mb-5">6,00 €</div>
                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.moreUser}
                    </div>
                    <div className="font-bold opacity-80 mb-5">5,00 €</div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.characteristics}
                    </div>
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.issueView}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.calView}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.userRoles}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.customFields}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.externalAccess}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.push}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.issuePlace}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.explorer}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.reminder}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.smartphone}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.scan}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.tasks}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.label}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.qr}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[1.0rem]"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.interfaces}
                    </div>
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.outlook}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.datev}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.lexware}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.praxident}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.steuersoft}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.revsafe}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.installation}
                    </div>
                    <br />
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.cloud}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[2.5rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.local}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.singleSign}
                      </div>
                      <img
                        src="/img/close.svg"
                        alt="close"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.templates}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.security}
                    </div>
                    <br />
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.dsgvo}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.rz}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto"
                      />
                    </div>
                    <br />
                    <br />
                  </div>
                </div>

                <div className="pricing text-center bg-white  w-full relative">
                  <div className="pricing-title flex items-center justify-center text-xl font-bold  bg-[#FFF2ED] text-orange text-center px-5 py-4">
                    {content.package3Title}
                  </div>
                  <div className="w-full px-4">
                    <div className="text-4xl font-bold opacity-80 mt-5 mb-1">{enabled === false ? "85,00 €" : "60,00 €"}</div>
                    <div className="text-[#8D9AA9] text-base">{content.perMonth}<br />{enabled === false ? content.packageDescMonth : content.packageDescYear}</div>
                    {content.perMonth.includes("month") ? <br /> : ""}
                    <a
                      href={enabled === false ? "https://buy.stripe.com/7sI14H6QogK5gOk148" : "https://buy.stripe.com/3csfZB6QofG1bu07sx"}
                      target="_blank" rel="noreferrer"
                    >
                      <button className="pricing-btn w-full rounded py-2 mt-7 transition text-orange hover:text-white font-bold">
                        {content.btnBuy}
                      </button>
                    </a>
                    <Link
                      to="/termin"
                      rel="noreferrer"
                    >
                      <button className="pricing-btnTest w-full rounded py-2 mt-3 transition text-orange font-bold">
                        {content.btnTest}
                      </button>
                    </Link>


                    <div
                      className="text-[15px] leading-relaxed mt-10 opacity-80"
                      style={{ height: "300px" }}
                    >
                      {content.package3Desc}
                    </div>

                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.inclusiveStorage}
                    </div>
                    <div className="font-bold opacity-80 mb-5">500GB</div>
                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.moreSpace}
                    </div>
                    <div className="font-bold opacity-80 mb-5">6,00 €</div>
                    <div className="text-[15.3px] names-pricing-f hidden mb-5">
                      {content.moreUser}
                    </div>
                    <div className="font-bold opacity-80 mb-5">5,00 €</div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.characteristics}
                    </div>
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.issueView}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.calView}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.userRoles}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.customFields}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.externalAccess}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.push}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.issuePlace}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.explorer}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.reminder}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.smartphone}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.scan}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.tasks}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.label}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.qr}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[1.0rem]"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.interfaces}
                    </div>
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.outlook}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.datev}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.lexware}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.praxident}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.steuersoft}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.revsafe}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.installation}
                    </div>
                    <br />
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.cloud}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[2.5rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.local}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.singleSign}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.3rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.templates}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto"
                      />
                    </div>

                    <br />
                    <br />
                    <div
                      className="text-[20.3px] names-pricing-f hidden mb-5"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {content.security}
                    </div>
                    <br />
                    <br />

                    <div className="flex flex-col">
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.dsgvo}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto mb-[3.0rem]"
                      />
                      <div className="text-[15.3px] names-pricing-f hidden mb-5">
                        {content.rz}
                      </div>
                      <img
                        src="/img/Check.svg"
                        alt="check"
                        className="mx-auto"
                      />
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer content={footer} />
        </section>
      </div>
    </>
  );
}

export default Preise;
