import React from "react";

const FormTextarea = ({
  label,
  name,
  handler,
  value,
  compulsory,
  placeholder,
}) => {
  return (
    <div className="flex justify-start items-start flex-col w-full gap-3">
      <label className="text-base text-black font-medium" htmlFor={label}>
        {label} {compulsory && <span className="text-orange">*</span>}
      </label>
      <textarea
        name={name}
        id={label}
        onChange={handler}
        value={value}
        type="text"
        placeholder={placeholder}
        className="w-full pt-4 border outline-1 border-solid resize-none border-[#727272] h-[150px] px-4 rounded"
      ></textarea>
    </div>
  );
};

export default FormTextarea;
