import { useEffect } from "react";

function Redirect({ url }) {
  useEffect(() => {
    alert("REDIRECTING");
    window.location.replace(url);
  });
}

export default Redirect;
